$sidebar-navbar-height: 170px;
$navbar-height: 60px;

#document-viewer-body {
  background: $canopy-color-background-container;
  height: 100%;
  overflow-y: auto;
  padding-bottom: $header-height;
  position: fixed;
  right: 0;

  @include media-breakpoint-down(sm) {
    border: 0;
    margin: 15px 0;
    position: relative;
  }

  .document-files {
    background: $canopy-color-background-container;
    border: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
    max-height: calc(100vh - #{$navbar-height + $sidebar-navbar-height});
    min-height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
  }

  .document-file-wrapper {
    position: relative;

    .document-overlay {
      background: $canopy-color-primitives-gray-39;
      content: "";
      display: none;
      height: 100%;
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: $z-index-document-overlay;
    }

    &.active .document-overlay {
      display: block;
    }
  }
}

#document-viewer-buttons {
  position: fixed;
  right: 15px;
  top: 72px;
  z-index: $z-index-document-toggle;
}
