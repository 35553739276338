@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.pages {
  background-color: $canopy-color-background-container;
  list-style: none;
  margin: 0;
  padding-left: 0;

  @include media-breakpoint-up(md) {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.link {
  font-weight: bold;
  font-size: 14px;

  @include media-breakpoint-only(lg) {
    font-size: 12px;
  }

  &:last-child {
    border-bottom: 0;

    @include media-breakpoint-up(md) {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  a,
  > span {
    @include media-breakpoint-only(lg) {
      padding: 10px 8px;
    }
    color: $canopy-color-text-primary;
    display: block;
    padding: 14px 12px;
  }
}

.with-bottom-border {
  border-bottom: 1px solid $canopy-color-primitives-gray-86;
}

.active {
  background: $canopy-color-interactive-background-secondary-active;
  color: $canopy-color-interactive-link-active;

  .fa-check-circle {
    color: $canopy-color-brand-primary;
  }

  .fa-circle {
    color: $canopy-color-brand-primary;
    font-weight: 500;
  }
}
