.dashboard-sidebar {
  background: $canopy-color-background-secondary;
  border-left: 1px solid $canopy-color-primitives-gray-86;
  height: 100%;
  padding-bottom: 140px;
  padding-left: 0;
  padding-right: 0;
  position: fixed;
  right: 0;
  z-index: $z-index-right-sidebar;

  .dashboard-sidebar-body {
    background: $canopy-color-background-secondary;
    height: 100%;
    min-height: 250px;
    overflow-y: auto;
    padding-bottom: $header-height;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @include media-breakpoint-down(sm) {
      border: 0;
      margin: 15px 0;
      position: relative;
    }
  }

  .well {
    border: 0;

    .checkbox {
      margin-bottom: 2px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $canopy-color-primitives-gray-86;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 0;
    width: 100%;
  }
}
