@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.container {
  @include media-breakpoint-up(md) {
    text-align: center;
  }
}

.add-reviewer-button {
  @include media-breakpoint-down(md) {
    margin-top: -8px;
  }
}
