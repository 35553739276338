@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.header {
  display: flex;
  justify-content: space-between;
}

.pinned-icon {
  color: $canopy-color-primitives-blue-41;
  font-size: 0.875rem;
}

.efax-container {
  display: flex;
}

.caution-icon {
  color: $canopy-color-primitives-yellow-62;
  font-size: 0.875rem;
}

.disclaimer-container {
  padding-top: 3px;
}

.efax-disclaimer {
  color: $canopy-color-primitives-gray-32;
  font-size: 0.875rem;
}

.bold-efax-disclaimer {
  font-weight: bold;
}

.promoted-insight {
  background-color: $canopy-color-primitives-purple-35;
  color: $canopy-color-text-inverse;
  border-radius: 1rem;
  font-weight: bold;
  font-size: 0.875rem;
  padding: 0.2rem 0.65rem;
  margin-top: -0.2rem;
}

.promoted-copy {
  color: $canopy-color-brand-primary;
}

.other-name {
  color: $canopy-color-text-secondary;
}
