@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.documentsBrowser {
  inline-size: 100%;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: $canopy-space-4x;
}

.documentNav {
  @include canopy-typography-body-small;
  align-items: center;
  display: flex;
  gap: $canopy-space-4x;
  justify-content: space-between;

  .start {
    align-items: center;
    display: flex;
    gap: $canopy-space-2x;
  }
}

.documentControls {
  align-items: center;
  display: flex;
  gap: $canopy-space-4x;
  justify-content: space-between;

  .start {
    display: flex;
    gap: $canopy-space-2x;
  }

  .end {
    display: flex;
    align-items: center;
    gap: $canopy-space-2x;
  }

  .paginationLabel {
    @include canopy-typography-body-small;
    margin-inline-end: $canopy-space-4x;
  }
}

.image {
  position: relative;

  .overlay {
    background: $canopy-color-primitives-gray-39;
    content: "\A";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.1s;
    width: 100%;
  }

  &.hover {
    .overlay {
      opacity: 0.5;
    }
  }
}

.swiper {
  background: #fff;
  border: 1px solid $canopy-color-border-default;
  border-radius: 6px;
  cursor: move;
  margin-top: 0.5rem;
  max-height: calc(100vh - 236px);
  min-height: 500px;
  overflow-x: auto;
  overflow-y: auto;
  position: relative;
}
