@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.cardDetail {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.cardDetailTitle {
  width: 160px;
}
.cardDetailValue {
  margin-left: $canopy-space-8x;
}

.requiredField {
  color: $canopy-color-text-danger;
  font-style: italic;
}
