@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.badge {
  background-color: $canopy-color-interactive-background-primary-default;
  border: 0;
  border-radius: 100%;
  color: $canopy-color-text-inverse;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 23px;
  line-height: 23px;
  position: absolute;
  right: -6px;
  text-align: center;
  top: -6px;
  vertical-align: middle;
  white-space: nowrap;
  width: 23px;
}
