.demo-watermark {
  bottom: 0;
  color: $canopy-color-text-inverse;
  font-family: Avenir, Helvetica, Verdana, sans-serif;
  font-size: 300px;
  height: 100%;
  left: 0;
  margin: 0 auto;
  opacity: 0.8;
  position: fixed;
  right: 0;
  text-align: center;
  text-shadow: 1px 2px 4px rgba($canopy-color-primitives-red-48, 0.2);
  text-transform: uppercase;
  top: 20%;
  transform: rotate(-20deg);
  width: 100%;
  z-index: -1;
}
