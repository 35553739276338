$entry-height: 3rem;
$entry-padding-base: 0.875rem;

.entry {
  @include clearfix;
  background-color: $canopy-color-background-primary;
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: $entry-padding-base * 2;
  height: $entry-height;
  line-height: $entry-height;
  max-width: 100%;
  overflow: hidden;
  padding-left: 16px;
  padding-right: $entry-padding-base * 3;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &:hover {
    .description {
      text-decoration: underline;
    }
  }

  .fa-wrapper {
    display: inline-block;
    height: 16px;
    margin-right: 5px;
    min-width: 14px;
    width: 14px;
  }

  .fa-times:hover {
    background-color: rgba($canopy-color-primitives-gray-86, 0.3);
    border-radius: 22px;
  }

  &.warning {
    background-color: $canopy-color-background-danger;
    border: 1px solid $canopy-color-primitives-red-48;

    .fa-times:hover {
      background-color: rgba($canopy-color-primitives-red-48, 0.3);
    }
  }

  > .title {
    color: $canopy-color-text-primary;
    font-weight: bold;
  }

  > .description {
    color: $canopy-color-text-primary;
    padding-right: $entry-padding-base * 2;
  }

  > .title + .description {
    margin-left: $entry-padding-base;
  }

  > .icon {
    cursor: pointer;
    float: right;
    height: 36px;
    line-height: 36px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 5px;
    vertical-align: middle;
    width: 36px;
  }
}

.entry + .entry {
  margin-top: 0.5rem;
}
