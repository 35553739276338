@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.accordionRoot {
  margin-top: $canopy-space-8x;
  border: 1px solid $canopy-color-border-default !important;
  border-radius: $canopy-space-2x !important;
  box-shadow: 0 2px 4px $canopy-color-border-default !important;

  &::before {
    content: none !important;
  }
}

.authAccordionHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
