.link {
  background: none;
  border: 0;
  color: $canopy-color-interactive-link-default;
  font-weight: bold;

  &:hover,
  &:focus {
    color: $canopy-color-interactive-link-active;
    outline: 0;
    text-decoration: underline;
  }
}

.link-nonbold {
  font-weight: normal;
}

.link-understated {
  color: $canopy-color-primitives-gray-39;

  &:hover,
  &:focus {
    color: $canopy-color-primitives-gray-39;
  }
}

.link-teal {
  color: $canopy-color-brand-secondary;

  &:hover,
  &:focus {
    cursor: pointer;
    color: $canopy-color-text-success;
  }
}

.link-secondary {
  color: $canopy-color-brand-secondary;

  &:hover,
  &:focus {
    color: $canopy-color-text-success;
  }
}

.link-danger {
  color: $canopy-color-text-danger;

  &:hover,
  &:focus {
    color: $canopy-color-text-danger;
  }
}

.link-accent {
  color: $canopy-color-primitives-magenta-40;

  &:hover,
  &:focus {
    color: $canopy-color-primitives-magenta-40;
  }
}

a[disabled],
a.disabled {
  color: $canopy-color-primitives-gray-74;

  &:hover,
  &:focus {
    color: $canopy-color-primitives-gray-74;
    cursor: not-allowed;
  }
}

.link-sm {
  line-height: 38px;
}

.link-lg {
  line-height: 50px;
}

.link-tab {
  background-color: $canopy-color-interactive-background-secondary-default;
  border: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  padding: 15px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.1s linear;
  vertical-align: middle;
  white-space: nowrap;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }

  &.active {
    background-color: $canopy-color-primitives-teal-97;
    border-color: $canopy-color-brand-secondary;
    color: $canopy-color-brand-secondary;
  }
}

.link-tab-md {
  font-size: 16px;
  height: 75px;
  width: 125px;
}

.link-tab-lg {
  font-size: 24px;
  height: 65px;
  min-width: 200px;
}

.link-group {
  display: inline-block;
  font-size: 0; // remove whitespace between html tags

  .link-tab:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  .link-tab:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.link-basic {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.tab-label {
  display: inline-block;
  font-size: 0;

  .tab-label-content {
    display: inline-block;
    font-size: 24px;
    padding: 15px;
    vertical-align: middle;
  }
}
