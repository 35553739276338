@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.dmeOrderDetailsContainer {
  padding-left: 0;
  padding-right: 0;
  padding-top: $spacer * 2.5;
}

.columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  width: 50%;
}

.statusPillContainer {
  float: right;
}

.questionnaireContainer {
  display: flex;
  justify-content: space-between;
  margin-left: -2rem;
}

.questionnaireContainer::before {
  content: "•";
  margin-right: 8px;
  color: black;
}
