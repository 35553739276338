.accordion__item {
  border: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
  border-radius: 3px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
  margin-top: 0.5rem;
}

.accordion__button {
  border: 0;
  cursor: pointer;
  font-weight: bold;
  padding: 0.75rem 1rem;
  position: relative;
  text-align: left;
  width: 100%;
}

.accordion__button:hover {
  background-color: $canopy-color-interactive-background-secondary-hover;
}

.accordion__button::before {
  border-bottom: 2px solid $canopy-color-primitives-gray-16;
  border-right: 2px solid $canopy-color-primitives-gray-16;
  content: "";
  display: inline-block;
  height: 0.5rem;
  position: absolute;
  right: 1.25rem;
  top: 1.375rem;
  transform: rotate(-135deg);
  width: 0.5rem;
}

.accordion__button:active,
.accordion__button:focus {
  outline: 0;
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  top: 1.125rem;
  transform: rotate(45deg);
}

.accordion__panel {
  border-top: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
  padding: 0.75rem 1rem;
}
