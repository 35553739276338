.landing-layout {
  align-items: left;
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: center;
  min-height: 100vh;
  padding: 3rem;
}

@include media-breakpoint-down(sm) {
  .landing-layout {
    min-height: auto;
    padding: 1rem;
  }
}
