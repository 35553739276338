@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.btn-pagination {
  color: $canopy-color-primitives-gray-86;
  background: $canopy-color-primitives-gray-16;
  border: 2px solid $canopy-color-primitives-gray-16;
}

.btn-pagination:hover {
  color: $canopy-color-text-inverse;
  background: $canopy-color-primitives-gray-16;
  border: 2px solid $canopy-color-primitives-gray-16;
}

// Disabled styling must come after hover styling if you want
// disabled styling to take precedence. This is in line with how
// CSS works, but worth a reminder!
.btn-pagination:disabled {
  color: $canopy-color-primitives-gray-39;
  background: $canopy-color-interactive-background-disabled;
  border: 2px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
}
