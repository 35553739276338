@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/mixins.scss";
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
$space-between-text-and-highlight: $spacer * 0.75;

.container {
  display: flex;
  border-bottom: 2px solid $canopy-color-primitives-gray-86;
  margin-bottom: $spacer * 1;
}

.tab-container {
  flex: 2;
  display: inline-block;
  margin-right: $spacer * 1.5;
  padding-bottom: $space-between-text-and-highlight;

  &.selected-tab-container {
    border-bottom: 2px solid $canopy-color-primitives-purple-24;
    text-decoration: none;
  }
}

.tab {
  color: $canopy-color-primitives-gray-39;
  text-decoration: none;

  &:hover {
    color: $canopy-color-interactive-background-primary-active;
    text-decoration: none;
    cursor: pointer;
  }

  &:active {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }

  &.selected-tab {
    color: $canopy-color-interactive-background-primary-active;
    text-decoration: none;
  }
}

.tab-text {
  font-size: 1.5rem;
  font-weight: 800;
  @media (max-width: 660px) {
    font-size: 1.25rem;
  }
  @media (max-width: 480px) {
    font-size: 1.125rem;
  }
  @media (max-width: 375px) {
    font-size: 0.875rem;
  }
}

.tab-text-with-sidebar-open {
  font-size: 1.5rem;
  font-weight: 800;
  @media (max-width: 1610px) {
    font-size: 1.125rem;
  }
  @media (max-width: 1150px) {
    font-size: 1.125rem;
  }
  @media (max-width: 940px) {
    font-size: 0.875rem;
  }
}

.tab-list {
  list-style: none;
  margin-bottom: -2px;
  padding-inline-start: 0;
}
