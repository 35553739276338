@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.alertModal {
  border: 1px solid $canopy-color-primitives-yellow-53;

  > div {
    background-color: $canopy-color-primitives-yellow-96 !important;
    border: none !important;
  }
}

.tertiaryButton {
  background: transparent !important;
  &:hover {
    background: $canopy-color-interactive-background-secondary-hover !important;
  }
}
