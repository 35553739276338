input.form-control[disabled] {
  background-color: $canopy-color-interactive-background-disabled;
  border-color: $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
  box-shadow: none;
  color: $canopy-color-primitives-gray-39;
}

input[type="file"] {
  display: none;

  &.d-block {
    display: block;
  }
}

input[type="date"] {
  min-height: 5rem;
  padding: 12px;
}

.input-sm {
  font-size: 1em;
  height: 40px;
}

.input-date {
  width: 120px;

  @include media-breakpoint-down(sm) {
    width: 110px;
  }
}

.input-group {
  margin-bottom: 10px;

  &.secondary {
    .input-group-prepend,
    .input-group-append {
      background: $canopy-color-primitives-purple-98;
      border: 1px solid $canopy-color-primitives-purple-24;
      color: $canopy-color-interactive-link-default;
    }
  }
}

.input-group-placeholder {
  display: flex;
  flex: 1 1 auto;
  margin-bottom: 10px;

  > .form-control {
    margin-bottom: 0;
  }
}

.input-group > .input-group-placeholder {
  margin-bottom: 0;

  > .form-control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.input-group-prepend,
.input-group-append {
  background-color: $canopy-color-primitives-gray-94;
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 50px;
  z-index: 2;
}

.input-group-prepend {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group-append {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group-numeric {
  max-width: 178px;
}

.btn-label {
  position: relative;

  &.disabled {
    &:hover,
    &:focus {
      cursor: not-allowed;
    }

    &:hover input[type="checkbox"][disabled] {
      + span,
      + span.secondary {
        color: $canopy-color-primitives-gray-74;
      }
    }

    input[type="checkbox"][disabled] {
      + span,
      + span.secondary {
        border-color: $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
        color: $canopy-color-primitives-gray-74;
      }

      + span::before {
        color: $canopy-color-primitives-gray-94;
      }
    }
  }

  &:hover {
    cursor: pointer;

    input[type="checkbox"] + span {
      color: $canopy-color-text-success;

      &.secondary {
        color: $canopy-color-primitives-purple-24;
      }
    }

    input[type="checkbox"] + span::before {
      color: inherit;
    }
  }

  input[type="checkbox"] {
    display: none;

    + span {
      background: $canopy-color-background-secondary;
      border: 2px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
      border-radius: 3px;
      color: $canopy-color-primitives-gray-74;
      line-height: 50px;
      padding: 12px 24px;
    }

    + span::before {
      color: $canopy-color-primitives-gray-94;
      content: "✓";
      left: 6px;
      position: absolute;
    }

    &:checked + span {
      border-color: $canopy-color-primitives-teal-41;
      color: $canopy-color-text-success;

      &.secondary {
        border-color: $canopy-color-primitives-purple-24;
        color: $canopy-color-interactive-link-default;
      }
    }

    &:checked + span::before {
      color: inherit;
    }
  }
}

.file-label {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;

  input[type="file"] {
    cursor: inherit;
    display: block;
    filter: alpha(opacity = 0);
    font-size: 999px;
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;

    &:invalid ~ .chosen {
      display: none;
    }

    &:valid ~ .empty {
      display: none;
    }
  }
}

.form-control-feedback {
  height: 50px;
  line-height: 50px !important;
  width: 50px;
}

.form-control-feedback.pointer {
  cursor: pointer;
  pointer-events: inherit;
}

.input-sm + .form-control-feedback {
  height: 40px;
  line-height: 40px !important;
  width: 40px;
}

.col-form-label.inline {
  padding-right: 0.75rem;
  padding-top: 0.75rem;
  text-align: right;
}

.big-input {
  text-align: left;

  input {
    @include font-xxl;
    background: transparent;
    border: 0;
    border-bottom: 1px solid $canopy-color-primitives-gray-74;
    display: block;
    font-weight: bold;
    outline: none;
    padding: 0 0 10px;
    transition: border-bottom-color 0.2s ease-in-out;
    width: 100%;

    &::placeholder {
      color: #808080;
      font-weight: normal;
    }

    &:-ms-input-placeholder {
      color: #808080;
      font-weight: normal;
    }

    &:focus {
      border-bottom-color: $canopy-color-primitives-teal-41;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input + label {
    display: block;
    font-size: 0.875rem;
    font-weight: lighter;
  }

  &.has-error {
    input {
      border-bottom-color: $canopy-color-primitives-red-48;
    }

    input + label {
      color: $canopy-color-text-danger;
    }
  }
}

.multi-character-container {
  clear: both;
  text-align: center;
  overflow: auto;

  label {
    color: $canopy-color-primitives-gray-39;
    float: left;
    font-size: 2rem;
    font-weight: normal;
    line-height: 1.2;
    padding-right: 0.5rem;
    text-align: right;
    width: 48%;

    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      text-align: center;
      width: 100%;
    }
  }

  .multi-character-input {
    float: left;
    text-align: left;
    width: 52%;

    .big-input {
      display: inline-block;
    }

    .big-input + .big-input {
      margin-left: 0.5rem;
    }

    @include media-breakpoint-down(sm) {
      text-align: center;
      width: 100%;
    }
  }
}

.multi-character-container + .multi-character-container {
  padding-top: 0.5rem;

  @include media-breakpoint-down(sm) {
    padding-top: 3rem;
  }
}

.quantity-input-container {
  position: relative;
  max-width: 600px;

  input:placeholder-shown ~ .override-pill {
    display: none;
  }
}
