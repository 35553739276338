$icon-square-check: "\f14a";
$icon-square-minus: "\f146";
$icon-circle-check: "\f058";

.collection_check_boxes {
  padding-left: 0.5rem !important;
}

.checkbox {
  input {
    cursor: pointer;
  }

  input[type="checkbox"] {
    margin-left: -20px;
    position: absolute;
  }
}

.checkbox label {
  align-items: center;
  display: inline-flex;
  font-weight: normal;
  padding-left: 20px;

  input[type="checkbox"] {
    margin-right: 5px;
    position: relative;
  }
}

.checkbox.primary {
  @include clearfix;
  margin-top: 0;
  user-select: none;

  &.block input[type="checkbox"] + label {
    width: 100%;

    > input,
    > textarea {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }

  input[type="checkbox"] {
    cursor: pointer;
    opacity: 0 !important;
    z-index: -1 !important;
  }

  input[type="checkbox"] + label {
    background: $canopy-color-interactive-background-secondary-default;
    border-radius: 2px;
    box-shadow: 0 0 0 1.5px $canopy-color-primitives-gray-86;
    color: $canopy-color-text-primary;
    cursor: pointer;
    display: block;
    min-width: 60px;
    overflow: hidden;
    padding: 15px 20px 15px 42px;
    text-align: left;
    text-overflow: ellipsis;
    transition: 0.2s background;
  }

  input[type="checkbox"]:focus + label {
    outline: none;
  }

  input[type="checkbox"] + label::before {
    background-color: $canopy-color-interactive-background-secondary-default;
    border: 1px solid $canopy-color-primitives-gray-86;
    border-radius: 2px;
    content: "";
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    height: 16px;
    left: 19px;
    line-height: 0.9;
    position: absolute;
    top: 18px;
    width: 16px;
  }

  input[type="checkbox"] + label:hover {
    box-shadow: 0 0 0 1.5px $canopy-color-primitives-purple-24;
  }

  input[type="checkbox"]:checked + label {
    background: $canopy-color-interactive-background-secondary-selected;
    box-shadow: 0 0 0 1.5px $canopy-color-primitives-purple-24;
    color: $canopy-color-text-primary;
  }

  input[type="checkbox"]:checked + label::before {
    border: 0;
    box-shadow: none;
    color: $canopy-color-interactive-link-active;
    content: $icon-square-check;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    transition: 0.2s background-color;
  }

  input[type="checkbox"]:checked + label:hover {
    background: #d3bfdd;
    box-shadow: 0 0 0 1.5px $canopy-color-primitives-purple-24;

    &::before {
      background: #d3bfdd;
    }
  }

  input[type="checkbox"]:disabled + label,
  input[type="checkbox"]:disabled + label::before {
    background: $canopy-color-interactive-background-disabled;
    cursor: not-allowed;
  }

  &.radio {
    input[type="checkbox"] + label::before {
      border-radius: 50%;
    }

    input[type="checkbox"]:checked + label::before {
      content: $icon-circle-check;
    }
  }

  &.unframed {
    input[type="checkbox"] + label,
    input[type="checkbox"] + label:hover,
    input[type="checkbox"]:checked + label,
    input[type="checkbox"]:checked + label:hover,
    input[type="checkbox"]:disabled + label {
      background: transparent;
      box-shadow: none;
    }

    input[type="checkbox"] + label {
      padding-bottom: 5px;
      padding-top: 5px;
    }

    input[type="checkbox"] + label::before {
      top: 9px;
    }

    input[type="checkbox"] + label::after {
      top: 13px;
    }
  }

  &.outdented {
    input[type="checkbox"] + label {
      padding-left: 25px;
    }

    input[type="checkbox"] + label::before {
      left: 0;
    }
  }
}

.checkbox.basic {
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 24px;
  position: relative;
  user-select: none;

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  .icon {
    background-color: $canopy-color-background-secondary;
    border: 1px solid $canopy-color-primitives-gray-86;
    border-radius: 2px;
    cursor: pointer;
    height: 14px;
    left: 0;
    position: absolute;
    top: 5px;
    transition: border-color 0.2s;
    width: 14px;
  }

  input:checked + .icon {
    border-color: $canopy-color-primitives-teal-41;
  }

  input + .icon::before {
    color: $canopy-color-text-success;
    cursor: pointer;
    display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    height: 12px;
    left: -1px;
    position: absolute;
    top: -5px;
    transition: opacity 0.2s;
    width: 12px;
  }

  input:not(:checked) + .icon::before {
    opacity: 0;
  }

  input:checked + .icon::before {
    opacity: 1;
  }

  &.disabled {
    cursor: not-allowed;
  }

  input[type="checkbox"]:disabled + .icon {
    cursor: not-allowed;
  }
}

.checkbox.basic.disabled {
  color: $canopy-color-primitives-gray-86;

  .icon {
    background-color: $canopy-color-background-primary;
  }

  input:not(:checked) + .icon::before {
    opacity: 1;
  }
}

.checkbox.basic input + .icon::before {
  content: $icon-square-check;
}

.checkbox.basic.checkbox-brand {
  input + .icon::before {
    color: $canopy-color-brand-primary;
  }

  input:checked + .icon {
    border-color: $canopy-color-primitives-purple-24;
  }
}

.checkbox.basic.checkbox-danger {
  input + .icon::before {
    color: $canopy-color-primitives-red-48;
  }

  input:checked + .icon {
    border-color: $canopy-color-primitives-red-48;
  }
}

.checkbox.basic.indeterminate input + .icon::before {
  content: $icon-square-minus;
}

.checkbox.basic.disabled input + .icon::before {
  content: "";
}

.checkbox.basic.checkbox-brand.disabled,
.checkbox.basic.checkbox-danger.disabled {
  color: $canopy-color-primitives-gray-86;

  input + .icon::before {
    color: $canopy-color-primitives-gray-74;
    content: $icon-square-check;
    cursor: not-allowed;
  }

  &.indeterminate input + .icon::before {
    content: $icon-square-minus;
  }

  input:checked + .icon {
    border-color: $canopy-color-primitives-gray-74;
    cursor: not-allowed;
  }
}

.checkbox.basic.small {
  @include font-subparagraph;

  .icon {
    height: 12px;
    width: 12px;
    top: 4px;
  }
}

.checkbox + .checkbox {
  margin-top: 0;
}

.checkbox-link {
  @extend .link;
  @include clearfix;
  border-radius: 2px;
  box-shadow: 0 0 0 1px $canopy-color-primitives-gray-86, inset 0 0 0 1px $canopy-color-primitives-gray-94;
  cursor: pointer;
  margin-bottom: 0.5rem;
  min-width: 60px;
  overflow: hidden;
  padding: 15px 20px 15px 15px;
  text-align: left;
  text-overflow: ellipsis;
  transition: 0.2s background;

  &.block {
    display: block;
    width: 100%;
  }

  &:hover {
    text-decoration: underline;
  }
}
