@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "../../../../../../stylesheets/variables/z-indexes.scss";
@import "../../../../../../stylesheets/components/navbar.scss";

.full-height-container {
  $one-extra-pixel-because-it-was-off-by-that-much: 1px;
  $bottom-padding-of-the-workflow-wrapper: 16px;
  $top-padding-of-the-page-wrapper: 1rem;
  $height-of-the-footer-push-div: 50px;
  /* stylelint-disable max-line-length */
  height: calc(100vh - #{$navbar-height} - #{$height-of-the-footer-push-div} - #{$top-padding-of-the-page-wrapper} - #{$bottom-padding-of-the-workflow-wrapper} - #{$one-extra-pixel-because-it-was-off-by-that-much});
  /* stylelint-enable */
  display: flex;
  flex-direction: column;
  max-width: 1440px;
}
