/*
 * It's possible we might pass child functions into the sidebar that ultimately render no markup.
 * Because we won't know until its render, but don't want weird spaces from the sidebar-added margins,
 * this special override rule will collapse any extra margin and padding for empty elements and make them
 * take up no physical space.
 */
.collapseIfEmpty:empty {
  margin: 0 !important;
  padding: 0 !important;
}
