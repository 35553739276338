.square {
  background-color: $canopy-color-interactive-background-primary-default;
  border-radius: 3px;
  box-sizing: border-box;
  color: $canopy-color-text-inverse;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 600;
  height: 32px;
  line-height: 2.5;
  min-width: 34px;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  vertical-align: middle;
  width: 34px;
}

.pink.square {
  background-color: #ec6da4;
}

.purple.square {
  background-color: $canopy-color-brand-primary;
}

.green.square {
  background-color: $canopy-color-primitives-teal-41;
}

.blue.square {
  background-color: #4771aa;
}

.gray.square {
  background-color: $canopy-color-primitives-gray-39;
}

.dark-green.square {
  background-color: $canopy-color-primitives-green-24;
}

.pink-o.square {
  background-color: $canopy-color-background-container;
  border: 1px solid #ec6da4;
  color: #ec6da4;
}

.purple-o.square {
  background-color: $canopy-color-background-container;
  border: 1px solid $canopy-color-primitives-purple-24;
  color: $canopy-color-primitives-purple-24;
}

.dark-green-o.square {
  background-color: $canopy-color-background-container;
  border: 1px solid $canopy-color-primitives-green-24;
  color: $canopy-color-primitives-green-24;
}

.green-o.square {
  background-color: $canopy-color-background-container;
  border: 1px solid $canopy-color-primitives-teal-41;
  color: $canopy-color-primitives-teal-41;
}

.blue-o.square {
  background-color: $canopy-color-background-container;
  border: 1px solid #4771aa;
  color: #4771aa;
}

.gray-o.square {
  background-color: $canopy-color-background-container;
  border: 1px solid $canopy-color-primitives-gray-39;
  color: $canopy-color-primitives-gray-39;
}
