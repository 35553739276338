@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.info-container {
  max-width: 448px;
  min-height: 475px;

  @include media-breakpoint-down(md) {
    min-height: 400px;
  }
}

.info-detail-container {
  margin-bottom: $canopy-space-6x;

  ul {
    padding-inline-start: 10px;
  }

  li {
    margin-left: $canopy-space-4x;
    font-size: $canopy-typography-body-small-font-size;
  }
}

.info-detail-header {
  display: flex;
  align-items: baseline;
}

.info-detail-learn-more-link {
  font-size: $canopy-typography-body-small-font-size;

  link {
    color: $canopy-color-interactive-link-default;
    font-weight: $canopy-typography-font-weight-bold;
  }
}
