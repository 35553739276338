@import "node_modules/@parachutehealth/canopy-tokens-color/build/tokens.scss";

.searchByProductTile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchByProductTile .supplierLabels {
  color: $canopy-color-text-secondary;
  font-size: 0.8rem;
  margin: auto 10px 0;
  padding-bottom: 10px;
}

.tallTile {
  height: 342px;
}

.yourOrgsSuppliersLabel {
  margin-bottom: 12px;
}
