@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.deny-user {
  color: $canopy-color-text-danger;
  background-color: $canopy-color-background-secondary;
  border: 2px solid $canopy-color-text-danger;
}

.secondary-email-button {
  background-color: $canopy-color-brand-secondary;
}

.secondary-email-button:hover {
  background-color: #108378;
}

.secondary-email-button:active {
  background-color: #108378;
}

.secondary-email-button:focus {
  background-color: #108378;
}
