@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.container {
  display: flex;
  border-bottom: 2px solid $canopy-color-primitives-gray-86;
  margin-bottom: $canopy-space-12x;
  padding-bottom: $canopy-space-8x;
}

.tabContainer {
  flex: 2;
  display: inline-block;
  margin-right: $canopy-space-12x;
}

.tab {
  display: inline;
  cursor: pointer;
  font-size: 18px;
  font-weight: 800;
  color: $canopy-color-primitives-gray-39;
  text-decoration: none;
  padding-bottom: $canopy-space-8x;

  &:hover {
    color: inherit;
  }

  &:focus,
  &:active,
  &.tabActive {
    color: $canopy-color-interactive-background-primary-active;
    border-bottom: 2px solid $canopy-color-primitives-purple-24;
    text-decoration: none;
  }
}

.tabList {
  list-style: none;
  margin-bottom: -2px;
  padding-inline-start: 0;
}

.table {
  table-layout: fixed;
}

.template-var {
  background-color: #eee6fa;
}

.add_variable_button {
  @include canopy-typography-body-medium;
  background-color: $canopy-color-primitives-purple-94;
  border: none;
  border-radius: $canopy-space-8x;
  margin-left: $canopy-space-4x;
  padding: 6px 10px;
}
