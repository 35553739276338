.panel {
  background-color: $canopy-color-background-container;
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: 0;
  border-top: 3px solid $canopy-color-primitives-teal-41;
  margin-bottom: 15px;
  padding-bottom: 1rem;
}

.panel-header {
  background-color: $canopy-color-background-container;
  padding: 10px 15px;
  border-bottom: 1px solid $canopy-color-primitives-gray-86;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-body {
  padding: 1rem;
}

.panel-flex-row {
  display: flex;
  align-items: center;

  .last-migration-timestamp {
    margin-left: 7px;
  }
}

.panel-footer {
  padding: 10px 15px;
  background-color: $canopy-color-background-primary;
  border-top: 1px solid $canopy-color-primitives-gray-86;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
