.tooltip {
  z-index: $z-index-tooltip;

  .tooltip-arrow {
    border-color: transparent;
    border-style: solid;
    height: 0;
    position: absolute;
    width: 0;
  }

  &.top {
    padding: 5px 0;
    margin-top: -3px;
  }

  &.top .tooltip-arrow {
    border-top-color: $canopy-color-primitives-gray-16;
    border-width: 5px 5px 0;
    bottom: 0;
    margin-left: -5px;
  }

  &.right {
    padding: 0 5px;
    margin-left: 3px;
  }

  &.right .tooltip-arrow {
    border-right-color: $canopy-color-primitives-gray-16;
    border-width: 5px 5px 5px 0;
    left: 0;
    margin-top: -5px;
  }

  &.bottom {
    padding: 5px 0;
    margin-top: 3px;
  }

  &.bottom .tooltip-arrow {
    border-bottom-color: $canopy-color-primitives-gray-16;
    border-width: 0 5px 5px;
    top: 0;
  }

  &.left {
    padding: 0 5px;
    margin-left: -3px;
  }

  &.left .tooltip-arrow {
    border-left-color: $canopy-color-primitives-gray-16;
    border-width: 5px 0 5px 5px;
    margin-top: -5px;
    right: 0;
  }

  .tooltip-inner {
    background-color: $canopy-color-primitives-gray-16;
    border-radius: 0.75rem;
    color: $canopy-color-text-inverse;
    font-family: $font-body;
    font-size: 0.875rem;
    font-weight: normal;
    padding: 0.5rem;
    text-align: left;
  }

  &.tooltip-max-width-wide .tooltip-inner {
    max-width: 30rem;
  }

  &.tooltip-max-width-unlimited .tooltip-inner {
    max-width: none;
  }

  &.tooltip-min-width-wide .tooltip-inner {
    min-width: 20rem;
  }

  &.fade {
    transition: opacity 0s linear;
  }

  &.in {
    opacity: 1;
    transition: opacity 0.2s linear;
  }
}
