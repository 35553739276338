@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.headerText {
  font-weight: $canopy-typography-heading-2-xlarge-font-weight;
}

.subheaderText {
  color: $canopy-color-text-secondary;
  padding-bottom: $canopy-space-4x;
}
