.d-xs-block {
  @include media-breakpoint-down(sm) {
    display: block !important;
    width: 100% !important;
  }
}

.d-sm-block {
  @include media-breakpoint-only(md) {
    display: block !important;
    width: 100% !important;
  }
}
