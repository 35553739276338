@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.header-container {
  display: flex;
  flex-direction: column;
}

.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  h3 {
    margin: 0;
  }
}

.signature-icon {
  padding-right: 8px;
}

.header-patient-info {
  display: flex;
  margin-bottom: 16px;
}
