@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.sub-option {
  display: flex;
  max-width: 90%;
  min-height: 32px;
  padding: 4px 8px 4px 16px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-left: 4px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
  @include canopy-typography-body-small;
}

.active {
  color: $canopy-color-brand-primary;
  font-weight: bold;
  border-color: $canopy-color-brand-primary;

  &:hover,
  &:focus {
    color: $canopy-color-brand-primary;
  }
}
