@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.thirdPartyLoginContainer {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  .thirdPartyLoginButton {
    margin-top: $canopy-space-4x;
    border: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
    color: $canopy-color-text-secondary !important;
    width: 100%;
    justify-content: flex-start;

    span {
      display: flex;
      align-items: center;
    }

    img {
      height: auto;
      max-height: 24px;
      width: 24px;
      vertical-align: text-bottom;
      margin-right: $canopy-space-4x;
    }
  }
}
