@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.selected-doctors-container {
  margin-bottom: $canopy-space-12x;

  .selected-doctors-list {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    row-gap: $canopy-space-4x;
    column-gap: $canopy-space-2x;
    height: 90px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}

.help-text {
  color: $canopy-color-text-secondary;
  margin-bottom: $canopy-space-8x;
  margin-top: -$canopy-space-12x;
}

.reviewer-attestation {
  margin-bottom: 0;
}

.no-clinicians-selected-alert {
  width: 100%;
}
