.radio.plain {
  input[type="radio"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    opacity: 0 !important;
  }

  input[type="radio"] + label {
    cursor: pointer;
    font-weight: 400;
    min-height: 22px;
    padding: 0;
  }

  input[type="radio"] + label::before {
    background-image: url("../../assets/images/radio-deselected.svg");
    background-repeat: no-repeat;
    background-size: 14px 14px;
    content: "";
    display: inline-block;
    height: 14px;
    margin-right: 5px;
    position: relative;
    top: 1px;
    width: 14px;
  }

  input[type="radio"]:checked + label::before {
    background-image: url("../../assets/images/radio-selected.svg");
  }
}

.radio {
  input[type="radio"] {
    margin-left: -20px;
    position: absolute;
  }

  label {
    font-weight: bold;
    padding-left: 0;
  }

  > label {
    font-weight: normal;
    padding-left: 20px;
  }
}

.radio.primary {
  display: inline-block;
  margin-right: 10px;
  margin-top: 0;
  user-select: none;

  // scss-lint:disable ImportantRule
  input[type="radio"] {
    opacity: 0 !important;
    z-index: -1 !important;
  }

  input[type="radio"] + label {
    border-radius: 30px;
    cursor: pointer;
    display: inline-block;
    min-width: 75px;
    padding: 15px 20px;
    text-align: center;
    transition: 0.2s background;
  }

  input[type="radio"]:focus + label {
    outline: none;
  }

  label {
    padding: 0;
  }
}

.radio.primary.option-exclusive {
  input[type="radio"] + label {
    border-radius: 2px;
  }
}

.radio.primary:last-child {
  margin-right: 0;
}

.radio.primary.block {
  display: block;
  width: 100%;

  input[type="radio"] + label {
    display: block;
    text-align: left;
  }
}

.radio.primary.centered input[type="radio"] + label {
  text-align: center;
}

.radio-cell-container {
  cursor: pointer;
  height: 1px;
  vertical-align: middle !important;

  &:hover {
    background: rgba($canopy-color-primitives-gray-94, 0.75);
  }
}

.radio-cell-container > .radio-cell {
  cursor: pointer;
}

.radio.primary {
  input[type="radio"] + label {
    background: $canopy-color-interactive-background-secondary-default;
    box-shadow: 0 0 0 1.5px $canopy-color-primitives-gray-86;
    color: $canopy-color-text-primary;
    padding: 15px 20px 15px 42px;
  }

  input[type="radio"] + label:hover {
    box-shadow: 0 0 0 1.5px $canopy-color-primitives-purple-24;
  }

  input[type="radio"]:checked + label {
    background: $canopy-color-interactive-background-secondary-selected;
    box-shadow: 0 0 0 1.5px $canopy-color-primitives-purple-24;
    color: $canopy-color-text-primary;
  }

  input[type="radio"] + label::before {
    border: 1px solid $canopy-color-primitives-gray-86;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 16px;
    left: 19px;
    line-height: 0.9;
    position: absolute;
    top: 18px;
    width: 16px;
  }

  input[type="radio"]:checked + label::before {
    border: 1px solid $canopy-color-primitives-purple-24;
  }

  input[type="radio"]:checked + label::after {
    background-color: $canopy-color-interactive-background-primary-active;
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 22px;
    position: absolute;
    top: 21px;
    transition: 0.2s background-color;
    width: 10px;
  }

  input[type="radio"]:checked + label:hover {
    background: #d3bfdd;
    box-shadow: 0 0 0 1.5px $canopy-color-primitives-purple-24;

    &::before {
      background: #d3bfdd;
    }
  }

  input[type="radio"]:disabled + label,
  input[type="radio"]:disabled + label::before {
    background: $canopy-color-interactive-background-secondary-default;
    box-shadow: 0 0 0 1.5px $canopy-color-primitives-gray-86;
    color: $canopy-color-primitives-gray-74;
    cursor: not-allowed;
  }

  input[type="radio"]:disabled + label:hover {
    color: $canopy-color-primitives-gray-74;
    cursor: not-allowed;

    &::before {
      background: #d3bfdd;
    }
  }

  input[type="radio"]:disabled:checked + label {
    background-color: $canopy-color-primitives-gray-97;
    color: $canopy-color-primitives-gray-74;
  }
}

.radio_buttons {
  .radio {
    background-color: $canopy-color-primitives-gray-94;
    border-radius: 3px;
    color: $canopy-color-text-primary;
    cursor: pointer;
    display: block;
    padding: 15px;
    padding-bottom: 16px;
    transition: all 0.2s ease-in-out, opacity 0.15s ease-in-out;
    transition-property: background-color, color;

    // scss-lint:disable NestingDepth
    &:hover {
      background-color: darken($canopy-color-primitives-gray-94, 10%);
    }
    // scss-lint:disable SelectorDepth
    // scss-lint:disable NestingDepth
    label {
      display: block;
      font-weight: bold;
      height: auto;
      max-width: 100%;
      min-height: auto;
      overflow: hidden;
      padding-left: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    // scss-lint:disable SelectorDepth
    // scss-lint:disable NestingDepth
    label[checked] {
      font-weight: bold;
    }

    // scss-lint:disable ImportantRule
    input {
      opacity: 0 !important;
      z-index: -1 !important;
    }
  }

  .radio-selected {
    background-color: $canopy-color-primitives-teal-41;
    color: $canopy-color-text-inverse;

    // scss-lint:disable NestingDepth
    &:hover {
      background-color: darken($canopy-color-primitives-teal-41, 10%);
    }
  }
}

.radio-group {
  display: inline-block;
  font-size: 0; // remove whitespace between html tags

  .radio {
    background-color: $canopy-color-interactive-background-secondary-default;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    margin: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.1s linear;
    vertical-align: middle;
    white-space: nowrap;

    // scss-lint:disable ImportantRule
    input {
      opacity: 0 !important;
      z-index: -1 !important;

      // scss-lint:disable NestingDepth
      &:checked + label {
        background-color: $canopy-color-primitives-teal-97;
        border-color: $canopy-color-primitives-teal-41;
        color: $canopy-color-text-success;
        text-decoration: none;
      }
    }

    label {
      border: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
      color: $canopy-color-text-primary;
      font-size: 16px;
      font-weight: bold;
      height: 75px;
      padding: 15px;
      transition: all 0.1s linear;
      width: 125px;

      // scss-lint:disable NestingDepth
      &:hover {
        color: $canopy-color-text-primary;
      }
    }
  }

  .radio:first-child label {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  .radio:last-child label {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.basic_radio_buttons {
  label.col-form-label {
    display: block;
  }

  label.collection_radio_buttons {
    height: 50px;
    margin-bottom: 0;
    margin-right: 10px;
  }

  input[type="radio"] {
    border: 0;
    display: inline-block;
    height: auto;
    margin-right: 5px;
    width: auto;
  }

  .vertical {
    label {
      display: block;
    }

    label.collection_radio_buttons {
      display: inline;
    }
  }
}

.radio + .radio {
  margin-top: 0;
}

.radio label {
  padding-left: 20px;
}
