@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
$width-input-group: 200px;
$width-append: 50px;
$width-border: 1px;

.inline {
  top: -9px;
  > div {
    align-items: center;
    flex-direction: column;
  }

  &.container:focus-within {
    box-shadow: none;
  }
}

.container:focus-within {
  box-shadow: 0 0 8px lighten($canopy-color-primitives-teal-41, 25%);

  input {
    box-shadow: none;
  }

  .append {
    border-bottom-color: $canopy-color-primitives-teal-41;
    border-right-color: $canopy-color-primitives-teal-41;
    border-top-color: $canopy-color-primitives-teal-41;
  }
}

.append {
  background-color: $canopy-color-interactive-background-secondary-default;
}

.input-group-sm {
  width: $width-input-group;
}

.form-control-sm {
  width: $width-input-group - $width-append + $width-border;
}
