@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.sectionTitle {
  min-width: 160px;
}

.sectionBody {
  display: flex;
  flex-direction: column;
  border: 1px solid $canopy-color-border-default;
  border-radius: 4px;
  flex-grow: 1;
}

.sectionBodyItem {
  display: flex;
  flex-direction: row;
  padding: $canopy-space-4x $canopy-space-8x;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid $canopy-color-border-default;
  }
}

.sectionBodyTitle {
  width: 170px;
  margin-right: $canopy-space-4x;
}

.sectionBodyValue {
  flex-grow: 1;
}
