$collapsed-left-sidebar-width: 50px;
$expanded-left-sidebar-width: 200px;
$expanded-left-sidebar-extra-width: 250px;

.left-sidebar {
  background: $canopy-color-background-container;
  border-right: 1px solid $canopy-color-primitives-gray-74;
  display: block;
  margin-left: -15px;
  width: $collapsed-left-sidebar-width;
  height: 100%;
  position: absolute;
  z-index: $z-index-left-sidebar;

  + div {
    margin-left: $collapsed-left-sidebar-width - 15px;
  }

  &.expanded {
    width: $expanded-left-sidebar-width;
    &.extra-width {
      width: $expanded-left-sidebar-extra-width;

      + div {
        margin-left: $expanded-left-sidebar-extra-width - 15px;
      }
    }

    + div {
      margin-left: $expanded-left-sidebar-width - 15px;
    }
  }
}
