@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.label {
  color: $canopy-color-primitives-gray-39;
}

.label-cell {
  padding-left: 0;
  width: 150px;
}

.link {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
