.alert {
  border-radius: 0;
  color: $canopy-color-text-primary;
  font-size: 0.875rem;
  margin-bottom: 0;
  padding: 1rem;
  position: relative;

  &.has-right-icon {
    padding-right: 2rem;
  }
}

.alert a {
  font-weight: bold;
}

.alert > h1 {
  margin: 0;
  padding: 1rem;
}

.alert > .arrow {
  cursor: pointer;
  position: absolute;
  right: 1.25rem;
  top: 1.375rem;
}

.alert > .alert-icon,
.alert > .fas {
  display: inline-block;
  margin-right: $canopy-space-1x;
  margin-top: -1px;

  &.left {
    margin-right: 0.5rem;
  }

  &.right {
    display: block;
    line-height: 1.5;
    margin-right: 0;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;
  }

  &::before {
    display: inline;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }
}

.alert.alert-rounded {
  border-radius: 4px;
}

.alert.alert-success {
  background-color: $canopy-color-background-success;
  border: 1px solid $canopy-color-primitives-teal-41;

  > .alert-icon::before {
    color: $canopy-color-brand-secondary;
    content: "\f058";
  }

  a {
    color: $canopy-color-brand-secondary;
  }
}

.alert.alert-info {
  background-color: $canopy-color-background-information;
  border: 1px solid $canopy-color-primitives-blue-41;

  > .alert-icon::before {
    color: $canopy-color-primitives-blue-41;
    content: "\f05a";
  }

  a {
    color: $canopy-color-primitives-blue-41;
  }
}

.alert.alert-accent {
  background-color: $canopy-color-primitives-magenta-98;
  border: 1px solid $canopy-color-primitives-magenta-40;

  > .alert-icon::before {
    color: $canopy-color-primitives-magenta-40;
    content: "\f05a";
  }

  a {
    color: $canopy-color-primitives-magenta-40;
  }
}

.alert.alert-warning {
  background-color: $canopy-color-background-warning;
  border: 1px solid $canopy-color-primitives-yellow-62;

  > .alert-icon::before {
    color: $canopy-color-primitives-yellow-62;
    content: "\f071";
  }

  a {
    color: $canopy-color-primitives-gray-39;
  }
}

.alert.alert-danger {
  background-color: $canopy-color-background-danger;
  border: 1px solid $canopy-color-primitives-red-48;

  > .alert-icon::before {
    color: $canopy-color-text-danger;
    content: "\f06a";
  }

  a {
    color: $canopy-color-text-danger;
  }
}

.alert.alert-accent {
  background-color: $canopy-color-primitives-magenta-98;
  border: 1px solid $canopy-color-primitives-magenta-40;

  > .alert-icon::before {
    color: $canopy-color-primitives-magenta-40;
    content: "\f024";
  }
}

.alert.alert-default {
  background-color: $canopy-color-primitives-gray-94;
  border: 1px solid $canopy-color-primitives-gray-74;
  color: $canopy-color-primitives-gray-39;

  > .alert-icon::before {
    color: $canopy-color-primitives-gray-74;
    content: "\f05a";
  }
}

.alert.alert-textured {
  background-image:
    linear-gradient(
      135deg,
      $canopy-color-primitives-white-100 41.67%,
      $canopy-color-primitives-gray-94 41.67%,
      $canopy-color-primitives-gray-94 50%,
      $canopy-color-primitives-white-100 50%,
      $canopy-color-primitives-white-100 91.67%,
      $canopy-color-primitives-gray-94 91.67%,
      $canopy-color-primitives-gray-94 100%
    );
  background-size: 7px 7px;
  border: 1px solid $canopy-color-primitives-gray-86;
}

.alert.alert-borderless {
  border: 0;
}

.alert {
  .arrow-icon::before {
    color: $canopy-color-primitives-gray-16;
    content: "\f054";
    cursor: pointer;
  }

  .times-icon::before {
    color: $canopy-color-primitives-gray-16;
    content: "\f00d";
    cursor: pointer;
  }
}
