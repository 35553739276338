@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.container {
  max-width: 1200px;
  width: 100%;
  text-align: center;
  padding: 0 1rem;
  margin: 2rem auto;
  @include media-breakpoint-up(md) {
    padding: 0 2rem;
  }
}

.icon {
  height: 2.5rem;
  width: 2.5rem;
  color: $canopy-color-brand-secondary;
  margin: 0 1rem 0.75rem 0;
}

.no-requests-header {
  text-align: left;
  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

.subhead {
  @include media-breakpoint-up(md) {
    font-size: 24px;
    margin-bottom: 2rem;
  }
}

.marketing-content-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 3rem;
  }
}

.marketing-content-inner-container {
  background-color: white;
  box-shadow: 2px 3px 64px -21px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.marketing-content-image {
  width: inherit;
  max-width: 400px;
  height: inherit;
  margin: auto;
  padding-bottom: 1rem;
  object-fit: cover;
}

.feature-description {
  font-size: 14px;
}
