@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.template-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: $canopy-space-4x;
  margin-bottom: $canopy-space-4x;

  input[type="radio"]:checked + .template-label {
    color: $canopy-color-brand-primary;
    border: 1px solid $canopy-color-brand-primary;
    background-color: $canopy-color-interactive-background-secondary-active;
  }
}

.template {
  height: 3.5rem;
}

.hidden-radio {
  display: none;
}

.template-label {
  @include canopy-typography-body-small;
  color: $canopy-color-primitives-gray-48;
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: 0.25rem;
  padding: $canopy-space-4x;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
