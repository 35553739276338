@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.backLink-container {
  margin-top: $spacer * 0.5;
  &hr {
    background-color: $canopy-color-primitives-gray-94;
    margin-top: $spacer;
    margin-bottom: $spacer;
  }
}

.header {
  display: flex;
}

.patient-info {
  flex-grow: 1;
}

.patient-name {
  margin-bottom: $spacer * 0.5;
  font-weight: 800;
}

.patient-name-icon {
  margin-right: $spacer * 0.5;
  font-size: 14px;
}

.deny-request-button {
  margin-right: $spacer;
  color: $canopy_color_text_danger;
}

.patient-extended-info {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: $canopy-color-primitives-gray-39;
}

.action-buttons {
  flex-grow: 0;
}

.documents-container {
  padding-left: 0;
  padding-right: 0;
}
