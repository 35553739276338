@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.disabled-row-checked {
  pointer-events: none;

  :global .Mui-checked {
    color: $canopy-color-text-disabled !important;
  }
}

.disabled-row-unchecked {
  pointer-events: none;
  opacity: 0.5;
}
