.ssr-app {
  .package-modal-button {
    text-decoration: underline;
  }

  .text-area-button {
    background-color: $canopy-color-primitives-gray-94;
    border-radius: 23px;
    color: $canopy-color-primitives-gray-39;
    font-size: 0.8125rem;
    padding: 0.5rem 1rem;
  }

  .activity-feed-button {
    background-color: $canopy-color-interactive-background-secondary-default;
    border: 1px solid $canopy-color-brand-primary;
    border-radius: 2px;
    color: $canopy-color-primitives-purple-24;
    font-size: 0.8125rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
  }

  .nav-bar {
    background-color: $canopy-color-background-secondary;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }

  .nav-bar-spacer {
    padding-top: 6.75rem;
  }

  .search-orders-header-spacer {
    padding-top: 6.375rem;
  }

  .order-list-card {
    background-color: $canopy-color-background-secondary;
    border-radius: 2px;
    color: $canopy-color-text-primary;

    .order-list-card-caption {
      font-size: 0.625rem;
      font-weight: bold;
      letter-spacing: 0.0625rem;
    }
  }

  .list-bullet {
    $left-padding: 1.2em;
    list-style: none;
    padding: 0;

    li {
      padding-left: $left-padding;

      &::before {
        background-color: $canopy-color-primitives-gray-86;
        border-radius: 50%;
        content: "";
        display: inline-block;
        height: 5px;
        margin-bottom: 2px;
        margin-left: -$left-padding;
        margin-right: $left-padding;
        width: 5px;
      }
    }
  }

  .sticky-bottom {
    background-color: $canopy-color-background-secondary;
    bottom: 0;
    box-shadow: 0 -2px 6px 0 rgba($canopy-color-primitives-gray-16, 0.05);
    left: 0;
    position: fixed;
    right: 0;
  }

  .sticky-bottom-padding {
    height: 3.3475rem;
  }

  input[type="search"] {
    border: 0;
    box-shadow: none;
    outline: none;
    padding: 1rem 1.5rem;
  }

  .form-group {
    margin-bottom: 0;
  }

  .radio {
    input[type="radio"] {
      display: none;
    }

    .ssr-app-radio-outer-circle {
      border: 1px solid;
      border-color: $canopy-color-primitives-gray-86;
      border-radius: 50%;
      height: 1.5rem;
      width: 1.5rem;
    }

    .ssr-app-radio-inner-circle {
      display: none;
    }

    input[type="radio"]:checked + label {
      .ssr-app-radio-outer-circle {
        background-color: $canopy-color-interactive-background-primary-active;
        border-color: $canopy-color-interactive-background-primary-active;
      }

      .ssr-app-radio-inner-circle {
        background-color: $canopy-color-interactive-background-secondary-default;
        border-radius: 50%;
        display: block;
        height: 0.5rem;
        width: 0.5rem;
      }
    }
  }
}

.ssr-app-modal-dialog {
  &:focus {
    outline: none;
  }

  &.bottom-sheet {
    bottom: 0;
    max-height: 0%;
    overflow-y: hidden;
    position: absolute;
    transition: max-height 0.3s ease-out;
    width: 100%;
  }

  &.bottom-sheet-open {
    max-height: 100%;
  }

  &.bottom-sheet-close {
    max-height: 0%;
  }

  &.message {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    top: 13.75rem;
  }
}

.ssr-app-modal-content {
  border-radius: 0;
  box-shadow: none;
}

.internal-order-top-nav {
  background-color: $canopy-color-background-secondary;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.internal-order-top-inset-opacity {
  background-color: $canopy-color-background-secondary;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.ssr-app-modal-backdrop {
  background-color: $canopy-color-background-secondary;

  .ssr-app-modal-title {
    margin: 10px auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 66%;
  }

  .ssr-app-modal-header {
    border-bottom: solid 1px $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
    box-shadow: none;
    left: 0;
    padding: 1rem 0 0;
    position: fixed;
    right: 0;
    z-index: 10;

    .ssr-app-modal-action {
      line-height: 1.2;
      position: absolute;
      right: 1.5rem;
    }
  }

  .ssr-app-modal-header-padding {
    height: 3rem;
  }
}

.ssr-app-bottom-sheet-content {
  border-radius: 24px 24px 0 0;
  overflow: hidden;

  .ssr-app-modal-header {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    box-shadow: none;
  }
}

.order-link-modal-background {
  height: 100vh;
}

.order-link-code-container {
  border-radius: 8px;
}

.order-link-access-code-container {
  max-width: 18rem;
}

.order-link-security-code-container {
  max-width: 12rem;
}

.order-link-digit {
  font-size: 40px;
}

.order-link-buttons-container {
  bottom: 24px;
  position: fixed;
  width: 100%;
}

.flex-buffer {
  flex-grow: 1;
}

.ssr-pill {
  background-color: $canopy-color-primitives-gray-94;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
}

.bordered {
  border: solid 1px $canopy-color-primitives-purple-24;
}

.ssr-app-scan-button {
  background-color: $canopy-color-primitives-purple-98;
  border: solid 1px $canopy-color-primitives-purple-24;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
}

.ssr-app-unstyled-button {
  background: inherit;
  border: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.snackbar {
  background-color: $canopy-color-primitives-gray-16;
  border-radius: 4px;
  margin: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.snackbar-container {
  bottom: 0;
  position: fixed;
  width: 100%;
}

.ssr-app-order-activity-separator {
  margin-left: 0;
  width: 35px;
}

.ssr-app-signature-divider {
  background-color: $canopy-color-background-primary;
  height: 150px;
  margin-top: 1.5rem;
}

.close-button-container {
  align-items: center;
  background-color: $canopy-color-primitives-gray-94;
  border-radius: 50%;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;

  &:hover,
  &:active {
    background-color: $canopy-color-primitives-gray-74;
  }
}

.close-button-margin {
  margin-right: -0.5rem;
}

.activity-preview-margin {
  margin-left: 1.75rem;
}

.activity-preview-icon {
  margin-top: 3px;
}

.activity-preview-icon-container {
  width: 1rem;
}

.activity-preview-line {
  border-left-color: $canopy-color-primitives-gray-86;
  border-left-style: dashed;
  border-left-width: 1px;
  height: 100%;
  margin-bottom: -0.6875rem;
  margin-top: 0.5rem;
}

.small-icon-container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 1.5rem;
}

.larger-icon-container {
  align-items: center;
  background-color: $canopy-color-background-secondary;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba($canopy-color-primitives-gray-16, 0.08);
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
}

.larger-icon-container-dark {
  @extend .larger-icon-container;
  background-color: $canopy-color-primitives-gray-39;
  color: $canopy-color-text-inverse;
}

.offline {
  padding-top: 200px;
}

.ssr-small-paragraph {
  font-size: 0.8125rem;
}

.bottom-sheet-subtitle-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate-container {
  min-width: 0;
}

.case-manager-contact-button {
  align-items: center;
  background-color: $canopy-color-primitives-purple-98;
  border-radius: 50%;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
}

.ssr-app-required-documentation-row .ssr-app-required-documentation-row-button {
  color: $canopy-color-text-primary !important; // we need this to override the button !important color white
  font-weight: normal;
  line-height: 16px;
}
