@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.internalNotesContainer {
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: 8px;
  background-color: $canopy-color-background-container;
  padding: $canopy-space-6x;
}

.tooltipContainer {
  cursor: help;
  margin-top: $canopy-space-4x;
  display: flex;
  justify-content: center;
}
