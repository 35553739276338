@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.favoriteIconSaved {
  color: $canopy-color-primitives-purple-63;
  &:hover {
    color: $canopy-color-primitives-purple-48;
  }
}

.favoriteIconUnsaved {
  color: $canopy-color-primitives-gray-74;
  &:hover {
    color: $canopy-color-primitives-gray-60;
  }
}

.iconButton {
  cursor: pointer;
  border-radius: 50%;
  height: 2.5rem;
  padding: $canopy-space-4x;
  width: 2.5rem;

  &:hover {
    background-color: $canopy-color-primitives-gray-94;
  }
}
