.d-flex {
  &.justify-content-center {
    justify-content: center;
  }

  &.justify-content-space-between {
    justify-content: space-between;
  }

  &.align-items-center {
    align-items: center;
  }

  &.flex-row {
    flex-direction: row;
  }

  &.flex-column {
    flex-direction: column;
  }

  &.flex-wrap {
    flex-flow: wrap;
  }
}

.flex-1 {
  flex: 1;
}

.flex-3 {
  flex: 3;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none !important;
}
