@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.highlight-indicator {
  color: $canopy-color-primitives-magenta-40;
  margin-left: -15px;
  margin-bottom: 0;
  float: left;

  &::before {
    content: "•";
  }
}

.supplier-link {
  background: none;
  border: none;
  color: $canopy-color-primitives-gray-39;
  display: block;
  padding: $spacer * 0.5 $spacer * 0.5 $spacer * 0.5 $spacer * 1.5;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: $canopy-color-interactive-background-secondary-hover;
    border-radius: $spacer * 0.25;
    text-decoration: none;
  }
  &:active {
    background-color: $canopy-color-primitives-gray-94;
    border-radius: $spacer * 0.25;
    text-decoration: none;
  }
}

.supplier-link-selected {
  background-color: $canopy-color-primitives-gray-94;
  border-radius: $spacer * 0.25;
}
