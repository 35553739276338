@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
$fa-font-path: "../../assets/fonts";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";

@font-face {
  font-family: Avenir;
  src: url("../../assets/fonts/Avenir.woff2") format("woff2");
  font-style: normal;
  font-weight: $canopy-typography-font-weight-regular;
}

// The following font-face declarations are mapped to an alias for Avenir to facilitate an easier migration to true
// Avenir bold
@font-face {
  font-family: CanopyAvenir;
  src: url("../../assets/fonts/Avenir.woff2") format("woff2");
  font-style: normal;
  font-weight: $canopy-typography-font-weight-regular;
}

@font-face {
  font-family: CanopyAvenir;
  src: url("../../assets/fonts/AvenirLTStd-Heavy.woff2") format("woff2");
  font-style: normal;
  font-weight: $canopy-typography-font-weight-bold;
}

@font-face {
  font-family: CanopyAvenir;
  src: url("../../assets/fonts/AvenirLTStd-Oblique.woff2") format("woff2");
  font-style: italic;
  font-weight: $canopy-typography-font-weight-regular;
}
