@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.td {
  transition-duration: 0.2s;
  &.selected {
    background-color: $canopy-color-interactive-background-secondary-selected !important;
  }
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
