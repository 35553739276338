@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
$navbar-height: 57px;

.nav {
  margin-bottom: $canopy-space-8x;
}

.transparent {
  background-color: transparent !important;

  &:hover {
    background-color: $canopy-color-primitives-gray-94 !important;
  }
}

.container {
  height: calc(100vh - #{$navbar-height});
  overflow-y: auto;
}

.orderActionsContainer {
  background-color: $canopy-color-primitives-white-100;
  border-bottom: 1px solid $canopy-color-primitives-gray-86;
  padding: $canopy-space-8x;
}
