$note-width: 20px;

.note {
  > .fas {
    margin-top: 3px;
    position: absolute;
    width: $note-width;
  }

  &.note-default > .fas {
    color: $canopy-color-primitives-gray-39;
  }

  &.note-success > .fas {
    color: $canopy-color-text-success;
  }

  &.note-info > .fas {
    color: $canopy-color-primitives-blue-41;
  }

  &.note-warning > .fas {
    color: $canopy-color-primitives-yellow-62;
  }

  &.note-danger > .fas {
    color: $canopy-color-text-danger;
  }

  > .description {
    color: $canopy-color-primitives-gray-39;
    padding-left: $note-width;
  }
}
