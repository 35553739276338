.bold {
  font-weight: 700 !important;
}

.regular {
  font-weight: 400 !important;
}

.italic {
  font-style: italic;
}

.caps {
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}

.preline {
  white-space: pre-line;
}

.linethrough {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.compact {
  @include font-xs;
  line-height: 95% !important;
  margin-bottom: 0.5rem;
}

.text-justify {
  text-align: justify !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-3 {
  line-height: 3 !important;
}

.font-xxxxl {
  @include font-xxxxl;
}

.font-xxxl {
  @include font-xxxl;
}

.font-xxl {
  @include font-xxl;
}

.font-xl {
  @include font-xl;
}

.font-l {
  @include font-l;
}

.font-m {
  @include font-m;
}

.font-s {
  @include font-s;
}

.font-xs {
  @include font-xs;
}

.font-xxs {
  @include font-xxs;
}

.font-paragraph {
  font-size: 1rem;
}

.font-subparagraph {
  @include font-subparagraph;
}

.font-notice {
  font-size: 0.75rem;
}

.font-primary {
  font-family: $font-body;
}
