@import "node_modules/@parachutehealth/canopy-tokens-color/build/tokens.scss";

.badge {
  padding: 0 6px;
  position: relative !important;
  width: auto;
  height: 20px;
  vertical-align: inherit;

  :global(.MuiBadge-badge) {
    position: relative !important;
  }

  :global .MuiBadge-anchorOriginTopRightRectangle {
    background-color: $canopy-color-primitives-gray-94;
    transform: none;
    font-weight: bold;

    :global(.Mui-selected) & {
      background-color: $canopy-color-primitives-purple-94;
    }
  }
}
