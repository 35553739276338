textarea {
  max-width: 100%;
  resize: vertical;
}

textarea.form-control {
  &:focus {
    border-color: $canopy-color-primitives-teal-41;
    box-shadow: 0 0 8px lighten($canopy-color-primitives-teal-41, 25%);
  }
}

.textarea-container.has-alert {
  margin-bottom: 1rem;

  .form-control {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 0.875rem;
    margin-bottom: -1px;
    padding: 1rem;
  }

  .form-control + .alert {
    border: 1px solid #ccc;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-color: $canopy-color-primitives-gray-86;
    color: $canopy-color-primitives-gray-39;
    font-size: 0.75rem;
    padding: 0.5rem 0.75rem;
  }
}

.textarea-container.has-focus {
  .form-control {
    border-color: $canopy-color-primitives-teal-41;
    box-shadow: 0 0 8px lighten($canopy-color-primitives-teal-41, 25%);
  }

  .form-control + .alert {
    border-color: $canopy-color-primitives-teal-41;
  }
}

.textarea-container.has-autosize .form-control {
  resize: none;
}
