@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.container {
  display: flex;
  border-bottom: 2px solid $canopy-color-primitives-gray-86;
  margin-bottom: $spacer * 1.5;
  padding-bottom: $spacer;
}

.tabContainer {
  flex: 2;
  display: inline-block;
  margin-right: $spacer * 1.5;
}

.tab {
  font-size: 18px;
  font-weight: 800;
  color: $canopy-color-primitives-gray-39;
  text-decoration: none;
  padding-bottom: $spacer;

  &:hover {
    color: inherit;
  }

  &:focus,
  &:active,
  &.tabActive {
    color: $canopy-color-interactive-background-primary-active;
    border-bottom: 2px solid $canopy-color-primitives-purple-24;
    text-decoration: none;
  }
}

.tabList {
  list-style: none;
  margin-bottom: -2px;
  padding-inline-start: 0;
}
