@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.detail + .detail::before {
  color: $canopy-color-text-primary;
  content: "|";
  display: inline-block;
  margin: 0 0.25rem;
}

.icon {
  color: $canopy-color-primitives-gray-39;
  font-size: 80%;
}
