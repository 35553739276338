@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.container {
  margin-bottom: $canopy-space-4x;

  @include media-breakpoint-up(md) {
    width: 320px !important;
  }
}

.input {
  background: $canopy-color-interactive-background-secondary-default;
  border: 1px solid $canopy-color-primitives-gray-86;
  box-shadow: none;
  display: inline-block;
  margin-bottom: 0;
}

.input-sm {
  font-size: 0.875rem;
  height: 40px;

  + .feedback {
    height: 40px;
    line-height: 40px !important;
    width: 40px;
  }
}

.rounded-md {
  border-radius: 4px;
}

.rounded-lg {
  border-radius: 40px;
  padding-left: 1rem;
}

.remove-clear-icon-ie::-ms-clear {
  display: none;
}
