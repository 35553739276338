@import "node_modules/@parachutehealth/canopy-tokens-color/build/tokens.scss";
@import "node_modules/@parachutehealth/canopy-tokens-space/build/tokens.scss";

.mainContainer {
  background-color: $canopy-color-background-secondary;
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: $canopy-space-4x;
  box-shadow: none;
  padding: $canopy-space-8x;
}

.full {
  @extend .mainContainer;
}

.medium {
  @extend .mainContainer;
  max-width: 40.625rem;
  padding-bottom: $canopy-space-12x;
  padding-top: $canopy-space-12x;
}

.compact {
  padding: 0;
}
