@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

/*
  This class is meant to be applied to the outermost <TableContainer> component.
*/
.tableContainer {
  border-radius: 0.313rem;
  border: 1px solid $canopy-color-primitives-gray-86;

  table {
    & tbody {
      & td,
      th {
        padding: $canopy-space-6x;
      }
      & tr:last-child td {
        border-bottom: none;
      }
    }

    thead th {
      @include canopy-typography-heading-small;
      font-weight: $canopy-typography-font-weight-bold;
      border-bottom: 1px solid $canopy-color-primitives-gray-86;
      padding: $canopy-space-8x $canopy-space-6x;
      background-color: $canopy-color-primitives-gray-97;
    }
  }
}
