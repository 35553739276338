@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.signature-pad {
  box-shadow: 0 0 0 1px $canopy-color-border-form-control-default;  // TODO: Swap for Canopy token when available
  border-radius: 0.25rem; // TODO: Swap for Canopy token when available
  width: 100%;
}

.signature-pad-error {
  box-shadow: 0 0 0 1px $canopy-color-text-danger inset;  // TODO: Swap for Canopy token when available
}
