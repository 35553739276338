@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.suggestion {
  background-color: $canopy-color-text-inverse;
  color: $canopy-color-text-primary;
  cursor: pointer;
  display: block;
  margin-bottom: 0;
  padding: 13px 12px;
  position: relative;
  user-select: none;
  width: 100%;

  small {
    color: $canopy-color-primitives-gray-39;
  }

  &:hover,
  &.active,
  &.highlight,
  &.disabled,
  &.focused {
    background: $canopy-color-interactive-background-secondary-hover;
  }

  &.small {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }

  &:hover.unselectable {
    background-color: $canopy-color-text-inverse;
    cursor: default;
    user-select: none;
  }

  &:hover > a {
    text-decoration: underline;
  }

  &.disabled {
    color: $canopy-color-primitives-gray-74;
    cursor: not-allowed;

    small {
      color: $canopy-color-primitives-gray-74;
    }
  }
}
