$sidebar-navbar-height: 170px;

.new-comments {
  color: $canopy-color-primitives-magenta-40;

  &::after {
    bottom: 26px;
    content: ".";
    font-size: 50px;
    position: absolute;
    right: 11px;
  }
}

.right-sidebar-is-open {
  .shopping-cart-btn {
    span {
      display: none !important;
    }

    i {
      // scss-lint:disable ImportantRule
      display: inline-block !important;
    }
  }

  .right-sidebar {
    &.fixed {
      @include media-breakpoint-down(sm) {
        margin-top: -1rem;
      }
    }
  }
}

.right-sidebar {
  height: 100%;
  position: absolute;

  .filter-dropdown {
    text-align: right;

    .fa-info-circle {
      font-size: 0.625rem;
    }

    span {
      color: $canopy-color-primitives-gray-39;
      font-size: 0.875rem;
    }

    strong {
      font-size: 0.875rem;
      font-weight: 400;
    }

    .dropdown-menu {
      float: right;
      left: auto;
      right: 0;
      width: 230px;
    }
  }

  .sidebar-logo {
    margin-bottom: 0.5rem;
    max-height: 36px;
    max-width: 150px;
  }

  .sidebar-square-buttons {
    border-left: 1px solid $canopy-color-primitives-gray-86;
    border-right: 1px solid $canopy-color-primitives-gray-86;
    float: right;
    height: 100%;
    width: 50px;

    hr {
      margin: 0;
    }

    button {
      background-color: $canopy-color-interactive-background-secondary-default;
      border-radius: 0;
      border-width: 0;
      height: 48px;
      line-height: 38px;
      padding: 0;
      width: 48px;

      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        outline: 0;
      }

      &:hover {
        background-color: $canopy-color-interactive-background-secondary-hover;
        color: $canopy-color-interactive-link-active;
      }

      &:active,
      &:focus {
        background-color: $canopy-color-interactive-background-secondary-default;
      }

      &.active {
        background-color: $canopy-color-interactive-background-secondary-active;
        border: 1px solid $canopy-color-primitives-purple-24;
        box-shadow: none;
        color: $canopy-color-interactive-link-active;
      }
    }
  }

  .content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.right-sidebar-body {
  background: $canopy-color-background-container;
  height: 100%;
  min-height: 250px;
  overflow-y: auto;
  padding-bottom: $header-height;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  h3 {
    line-height: 36px;
    margin: 0;
    padding: 0;
  }

  @include media-breakpoint-down(sm) {
    border: 0;
    margin: 15px 0;
    position: relative;
  }

  #sidebar-actions {
    margin-top: 0.5rem;
    text-align: right;
  }

  #sidebar-documents {
    background: $canopy-color-background-container;
    border: 1px solid $canopy-color-primitives-gray-86;
    cursor: move;
    margin-top: 0.5rem;
    max-height: calc(100vh - #{10px + $navbar-height + $sidebar-navbar-height});
    min-height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;

    h4 {
      margin: 3rem;
      text-align: center;
    }

    .image {
      position: relative;
    }

    .checkbox {
      margin-bottom: 4px;
    }

    .image .overlay {
      background: $canopy-color-primitives-gray-39;
      content: "\A";
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: all 0.1s;
      width: 100%;
    }

    .image.hover .overlay {
      opacity: 0.5;
    }
  }
}

@include media-breakpoint-down(sm) {
  .right-sidebar {
    position: relative;
  }

  .right-sidebar-is-open .right-sidebar-body {
    margin-bottom: 0;
    margin-top: 0;
  }

  .sidebar-square-buttons {
    display: none;
  }

  .mobile-buttons {
    display: block;
    margin-top: 1rem;
    padding: 0 15px;
  }
}

@include media-breakpoint-up(md) {
  .right-sidebar {
    margin-top: 0;
    right: 0;

    &.fixed {
      position: fixed;
      top: $navbar-height;
    }
  }

  .sidebar-square-buttons {
    display: block;
  }

  .mobile-buttons {
    display: none;
  }
}

.patient-chat-tooltip {
  background:
    linear-gradient(
      #520079,
      #6100b3
    );
  border-radius: 4px;
  color: $canopy-color-text-inverse;
  font-family: $font-body;
  display: inline-flex;
  max-width: 400px;
  padding: $canopy-space-8x;
  flex-direction: column;
  align-items: flex-start;
  gap: $canopy-space-4x;
  box-shadow: 0 2px 8px 0 #d7dddf;

  h2 {
    font-size: 16px;
    line-height: 1.5;
    font-weight: $canopy-typography-font-weight-bold;
  }

  button {
    padding: $canopy-space-4x $canopy-space-8x;
    background: transparent;
    border-radius: 4px;
    border: 2px solid #fff;
    color: #fff;
    font-size: $canopy-typography-body-small-font-size;
    font-weight: $canopy-typography-font-weight-bold;
    align-self: end;
  }

  .patient-chat-tooltip__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
