@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.button {
  margin-bottom: 1rem;

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem !important;

    &::before {
      margin: 0 !important;
      width: 14px;
      padding: 0 1rem !important;
    }
  }
}

.checkbox {
  margin-left: 8px !important;
}

.checkbox-label {
  margin-top: 8px !important;
  font-size: $canopy-typography-body-small-font-size !important;
  font-weight: $canopy-typography-font-weight-regular !important;
}
