.section-content-block {
  border-bottom-color: $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 1rem;
  padding-top: 1rem;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
