@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $canopy-color-background-primary;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  height: $spacer * 2.5;
  width: $spacer * 2.5;
  color: $canopy-color-brand-secondary;
  margin-bottom: $spacer * 2;
}

.message {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: $spacer * 2;
  text-align: center;
}
