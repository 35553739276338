@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.toggle {
  border-radius: 0.25rem;
  height: 40px;
  line-height: 40px;
  padding: 0 0.82rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
  font-weight: bolder;
  font-size: 0.875rem;
  border: none;
  background-color: $canopy-color-interactive-background-secondary-default;
}

.toggle:hover {
  background: $canopy-color-primitives-gray-94;
}

.toggle:active {
  background: $canopy-color-primitives-gray-86;
}

.active {
  background: $canopy-color-interactive-background-secondary-active;
  color: $canopy-color-interactive-link-active;
}

.active:hover {
  background: $canopy-color-primitives-purple-94;
}

.active:active {
  background: $canopy-color-primitives-purple-87;
}

.disabled {
  cursor: not-allowed;
  color: $canopy-color-primitives-gray-39;
}

.label {
  display: none;
  @include media-breakpoint-up(lg) {
    display: inline;
  }
}

.collapsed-label {
  display: inline;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
