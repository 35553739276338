$z-index-document-overlay: 2;
$z-index-tile-overlay: 9;
$z-index-select-menu: 10;
$z-index-left-nav: 10;
$z-index-document-toggle: 10;
$z-index-left-sidebar: 10;
$z-index-spinner-overlay: 10;
$z-index-workflow-overlay: 10;
$z-index-feedback-button: 11;
$z-index-right-sidebar: 11;
$z-index-btn-bottom-left-fixed: 11;
$z-index-popover: 99;
$z-index-toolbar: 99;
$z-index-bottom-navigation-mobile: 100;
$z-index-navbar: 100;
$z-index-tooltip: 101;
$z-index-modal-backdrop: 1040;
$z-index-bottom-navigation-overlay: 1050;
$z-index-modal: 1050;
