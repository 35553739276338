@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.container {
  padding: 0 $spacer * 1.5;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  overflow-y: auto;
}
