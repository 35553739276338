@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.patient {
  background-color: $canopy-color-background-secondary;
  border-bottom: 1px solid $canopy-color-primitives-gray-86;
  clear: both;
  padding: 15px 20px;

  @include media-breakpoint-up(lg) {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
}

.patient-info-container {
  display: flex;
  flex-direction: column;
}

.patient-info-item {
  margin: 0;
  color: $canopy-color-primitives-gray-39;
  font-size: 14px;
}

.patient-info-label {
  font-family: $canopy-typography-font-family-default;
  font-weight: $canopy-typography-font-weight-bold;
}
