.cobranding {
  img:first-child {
    height: 30px;
  }

  img:last-child {
    height: 40px;
  }

  .cobranding-plus {
    font-size: 1.5rem;
    line-height: 2;
    padding: 0 10px;
    vertical-align: top;
  }
}
