@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.container {
  display: flex;
  position: relative;
}

.icon {
  font-size: 1.125rem !important;
}

.active {
  color: $canopy-color-interactive-link-active;
}

.badge {
  background: $canopy-color-primitives-magenta-40;
  border-radius: 100%;
  display: block;
  height: 10px;
  position: absolute;
  right: -2px;
  top: -4px;
  width: 10px;
}
