.shortcut {
  border-bottom: 3px solid transparent;
  color: $canopy-color-primitives-gray-39;
  display: inline-block;
  position: relative;

  &.current {
    border-bottom: 3px solid $canopy-color-primitives-teal-41;
  }

  // scss-lint:disable ImportantRule
  > a {
    @include font-xs;
    color: $canopy-color-primitives-gray-39;
    cursor: pointer;
    display: block;
    float: left;
    line-height: 1.5;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    padding: 0.375rem 0.25rem 0.25rem !important;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include media-breakpoint-only(lg) {
      max-width: 112px !important;
    }
  }

  > .dropdown {
    cursor: pointer;
    float: right;
    height: 53px;
    position: static;

    &.open {
      background-color: $canopy-color-primitives-gray-94;

      .dropdown-menu {
        left: 0;
        margin-top: 0;
        min-width: 100%;
        padding: 0.25rem;
        right: 0;
        user-select: none;
      }
    }

    > a {
      display: block;
      height: 53px;
      padding: 0.5rem;

      .caret {
        color: $canopy-color-primitives-gray-39;
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}
