@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@import "app/javascript/stylesheets/variables/z-indexes.scss";
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.button {
  margin: 1rem $canopy-space-8x;
  transition-duration: 0.3s;
}
