@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.icon {
  color: $canopy-color-primitives-gray-39;
  font-size: 0.75rem;
  margin-right: 0.5rem;
}

.insurance {
  border-top: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
  color: $canopy-color-text-primary;
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: normal;
  padding: 0.625rem 1rem;
  margin: 0 -1rem;
}
