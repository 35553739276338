@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.memberColumn {
  & p {
    height: 32px;
    margin-bottom: 0;
  }
}

.memberNumber {
  font: $canopy-typography-body-xsmall-font;
  color: $canopy-color-text-secondary;
}
