@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "app/javascript/stylesheets/mixins/font-sizes.scss" as typography;

.signature-login-intro {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0 !important;
}

.legalese {
  margin: $canopy-space-24x 0;

  :only-child {
    @include typography.font-xxxs;
  }
}
