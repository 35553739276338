@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.active {
  color: $canopy-color-interactive-link-active;
}

.disabled {
  color: $canopy-color-primitives-gray-39;
  text-decoration: none;
  &:hover,
  &:focus {
    cursor: default;
    text-decoration: none;
  }
}
