@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.mainContainer {
  position: relative;
}

.cellText {
  text-overflow: ellipsis;
  overflow: hidden;
}
