@keyframes circular-rotate {
  0% {
    transform: rotate(0deg);

    /* Fix IE11 wobbly */
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveUp {
  0% {
    opacity: 0.7;
    transform: translateY(200px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes placeholder {
  0% {
    transform: translate3d(-50%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
