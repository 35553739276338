.w-auto {
  width: auto !important;
}

.w-90 {
  width: 90px !important;
}

.w-100 {
  width: 100px !important;
}

.w-125 {
  width: 125px !important;
}

.w-150 {
  width: 150px !important;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px !important;
}

.w-300 {
  width: 300px !important;
}

.w-350 {
  width: 350px !important;
}

.w-400 {
  width: 400px !important;
}

.w-500 {
  width: 500px !important;
}

.w-600 {
  width: 600px !important;
}

.w-60-p {
  width: 60% !important;
}

.w-80-p {
  width: 80% !important;
}

.w-100-p {
  width: 100% !important;
}

.h-100-p {
  height: 100% !important;
}

.h-60 {
  height: 60px !important;
}

.mw-200 {
  max-width: 200px !important;
}

.mw-300 {
  max-width: 300px !important;
}

.mh-300 {
  max-height: 300px !important;
  overflow: scroll;
}

.A4 {
  max-height: 1123px !important;
  width: 794px !important;
}

.max-vh-100 {
  max-height: 100vh !important;
}
