@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";
$navbar-height: 60px;

.workflow {
  @extend .row;
  padding-bottom: 1rem;

  #workflow-navigation {
    .workflow-left-nav-deprecated {
      @extend .col-lg-2;
      background-color: $canopy-color-background-primary;
      min-height: 0;
      z-index: $z-index-left-nav;

      @include media-breakpoint-down(md) {
        padding: 0;
      }

      @include media-breakpoint-up(lg) {
        height: 100%;
        overflow-y: auto;
        padding: 0 0 0 1rem;
        position: fixed;

        &::after {
          content: "";
          display: block;
          padding-bottom: $navbar-height * 1.5;
        }
      }
    }

    + .workflow-main-deprecated {
      @extend .col-lg-10;
      @extend .offset-lg-2;

      @include media-breakpoint-up(md) {
        padding: 0 24px;
      }
    }

    + .workflow-main-deprecated.add-sidebar-button-offset {
      @include media-breakpoint-up(md) {
        padding-right: 74px;
      }
    }

    .workflow-left-nav {
      background-color: $canopy-color-primitives-white-100;
      min-height: 0;
      z-index: $z-index-left-nav;

      @include media-breakpoint-down(md) {
        padding: 0;
      }

      @include media-breakpoint-up(lg) {
        width: 17%;
        overflow-y: auto;
        padding: 0;
        position: fixed;
        margin-top: -1.1rem;
        border-top: 0;
        border-right: 1px solid $canopy-color-primitives-gray-86;

        &::after {
          content: "";
          display: block;
          padding-bottom: $navbar-height * 1.5;
        }
      }

      @media (min-width: 1600px) {
        width: 13%;
      }
    }

    + .workflow-main {
      @extend .col-lg-10;
      @extend .offset-lg-2;

      @include media-breakpoint-up(md) {
        padding: 0 24px;
      }

      @include media-breakpoint-up(lg) {
        width: 83%;
        margin-left: 17%;
      }

      @media (min-width: 1600px) {
        width: 87%;
        margin-left: 13%;
      }
    }

    + .workflow-main.add-sidebar-button-offset {
      @include media-breakpoint-up(md) {
        padding-right: 74px;
      }
    }
  }

  .workflow-overlay {
    bottom: 0;
    height: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.3s ease;
    width: 0;
    z-index: $z-index-workflow-overlay;
  }

  .workflow-overlay.active {
    background: $canopy-color-primitives-gray-74;
    height: 100%;
    opacity: 0.3;
    width: 100%;
  }

  .workflow-overlay.active::before {
    animation: placeholder 1s ease infinite;
    background:
      linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 50%
      ) 50% 50%;
    content: " ";
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .workflow-full {
    @extend .col-12;
  }

  &:not(.document-viewer-open),
  &.right-sidebar-closed-deprecated {
    #document-viewer-body,
    .right-sidebar-body {
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
        height: 100%;
        position: relative;
        width: 100%;
      }
    }

    #document-viewer-buttons {
      display: block;
    }

    .document-actions,
    .document-files,
    #document_close_viewer {
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    .half-workflow {
      @extend .col-md-6;
    }
  }

  &.right-sidebar-closed {
    #document-viewer-body,
    .right-sidebar-body {
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
        height: 100%;
        position: relative;
        width: 100%;
      }
    }

    #workflow-navigation + .workflow-main {
      @include media-breakpoint-up(lg) {
        flex: 87%;
        max-width: 87%;
      }
    }

    .workflow-row {
      @extend .col-md-8;
    }

    #document-viewer-buttons {
      display: block;
    }

    .document-actions,
    .document-files,
    #document_close_viewer {
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    .half-workflow {
      @extend .col-md-6;
    }
  }

  &.document-viewer-open,
  &.right-sidebar-open {
    #workflow-navigation + .workflow-main {
      @extend .col-md-6;
      padding-right: 0;
      padding-left: 20px;

      .workflow-row {
        @extend .col-md-12;
      }

      @include media-breakpoint-only(md) {
        max-width: 50%;
        width: 50%;

        .product-grid {
          margin-left: 0;
          margin-right: 0;
        }
      }

      @include media-breakpoint-up(xl) {
        .filters {
          padding-right: 16px;
        }

        .product-grid {
          padding-left: 0;
        }
      }

      @media (min-width: 1600px) {
        flex: 53.5%;
        max-width: 53.5%;
      }

      @media (max-width: 1600px) {
        flex: 49.5%;
        max-width: 49.5%;
      }
    }

    #workflow-navigation + .wider-sidebar {
      @include media-breakpoint-up(md) {
        max-width: 800px;
      }

      @media (min-width: 1600px) {
        flex: 45%;
        max-width: 45%;
      }

      @media (max-width: 1600px) {
        flex: 40.5%;
        max-width: 40.5%;
      }

      @include media-breakpoint-only(md) {
        flex: 49.5%;
        max-width: 49.5%;
      }
    }

    #workflow-navigation + .workflow-main-deprecated {
      @extend .col-md-5;

      @include media-breakpoint-only(md) {
        max-width: 50%;
        width: 50%;
      }
    }

    .right-sidebar {
      @include media-breakpoint-only(md) {
        max-width: 50%;
        width: 50%;
      }
    }

    #workflow-navigation + .workflow-full {
      @extend .col-7;
    }

    #document-viewer-body,
    .right-sidebar-body {
      display: block;

      @include media-breakpoint-up(md) {
        margin-left: 20px;
      }
    }

    #document-viewer-body,
    .right-sidebar-body-deprecated {
      display: block;
    }

    #document-viewer-buttons {
      display: none;
    }

    .half-workflow {
      @extend .col-md-12;
    }
  }

  @include media-breakpoint-up(md) {
    .workflow-row {
      max-width: 600px;

      .well {
        max-width: 600px;
      }
    }
  }

  .workflow-banner {
    bottom: 1rem;
    position: relative;
  }

  .wide-fixed-row,
  .wide-fixed-row .form-group {
    @include media-breakpoint-up(md) {
      max-width: 800px !important;
    }

    .well,
    .well-wide {
      max-width: 800px;
    }
  }
}
