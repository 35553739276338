.tabs {
  @include clearfix;
  background: $canopy-color-interactive-background-secondary-default;

  .tab {
    color: $canopy-color-primitives-gray-39;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    padding: 0.5rem 0.75rem 0.75rem;

    > a {
      color: inherit;
      text-decoration: none;
      transition: none;
    }

    &:hover {
      color: $canopy-color-interactive-link-active;
    }

    &.selected {
      border-bottom: 4px solid $canopy-color-primitives-purple-24;
      color: $canopy-color-interactive-link-active;
      padding: 0.5rem 0.75rem;

      > a {
        color: inherit;
      }
    }

    &.disabled {
      color: $canopy-color-primitives-gray-74;
      cursor: not-allowed;
    }

    .fas {
      font-size: 0.75rem;
      position: relative;
      top: -1px;
    }
  }

  &.big {
    font-size: 1.5rem;

    .fas {
      font-size: 1.25rem;
      top: 0;
    }

    .badge {
      font-size: 0.6rem;
      height: 1.5rem;
      line-height: 1.5rem;
      margin-bottom: 0;
      min-width: 1.5rem;
      position: relative;
      top: -1px;
    }
  }
}
