.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-absolute-right {
  position: absolute !important;
  right: 0;
}

.position-fixed {
  position: fixed !important;
}

.v-align-top {
  vertical-align: top !important;
}

.v-align-middle {
  vertical-align: middle !important;
}

.v-align-bottom {
  vertical-align: bottom !important;
}

.v-align-text-bottom {
  vertical-align: text-bottom !important;
}

.h-50px {
  height: 50px;
  line-height: 50px;

  > span {
    display: inline-block;
    height: 49px;
    padding-top: 1px;
    vertical-align: middle;
  }
}
