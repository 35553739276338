@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.beta-badge {
  background-color: $canopy-color-primitives-teal-41;
  border-radius: 20px;
  color: $canopy-color-text-inverse;
  font-weight: bold;
  margin-left: 6px;
  padding: 4px 8px;
}

.new-flow-beta-badge {
  background-color: $canopy-color-primitives-teal-41;
  border-radius: 20px;
  color: $canopy-color-text-inverse;
  font-weight: bold;
  margin-left: 6px;
  padding: 4px 8px;
  font-size: 12px;
  font-family: Avenir, Helvetica, Verdana, sans-serif;
}

.indicator {
  @include canopy-typography-heading-medium;
  cursor: auto;
}

.success-indicator {
  color: $canopy-color-text-success;
}

.summary {
  overflow-y: scroll;
}

.warning-indicator {
  color: $canopy-color-primitives-yellow-53;
}

.see-more-or-less-button {
  color: $canopy-color-interactive-link-default !important;
  float: right;
  margin-top: 0;
  min-height: inherit !important;
  padding: 0 !important;
}

.markdown-container > :last-child {
  margin-bottom: 0.125rem;
}
