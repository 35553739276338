@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-top: $canopy-space-2x;
  margin-bottom: $canopy-space-2x;

  .rightHeader {
    display: flex;
    button {
      svg {
        fill: $canopy-color-text-primary !important;
      }
    }
  }
}

.mainContent {
  padding-bottom: $canopy-space-8x;
  border-bottom: 1px solid $canopy-color-primitives-gray-86;
}

.disabledLink {
  opacity: 0.5;
}

.internalNotesContainer {
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: 8px;
  background-color: $canopy-color-background-container;
}
