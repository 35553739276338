@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
.canopy-color-text-danger {
  color: $canopy-color-text-danger !important;
}
.canopy-color-background-primary {
  background-color: $canopy-color-background-primary;
}

.table-head {
  border-bottom: 1px solid $canopy-color-border-default;
  @include canopy-typography-body-medium;
  font-weight: $canopy-typography-font-weight-bold;
}

.sku-table {
  width: 100%;
  table-layout: fixed;
}

.quantity-column {
  width: 80px;
}

.action-column {
  width: 100px;
  text-align: right;
}

.item-column {
  width: auto;
}
