$opacity: 0.5;

.timeline-event-enter {
  overflow: hidden;
  opacity: 0;
  max-height: 0;
}

.timeline-event-enter-active {
  transition:
    opacity 300ms cubic-bezier(0, 0, 0.2, 1),
    max-height 300ms cubic-bezier(0, 0, 0.2, 1);
  max-height: 300px;
  opacity: 1;
}

.timeline-event-exit {
  max-height: 300px;
  opacity: 1;
}

.timeline-event-exit-active {
  transition:
    opacity 250ms cubic-bezier(0.4, 0, 1, 1),
    max-height 250ms cubic-bezier(0.4, 0, 1, 1);
  overflow: hidden;
  opacity: 0;
  max-height: 0;
}

.event-component {
  background-color: $canopy-color-background-secondary;
  padding: 0.5rem;

  .message-time {
    display: flex;
    flex-flow: column;
  }

  .message-timestamp {
    color: $canopy-color-primitives-gray-74;
    line-height: 23px;
  }

  .message-body {
    margin-top: 0.5rem;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    padding: 1rem;

    .message-text {
      white-space: pre-wrap;
    }
  }

  .message-badge {
    background: $canopy-color-primitives-magenta-40;
    border: none;
    border-radius: 0.75rem;
    color: $canopy-color-text-inverse;
    display: inline-block;
    font-size: 0.6rem;
    font-weight: bold;
    padding: 0.05rem 0.375rem;
    text-transform: uppercase;
    vertical-align: middle;
    align-self: center;
  }

  .message-badge-exit {
    opacity: 1;
  }

  .message-badge-exit-active {
    opacity: 0;
    transition: opacity 200ms cubic-bezier(0.4, 0, 1, 1);
  }

  .message-user-status {
    background: $canopy-color-brand-secondary;
    border-radius: 100%;
    display: inline-block;
    height: 5px;
    position: relative;
    top: -2px;
    width: 5px;
  }

  &.referral {
    .message-body {
      border-color: rgba($canopy-color-primitives-purple-24, $opacity);
      background-color: rgba($canopy-color-primitives-purple-98, $opacity);
    }
  }

  &.patient {
    .message-body {
      border-color: rgba($canopy-color-primitives-yellow-62, $opacity);
      background-color: rgba($canopy-color-background-warning, $opacity);
    }
  }

  &.supplier {
    .message-body {
      border-color: rgba($canopy-color-primitives-blue-41, $opacity);
      background-color: rgba($canopy-color-background-information, $opacity);
    }
  }

  &.insurance-payor {
    .message-body {
      border-color: $canopy-color-primitives-magenta-91;
      background-color: $canopy-color-primitives-magenta-98;
    }
  }

  &.parachute {
    .message-body {
      border-color: rgba($canopy-color-primitives-teal-41, $opacity);
      background-color: rgba($canopy-color-primitives-teal-97, $opacity);
    }

    img {
      top: 4px;
    }
  }

  &.internal {
    .message-body {
      border-color: $canopy-color-primitives-gray-74;
      background-color: $canopy-color-background-secondary;

      .message-notice {
        background-image:
          linear-gradient(
            135deg,
            $canopy-color-primitives-white-100 41.67%,
            $canopy-color-primitives-gray-94 41.67%,
            $canopy-color-primitives-gray-94 50%,
            $canopy-color-primitives-white-100 50%,
            $canopy-color-primitives-white-100 91.67%,
            $canopy-color-primitives-gray-94 91.67%,
            $canopy-color-primitives-gray-94 100%
          );
        background-size: 7px 7px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top: 1px solid $canopy-color-primitives-gray-74;
        padding: 0.5rem 1rem;
      }
    }
  }

  &.automated {
    .message-body {
      border-color: $canopy-color-primitives-gray-74;
      background-color: $canopy-color-primitives-gray-97;
    }
  }

  &.event {
    .message-body {
      border: none;
      font-size: 0.875rem;
      padding: 0;
    }
  }

  .outer-message-body {
    border: none;
    font-size: 0.875rem;
    padding: 0;
    margin-top: 0.5rem;
    white-space: pre-line;
  }
}
