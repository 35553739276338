@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.mainHeader {
  margin-top: $canopy-space-24x;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.cardContainer {
  margin-top: $canopy-space-24x;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }
  gap: $canopy-space-16x;
  padding: 0 $canopy-space-24x;
}

.card {
  background-color: $canopy-color-primitives-white-100;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid $canopy-color-primitives-gray-86;
  box-shadow: 0 2px 4px 0 rgba(34, 48, 54, 0.14);
  width: 100%;
  min-width: 338px;
  height: 289px;

  @include media-breakpoint-up(xl) {
    width: 338px;
    height: 289px;
  }

  h3 {
    margin-top: $canopy-space-12x;
    margin-bottom: 0;
  }

  p {
    margin-top: $canopy-space-8x;
    margin-bottom: 0;
  }

  .btn {
    margin-top: $canopy-space-12x;
    border-radius: 2px;
    border: 2px solid $canopy-color-brand-primary;
    color: $canopy-color-brand-primary;
  }

  .btn:hover {
    background-color: $canopy-color-brand-primary;
    color: $canopy-color-text-inverse;
  }
}

.icon {
  margin-top: $canopy-space-16x;
  height: 50px;
  width: 50px;
}

.background {
  background-image: url("../../../assets/images/sign_up/purple-rectangle.png");
  position: fixed;
  height: 50vh;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
}
