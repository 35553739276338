.stepper {
  .dot {
    background-color: $canopy-color-primitives-gray-86;
    border-radius: 50%;
    height: 8px;
    margin: 4px;
    width: 8px;

    &.active {
      background-color: $canopy-color-primitives-gray-16;
    }
  }
}
