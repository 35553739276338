@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.notification-history-table {
  width: 100%;
}

.notification-history-row {
  display: flex;
  flex-direction: row;
  padding: $spacer * 0.35;
}

.notification-history-date {
  margin-right: $spacer * 0.875;
  font-weight: bold;
}

.notification-history-method {
  color: $canopy-color-primitives-gray-39;
}
