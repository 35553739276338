select[disabled] {
  color: $canopy-color-primitives-gray-39;
}

.react-select__container {
  -ms-overflow-style: none;

  .react-select__control {
    border-color: $canopy-color-primitives-gray-86;
    box-shadow: none;
    cursor: pointer;
    line-height: 35px;
    min-height: 50px;
    overflow: hidden;

    &:hover {
      border-color: $canopy-color-primitives-gray-74;
    }
  }

  .react-select__placeholder {
    color: #808080;
    white-space: nowrap;
  }

  .react-select__control--is-focused {
    border-color: $canopy-color-brand-secondary;
    box-shadow: 0 0 8px lighten($canopy-color-primitives-teal-41, 25%);
  }

  .react-select__control--is-disabled {
    background-color: $canopy-color-interactive-background-disabled;
    border-color: $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
  }

  .react-select__menu {
    margin: 0;
    z-index: $z-index-select-menu;
  }

  .react-select__group {
    padding: 0;

    .react-select__group-heading {
      background-color: $canopy-color-primitives-gray-94;
      color: $canopy-color-text-primary;
      font-weight: normal;
      line-height: 1.2;
      margin: 0;
      padding: 9px 12px 8px;
    }

    img {
      vertical-align: sub;
    }
  }

  .react-select__menu-list {
    border-radius: 3px;
    padding: 0;
  }

  .react-select__option {
    background: $canopy-color-interactive-background-secondary-default;
    border-bottom: 1px solid $canopy-color-primitives-gray-86;
    color: $canopy-color-text-primary;
    cursor: pointer;
    padding: 0;

    &:last-child {
      border-bottom: 0;
    }
  }

  .react-select__option--is-focused {
    // scss-lint:disable ImportantRule
    background: $canopy-color-primitives-gray-97 !important;
  }

  .react-select__option--is-selected {
    // scss-lint:disable ImportantRule
    background: $canopy-color-primitives-gray-94 !important;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicators-container {
    // scss-lint:disable ImportantRule
    color: $canopy-color-text-primary !important;
  }

  .react-select__single-value {
    // scss-lint:disable ImportantRule
    color: $canopy-color-text-primary !important;

    &.react-select__single-value--is-disabled {
      // scss-lint:disable ImportantRule
      color: $canopy-color-primitives-gray-39 !important;
    }
  }

  .react-select__dropdown-indicator {
    padding-left: $field-padding-x;
    padding-right: $field-padding-x;
  }

  .react-select__multi-value {
    background: transparent;
    border: 1px solid $canopy-color-primitives-teal-41;
    border-radius: 2px;
    line-height: normal;
    padding: 3px;
  }

  .react-select__multi-value__remove {
    &:hover {
      background: $canopy-color-primitives-teal-41;
      color: $canopy-color-text-inverse;
    }
  }

  .react-select__value-container {
    max-height: 130px;
    overflow: auto;
    padding-left: $field-padding-x;
    padding-right: $field-padding-x;

    &.react-select__value-container--has-value {
      overflow-x: hidden;
    }
  }

  .react-select__menu--container--is-relative {
    display: block;
    position: relative;

    .react-select__menu {
      background: transparent;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      position: relative;
      top: 0;
    }

    .react-select__menu-list {
      max-height: 152px;
      overflow-y: auto;
    }

    .react-select__option {
      border: 0;
      border-radius: 0;
      padding: 0.25rem 0.75rem;
    }

    .react-select__option:first-child {
      margin-top: 0.5rem;
    }

    .react-select__option--is-focused,
    .react-select__option--is-selected {
      // scss-lint:disable ImportantRule
      background: transparent !important;
    }
  }
}

.react-select__container.small {
  font-size: 1em;
  width: 184px;

  .react-select__control {
    line-height: 24px;
    min-height: 40px;
    height: 40px;
  }

  .react-select__value-container {
    max-height: 40px;
  }
}

.react-select__container.autoexpand {
  width: 1000px;

  .react-select__value-container {
    width: 1000px;
    max-height: none;
    overflow: auto;
  }
}

.has-error {
  .react-select__container .react-select__control {
    border-color: $canopy-color-primitives-red-48;
  }

  .react-select__container .react-select__control--is-focused {
    box-shadow: 0 0 8px lighten($canopy-color-primitives-red-48, 25%);
  }
}
