.form-control {
  border-color: #ccc;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  height: 50px;
  margin-bottom: 10px;

  &.select:focus {
    border-color: $canopy-color-brand-secondary;
    box-shadow: 0 0 8px lighten($canopy-color-brand-secondary, 25%);
  }

  &::placeholder {
    color: #808080;
  }
}

.col-form-label {
  display: block;
  padding-bottom: 6px;
  padding-top: 0;
}

.sub-label {
  color: $canopy-color-text-secondary;
  padding-bottom: $canopy-space-8x;
  font-size: $canopy-typography-body-small-font-size;

  a {
    font-weight: $canopy-typography-font-weight-regular;
  }
}

.form-inline {
  .col-form-label {
    padding-bottom: 0;
  }
}

.has-feedback {
  position: relative;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  pointer-events: none;
}

input.form-control {
  border-radius: 3px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  transition-property: background-color, border-color;

  &[type="date"] {
    min-height: 0;
    padding: 6px 12px;
  }

  &:focus {
    border-color: $canopy-color-brand-secondary;
    box-shadow: 0 0 8px lighten($canopy-color-brand-secondary, 25%);
  }

  &.input-rounded {
    border: 1px solid $canopy-color-primitives-gray-86;
    border-radius: 50px;
    box-shadow: none;
    padding-left: 1rem;

    &.input-sm {
      border-radius: 40px;
      font-size: 0.875rem;

      + .form-control-feedback {
        font-size: 0.875rem;
        right: 0.25rem;
      }
    }

    &:focus {
      border-color: $canopy-color-brand-secondary;
      box-shadow: 0 0 8px lighten($canopy-color-brand-secondary, 25%);
    }

    &::placeholder {
      color: #808080;
    }
  }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: $canopy-color-primitives-gray-97;
  color: $canopy-color-primitives-gray-74;
}

.form-control.half {
  width: 50%;
}

.form-group {
  margin-bottom: 24px;

  @include media-breakpoint-up(md) {
    max-width: 600px;
  }

  input:last-child {
    margin-bottom: 0;
  }

  &.has-error {
    input.form-control,
    select.form-control,
    textarea.form-control {
      border-color: $canopy-color-primitives-red-48;
      box-shadow: none;

      &:focus {
        box-shadow: 0 0 8px lighten($canopy-color-primitives-red-48, 25%);
      }
    }

    .input-group {
      margin-bottom: 0.5rem;

      input.form-control {
        margin-bottom: 0;
      }
    }
  }
}

.form-group-container.full-width .form-group,
.modal .form-group {
  max-width: 100%;
}

.modal .form-group .input-group-numeric {
  max-width: 210px;
}

.help-block {
  margin-bottom: 5px;
}

.form-actions {
  margin-top: 20px;
}

form.form-inline {
  label.col-form-label {
    margin-right: 10px;
  }
}

.js-inline-form {
  display: none;
}

.is-under-input {
  margin-top: -10px;
}

.radio,
.checkbox {
  display: block;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  position: relative;
}

.btn-toolbar {
  margin-left: 0;
}

.btn-toolbar > .btn:first-child,
.btn-toolbar > .btn-group:first-child,
.btn-toolbar > .input-group:first-child {
  margin-left: 0;
}

.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-left: 5px;
}

.has-error {
  .form-control {
    border-color: $canopy-color-primitives-red-48 !important;

    &:focus {
      box-shadow: 0 0 8px lighten($canopy-color-primitives-red-48, 25%);
    }
  }
}
