.anchor {
  display: block;
  position: relative;
  top: -$header-height;
  visibility: hidden;
}

.clearfix {
  clear: both;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}
