@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.paper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.no-results {
    flex-direction: column;
  }
}

.icon {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.message {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: $spacer * 2;
  text-align: center;
}
