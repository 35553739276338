.icon {
  &.fa-sort-down {
    margin-top: 0;
  }

  &.fa-sort-up {
    margin-top: 0.375rem;
  }

  &.clickable {
    border-radius: 100%;
    cursor: pointer;
    height: 2.25rem;
    line-height: 2.25rem !important;
    text-align: center;
    transition: background 0.2s ease-in-out;
    width: 2.25rem;

    &:hover {
      background: $canopy-color-primitives-gray-94;
    }

    &:active {
      background: $canopy-color-primitives-gray-74;
    }

    &.form-control-feedback {
      margin: 0.5rem;
    }
  }

  &.disabled {
    color: $canopy-color-primitives-gray-86;
    cursor: not-allowed;

    &:hover,
    &:active {
      background: transparent;
    }
  }
}

.input-sm + .icon.clickable {
  height: 2rem;
  line-height: 2rem;
  width: 2rem;

  &.form-control-feedback {
    margin: 0.25rem;
  }
}
