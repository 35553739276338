@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.dataEntryLineContainer {
  display: flex;
  gap: $canopy-space-4x;
  flex-wrap: wrap;
}
.dataEntryLineContainer {
  & > * {
    flex: 1 1 48%;
  }
}

.divider {
  margin-bottom: 2.5rem;
  margin-top: 3rem;
}
