$message-avatar-width: 52px;

.message {
  .message-avatar {
    float: left;
    margin-top: 5px;
    width: $message-avatar-width;
  }

  .message-content {
    margin-bottom: 0.5rem;
    margin-left: $message-avatar-width;

    .message-name {
      @include font-xs;
      display: inline-block;
      margin-bottom: 0.5rem;
    }

    .message-bubble {
      background: $canopy-color-background-information;
      border-radius: 1rem;
      border-top-left-radius: 0;
      margin-bottom: 0.5rem;
      padding: 1rem 0.75rem;
    }

    .message-emphasized {
      color: $canopy-color-primitives-gray-39;
      margin-bottom: 0.5rem;
      padding: 0 0.75rem;
    }
  }

  .message-footer {
    @include clearfix;
    @include font-xxs;
    margin-left: $message-avatar-width;
    padding-top: 0.5rem;

    .message-timestamp {
      color: $canopy-color-primitives-gray-74;
      float: left;
      margin-bottom: 0.5rem;
      margin-left: 0.75rem;
    }
  }
}

.message + .message {
  margin-top: 1rem;
}
