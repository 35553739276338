@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.button-container {
  padding-left: $spacer * 0.5;
}

.group-label {
  color: $canopy-color-text-primary;
  font-weight: 800;
}
