@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.card {
  font-size: 14px;
}

.card-header {
  color: $canopy-color-text-primary;
  font-weight: 800;
  margin: 2px 0;
}

.card-details {
  color: $canopy-color-primitives-gray-39;
  margin: 2px 0;
}
