@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "app/javascript/stylesheets/variables/z-indexes.scss";
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.toolbar {
  background-color: $canopy-color-background-secondary;
  color: $canopy-color-primitives-gray-39;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  z-index: $z-index-toolbar;
}

.header-icon {
  margin-right: $spacer;
}

.header {
  display: flex;
  align-items: center;
  margin: 0 $spacer * 1.5 0 0;
}

.tab-button {
  align-items: center;
  color: $canopy-color-primitives-gray-39;
  display: flex;
  flex-direction: row;
  margin-left: $spacer * 0.5;
  padding: $spacer * 0.5 $spacer;
  border-radius: $spacer * 0.5;
  &:hover {
    background-color: $canopy-color-interactive-background-secondary-hover;
    text-decoration: none;
  }
}

.tab-button-active {
  background-color: $canopy-color-interactive-background-secondary-active;
  color: $canopy-color-interactive-link-default;
  text-decoration: none;
  &:hover {
    background-color: $canopy-color-interactive-background-secondary-selected;
    color: $canopy-color-interactive-link-active;
  }
  &:focus,
  &:active {
    color: $canopy-color-interactive-link-active;
    text-decoration: none;
  }
}

.provider-container {
  border-radius: 8px;
  max-height: 50px;
  color: $canopy-color-text-primary;
}
