@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@import "app/javascript/stylesheets/fonts.scss";

.MuiDataGrid-root {
  background-color: $canopy-color-interactive-background-secondary-default;

  &.borderless {
    border-width: 1px 0 0;
    border-color: $canopy-color-primitives-gray-86;
    border-style: solid;
    padding-top: $canopy-space-6x;
  }

  .MuiDataGrid-overlay {
    z-index: 999;
  }

  .MuiDataGrid-columnsContainer {
    background-color: $canopy-color-interactive-background-secondary-default;
  }

  .MuiDataGrid-footerContainer,
  .MuiDataGrid-columnHeader {
    background-color: $canopy-color-interactive-background-secondary-default;
  }

  .MuiDataGrid-toolbarContainer {
    background-color: $canopy-color-interactive-background-secondary-default;
    height: 35px;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-family: $font-body;
    font-weight: 900 !important;
  }

  .MuiDataGrid-row {
    font-family: $font-body;
    background-color: $canopy-color-interactive-background-secondary-default;
  }

  .MuiDataGrid-cell {
    padding: 16px !important;
    display: flex !important;
    align-items: center;
  }
}

// worth noting: from reading some documentation, these CSS overrides have potential to break
// if we ever upgrade MUI versions (v6 in particular)

.DataGrid-stickyHeader {
  .MuiDataGrid-columnsContainer {
    position: sticky;
    z-index: 1;
    top: 43px; // the sticky-ness of this element needs to sit under the toolbar as both elements stick
    // the 43px refers to the height of the stickyToolBar element
  }

  .MuiDataGrid-window {
    bottom: 39px !important;
    // adding sticky header to columns headers takes the height of the columns container
    // this height is then added as extra spacing at the bottom of the data grid window
    // this css override eliminates the extra white spacing and matches the height of the columns container
  }

  .MuiDataGrid-footerContainer {
    margin-top: -39px !important;
    // similarly to .MuiDataGrid-window, extra white spacing is added above the pagination toolbar
    // this css override eliminates the extra white spacing and matches the height of the footer container
  }

  .DataGrid-stickyToolbar-wrapper {
    background-color: $canopy-color-primitives-gray-86;
    // styling changes for sticky headers eliminates corners of the data grid box
    // which left unfinished border corners
    // this styling fixes the corners to create a completed edge
  }
}

.DataGrid-stickyToolbar > div:first-child {
  // due to how nested the elements appear in the MUI datagrid,
  // we are targeting the parent div (MuiDataGrid-root)
  // and selected the first child div (does not have a class name) of MuiDataGrid-root
  // if we add more divs in the future, this could potentially break
  position: sticky;
  background-color: $canopy-color-interactive-background-secondary-default;
  top: 0;
  z-index: 1;
  margin-top: 0;
}
