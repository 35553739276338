@import "node_modules/@parachutehealth/canopy-tokens-color/build/tokens.scss";
@import "node_modules/@parachutehealth/canopy-tokens-space/build/tokens.scss";

.canopy-background-color-gray-32 {
  background-color: $canopy-color-primitives-gray-32;
}

.canopy-border-color-default {
  border: $canopy-color-border-default 1px solid;
}

.rounded-borders {
  border-radius: $canopy-space-2x;
}

.fit-content {
  width: fit-content;
}
