@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.container {
  padding: $spacer * 3 0 $spacer * 1.5;
  display: flex;
  flex-direction: column;
}

.title-row {
  display: flex;
  align-items: center;
  padding-bottom: $spacer * 0.5;
}

.button-row {
  display: flex;
  align-items: center;
}

.view-name {
  flex: 1;
}

.title {
  margin: 0;
}

.close-button {
  flex: 0;
}
