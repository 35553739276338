.modal-body {
  height: 380px;
}

.filterHeading {
  font-size: 1rem;
  font-weight: bolder;
}

.service-areas {
  font-weight: normal;
  text-decoration: underline;
}
