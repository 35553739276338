@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.reviewer-sign-up-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  padding-top: $canopy-space-32x;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
  background-color: $canopy-color-background-container;
}

.container-padding {
  padding-right: $canopy-space-16x;
  padding-left: $canopy-space-32x;
}

.clinician-select-container {
  min-height: 475px;
  max-width: 600px;
}

.cant-find-clinician-link {
  font-size: $canopy-typography-body-small-font-size;
  margin-top: $canopy-space-12x;
  margin-bottom: $canopy-space-1x;
  text-align: center;
}

.flex-container {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: $canopy-space-40x;
}

.triptych-container {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  width: 204px;
  height: 119px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $canopy-space-4x;
  margin-bottom: $canopy-space-8x;
}
