$navbar-height: 56px;

.navbar {
  border-radius: 0;
  margin-bottom: 0;
  height: $navbar-height;

  .secondary-nav-button {
    background-color: $canopy-color-interactive-background-secondary-default;
    border-radius: 4px;
    color: rgba(61, 72, 76, 1);
    font-height: 12px;
    padding: 0;
    width: 48px;

    &:hover {
      background-color: $canopy-color-interactive-background-secondary-hover;
    }
  }
}

.navbar-default {
  background-color: $canopy-color-background-primary;
  border: none;
}

.navbar-fixed-top {
  background-color: $canopy-color-background-primary;
  border-bottom: 1px solid $canopy-color-primitives-gray-86;
  z-index: $z-index-navbar;

  .navbar-collapse {
    background-color: $canopy-color-background-primary;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .nav-mobile {
      .nav-item a {
        display: block;
        padding: 0.5rem 0;
      }
    }
  }
}

.has-mobile-nav.mobile-nav-open .navbar-fixed-top {
  z-index: -1;
}

.navbar-brand {
  font-size: 1rem;
  height: auto;

  .font-xs {
    line-height: 1;
    margin-top: -2px;
  }
}

.navbar-edit,
.navbar-edit:hover {
  color: $canopy-color-text-primary;
}

.navbar-main {
  li {
    margin-right: 0.5rem;
  }

  li:last-child {
    margin-right: 0;
  }
}
