.overlay-container {
  position: relative;

  &:not(.overlay-flex-height) {
    min-height: 250px;
  }

  &.standalone {
    position: initial;
  }

  .overlay-children {
    opacity: 0.3;
    transition: opacity 0.4s ease;
    transition-delay: 0.25s;
  }

  .spinner-overlay {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: $z-index-spinner-overlay;

    &.active {
      height: 100%;
      opacity: 1;
      transition: opacity 0.4s ease;
      transition-delay: 0.25s;
      width: 100%;
    }

    .spinner-icon {
      font-size: 64px;
      left: 50%;
      margin: -46px -32px;
      position: absolute;
      top: 50%;

      &.spinner-icon-top {
        top: 10rem;
      }
    }
  }

  &.overlay-inactive {
    > .spinner-overlay {
      display: none;
    }
  }
}
