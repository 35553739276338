@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.promo-card {
  background-color: $canopy-color-interactive-background-secondary-selected;
}

.promo-content-container {
  margin-bottom: $canopy-space-12x;
  display: grid;

  .promo-content-image {
    grid-column: 1;
    margin-right: $canopy-space-12x;
  }
  .promo-content-text {
    grid-column: 2;

    .promo-content-heading {
      @include canopy-typography-heading-small;
      margin-bottom: 0;
    }

    .promo-content-body {
      @include canopy-typography-body-small;
      margin-bottom: 0;
    }
  }
}
