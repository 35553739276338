.well {
  background-color: $canopy-color-background-container;
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: 4px;
  box-shadow: none;
  margin-bottom: 20px;
  min-height: 20px;
  padding: 1rem;
  position: relative;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &.well-sectioned {
    padding: 0;
  }

  &.well.well-shadow {
    box-shadow: 0 2px 4px 0 rgba($canopy-color-primitives-gray-16, 0.06);
  }

  &.well-success {
    background-color: $canopy-color-background-success;
    border-color: $canopy-color-primitives-teal-41;
    color: $canopy-color-text-primary;

    .btn-primary {
      background-color: #53b769;
    }
  }

  &.well-warning {
    background-color: $canopy-color-background-warning;
    border-color: $canopy-color-primitives-yellow-62;
    color: $canopy-color-text-primary;
  }

  &.well-danger {
    background-color: $canopy-color-background-danger;
    border-color: $canopy-color-primitives-red-97;
    color: $canopy-color-text-primary;
  }

  &.well-error {
    background-color: $canopy-color-primitives-gray-97;
    border-color: $canopy-color-primitives-red-48;
    color: $canopy-color-text-primary;
  }

  &.well-info {
    background-color: $canopy-color-background-information;
    border-color: $canopy-color-primitives-blue-41;
    color: $canopy-color-text-primary;
  }

  .well-section {
    border-bottom: 1px solid $canopy-color-primitives-gray-86;
    padding: 1rem;

    &:last-child {
      border-bottom: 0;
    }
  }

  .well-actions {
    position: absolute;
    right: 3rem;
    top: 1rem;

    @include media-breakpoint-down(sm) {
      margin-top: 0.5rem;
      position: relative;
      right: 0;
      top: 0;
    }
  }

  .well-dropdown {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &.well-tall {
    .well-dropdown {
      top: 1.5rem;
    }
  }

  &.well-borderless {
    border: 1px solid transparent;
    box-shadow: none;
  }
}

.well,
.well-wide {
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(md) {
    max-width: 600px;
  }
}

.well-expand {
  max-width: 100%;
  width: 100%;
}
