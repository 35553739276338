@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.hidden {
  visibility: hidden;
}

.profileWorkflowStepContainer {
  background-color: $canopy-color-background-container;
  max-width: 800px;
  min-height: 90vh;
  margin: 0 auto;
  text-align: center;
  border-radius: 16px;
  padding-bottom: 100px;
  padding-top: 50px;
}

.formContainer {
  padding: 0 100px;
}

.formField,
.inlineFields {
  margin-bottom: $canopy-space-6x;
}

.header {
  @include canopy-typography-heading-2xlarge;
  font-weight: $canopy-typography-font-weight-bold;
  margin-bottom: $canopy-space-4x;
}

.inlineFields {
  display: flex;
  gap: $canopy-space-4x;
}

.buttonContainer {
  display: flex;
  gap: $canopy-space-4x;
  justify-content: center;
  margin-top: $canopy-space-16x;
}

.inviteUsersInputsContainer {
  .inviteUsersInputContainer {
    &:not(:last-child) {
      border-bottom: 1px solid $canopy-color-border-default;
    }
  }
}

.removeUserInput {
  position: absolute;
  right: -40px;
  top: 40px;
}

.inviteUserInputRow {
  position: relative;
  margin: $canopy-space-16x 0;
}

.inviteUserInputField {
  flex: 1;
}

.addAnotherContainer {
  display: flex;
  padding-bottom: $canopy-space-12x;
}

.inviteUsersButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: $canopy-space-8x;
}

.inviteUsersNotice {
  text-align: left;
  white-space: pre-line;
  margin-top: $canopy-space-32x;
}

.progressContainer {
  width: 25%;
  height: 10px;
  background-color: $canopy-color-primitives-purple-94;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto $canopy-space-16x;
}

.progressBar {
  height: 100%;
  background:
    linear-gradient(
      90deg,
      $canopy-color-primitives-purple-63 0%,
      $canopy-color-primitives-purple-24 100%
    );
  transition: width 0.3s ease-in-out;
  border-radius: 5px;
}

.thankyouBodyContainer {
  margin-top: 30vh;
}

.thankyouBackToDashboardContainer {
  margin-top: $canopy-space-16x;
}

.tableWrapper {

  thead tr th {
    background-color: white !important;

    &:first-child {
      border-left: 1px solid #d7dddf; // MUI table border color
    }

    &:last-child {
      border-right: 1px solid #d7dddf; // MUI table border color
    }
  }

  tbody tr td {
    background-color: white !important;

    &:first-child {
      border-left: 1px solid #d7dddf; // MUI table border color
    }

    &:last-child {
      border-right: 1px solid #d7dddf; // MUI table border color
    }
  }

  tbody tr:nth-child(even) td {
    background-color: white !important;
  }
}

.toggleButton {
  color: $canopy-color-brand-primary;
  cursor: pointer;
  margin-bottom: $canopy-space-8x;
  font-weight: 500;
  display: inline-flex;
  align-items: center;

  .icon {
    vertical-align: middle;
    font-size: 0.8em;
    margin-left: $canopy-space-4x;
  }
}
