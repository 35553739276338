.timeline {
  display: flex;

  .timeline-container {
    flex: 0 0 40px;

    .timeline-icon {
      border-radius: 12px;
      border-style: solid;
      border-width: 1px;
      height: 24px;
      width: 24px;

      span {
        font-size: 0.75rem;
        position: relative;
      }
    }
  }

  &.event,
  &.supplier-automated-message,
  &.automated {
    .timeline-icon {
      background-color: $canopy-color-primitives-gray-16;
      border: 0;
      height: 5px;
      margin-left: 10px;
      margin-top: 9px;
      width: 5px;
    }
  }

  &.parachute {
    .timeline-icon {
      border-color: $canopy-color-brand-secondary;

      span {
        background-image: url("../../assets/images/logo.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 14px 14px;
        display: inline-block;
        height: 14px;
        width: 14px;
      }
    }

    .event-component.parachute img {
      display: none;
    }
  }

  &.supplier {
    .timeline-icon {
      border-color: $canopy-color-primitives-blue-41;

      span {
        color: $canopy-color-primitives-blue-41;
        top: -1px;
      }
    }
  }

  &.insurance-payor {
    .timeline-icon {
      border-color: $canopy-color-primitives-magenta-40;

      span {
        color: $canopy-color-primitives-magenta-40;
        top: -1px;
      }
    }
  }

  &.patient {
    .timeline-icon {
      border-color: $canopy-color-primitives-yellow-62;

      span {
        color: $canopy-color-primitives-yellow-62;
        top: -1px;
      }
    }
  }

  &.internal {
    .timeline-icon {
      border-color: $canopy-color-primitives-gray-74;

      span {
        color: $canopy-color-primitives-gray-39;
        top: -1px;
      }
    }
  }

  &.referral {
    .timeline-icon {
      border: 1px solid $canopy-color-primitives-purple-24;

      span {
        color: $canopy-color-primitives-purple-24;
        top: -1px;
      }
    }
  }

  .timeline-content {
    flex: 1;
    margin-top: -1px;

    > div {
      padding-bottom: 31px;
      padding-left: 0;
      padding-top: 0;
    }
  }

  &:not(:last-child) {
    .timeline-line {
      border-left: 1px solid $canopy-color-primitives-gray-86;
      height: calc(100% - 24px - 7px);
      left: 12px;
      position: relative;
      top: 8px;
      width: 1px;
    }

    &.event {
      .timeline-line {
        height: calc(100% - 5px - 7px);
      }
    }

    &.internal {
      .timeline-line {
        border-left-style: dashed;
      }
    }
  }
}

.timeline-event-list {
  padding-bottom: 40px;
}
