@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@import "app/javascript/stylesheets/mixins/font-sizes.scss";
@import "app/javascript/stylesheets/mixins/shadows.scss";

.card {
  @include shadow-primary;
  background: $canopy-color-background-secondary;
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 190px;
  padding: 1rem 1rem 0;

  &:hover {
    background: $canopy-color-interactive-background-secondary-hover;
    cursor: pointer;
    text-decoration: none;
  }
}

.compact-card {
  height: 75px;
}

.overline {
  margin-bottom: 8px;
  color: $canopy-color-primitives-gray-74;
}

.title {
  @include font-l;
  color: $canopy-color-text-primary;
  font-weight: 500;
}
