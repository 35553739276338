@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.coverage {
  color: $canopy-color-text-primary;
  font-size: 75%;
  margin-left: 1.5rem;
}

.subhead {
  font-weight: 500;
  color: $canopy-color-primitives-gray-39;
}
