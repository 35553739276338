.sticky-active {
  .sticky-inner-wrapper {
    overflow: initial;
    z-index: 1;

    thead {
      box-shadow: 0 3px 9px rgba($canopy-color-primitives-gray-74, 0.5);
    }
  }
}

.table-flex {
  .sticky-outer-wrapper:not(.sticky-active) {
    thead {
      transform: unset !important;
    }
  }
}
