@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.nav {
  background-color: $canopy-color-background-secondary;
  border-bottom: 1px solid $canopy-color-primitives-gray-86;
}

.center {
  top: 1rem;
}
