@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.checkbox {
  margin-inline-start: 0.2rem;
  margin-block-end: 1rem;
  max-width: 35rem;

  label {
    background: $canopy-color-background-primary;
  }
}
