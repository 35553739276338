@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.edit-link-container {
  @include media-breakpoint-up(md) {
    text-align: right;
  }
}

.add-button-container {
  align-items: center;
  margin-left: 0;
  @include media-breakpoint-up(md) {
    padding-left: 0;
  }
}

.email-field-container {
  margin-right: 0;
  @include media-breakpoint-up(md) {
    padding-right: 0;
  }
}

.add-button {
  @include media-breakpoint-up(md) {
    margin-top: 30px;
  }
}
