@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.header {
  margin-bottom: $canopy-space-2x;
  margin-left: $canopy-space-2x;
}

.actions {
  margin-top: $canopy-space-2x;
  margin-bottom: $canopy-space-10x;
  display: flex;
  justify-content: right;
}

.packageSelect {
  margin-left: $canopy-space-4x;
  width: 300px;
  z-index: 1000;
}

.subheading {
  font-size: $canopy-typography-body-small-font-size;
  width: $canopy-space-32x;
  display: inline-block;
}

.link {
  display: inline-flex;
  align-items: center;
  font-size: $canopy-typography-body-xsmall-font-size;
}

.backBtn {
  margin-bottom: 3px; // 3px for fine-tuned alignment
  margin-right: $canopy-space-2x;
}

.allClosets {
  font: $canopy-typography-body-xsmall-font;
  font-weight: $canopy-typography-font-weight-bold;
}

.duplicateButton {
  a {
    padding: 0 !important;
  }
}
