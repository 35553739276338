@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.container {
  height: 100%;
  width: 640px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.message {
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: $spacer * 2;
  text-align: center;
}
