@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.pagination {
  @include media-breakpoint-down(sm) {
    display: block;
    text-align: center;
  }
}
