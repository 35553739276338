.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  outline: 0;
  z-index: $z-index-modal;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  margin: 10px;
  outline: 0;
  position: relative;
  width: auto;

  @include media-breakpoint-up(md) {
    &.vertical-center {
      left: 50%;
      margin-top: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) !important;
    }
  }
}

@include media-breakpoint-up(sm) {
  .modal-dialog {
    margin: 2% auto 30px;
    width: 600px;
  }
  .modal-sm {
    width: 300px;
  }
}

@include media-breakpoint-up(md) {
  .modal-xl {
    width: 664px;
  }
}

@include media-breakpoint-up(lg) {
  .modal-lg {
    width: 900px;
  }
}

@media (min-width: 1300px) {
  .modal-xl {
    width: 1260px;
  }
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-modal-backdrop;
}

.modal-backdrop,
.modal-backdrop.in {
  background: rgba($canopy-color-primitives-gray-16, 0.6);
  opacity: 1;
  z-index: $z-index-modal-backdrop;

  + .modal {
    z-index: $z-index-modal;
  }
}

.modal-open .modal.ReactModal__Overlay {
  display: block;
}

.modal-content {
  background-color: $canopy-color-background-secondary;
  border: 0;
  border-radius: 0.5rem 0.5rem 1rem 1rem;
  box-shadow: 0 1rem 1rem 0 rgba($canopy-color-primitives-gray-16, 0.16);
  position: relative;
  outline: 0;
}

.modal-show-backdrop {
  @extend .modal-backdrop;
  opacity: 1;
}

.modal-body {
  &.vertical-scroll {
    max-height: 500px;
    overflow-y: scroll;
  }
}

.modal-header,
.modal-body {
  padding: 1.5rem;
}

.modal-footer {
  border-top: 1px solid $canopy-color-primitives-gray-86;
  text-align: right;
  padding: 1rem 1.5rem;
}

.modal-banner-width {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.modal-header {
  background: $canopy-color-background-primary;
  border: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: inset 0 -1px 2px 0 rgba($canopy-color-primitives-gray-16, 0.16);

  .close {
    margin-top: -2px;
    outline: none;
  }

  &.has-tabs {
    padding-bottom: 1px;

    .modal-title {
      margin-bottom: 1rem;
    }

    .tabs {
      background-color: inherit;
    }
  }
}

.modal-title {
  margin: 0;
  line-height: 1.4286;
}

.modal-content {
  border-radius: 8px;
}
