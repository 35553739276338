@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.flex-container {
  display: flex;
}

.content {
  width: 100%;
  padding: $spacer * 1.5;
}

.card {
  height: 100%;
}

.patient-name-column {
  flex-grow: 1;
}

.view-button-column {
  flex-grow: 0;
}

.date-of-birth {
  padding-bottom: $spacer * 0.5;
}
