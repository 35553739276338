@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.fileUploadMenuItem {
  color: $canopy-color-text-primary;
  font-weight: 400;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}
