@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.backToDashboardContainer {
  @include canopy-typography-body-small;
  display: flex;
  margin: 0 $canopy-space-16x;
  margin-bottom: $canopy-space-8x;
}

.backToDashboardArrow {
  margin-top: 1px;
}

.backToDashboardLink {
  margin-left: $canopy-space-2x;
  color: $canopy-color-text-primary;
  font-weight: bold;
}
