@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.summary-row {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    margin-bottom: -8px;
  }
}
