@import "node_modules/@parachutehealth/canopy-tokens-color/build/tokens";
@import "node_modules/@parachutehealth/canopy-tokens-space/build/tokens";

.productHeader {
  color: $canopy-color-text-secondary;
}

.lineItemContainer:not(:last-child) {
  margin-bottom: $canopy-space-20x;
}

.form {
  background-color: $canopy-color-background-secondary;
  border-radius: $canopy-space-4x;
  border: 1px solid $canopy-color-primitives-gray-86;
  margin-bottom: $canopy-space-12x;

  .formFieldGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $canopy-color-primitives-gray-86;
    padding: $canopy-space-8x;
    margin: 0;
  }

  .summary {
    background-color: $canopy-color-primitives-blue-94;
    // account for border radius of containing element
    border-radius: 0 0 $canopy-space-4x $canopy-space-4x;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $canopy-space-12x $canopy-space-8x;
    margin: -1px 0 0; // overlap the bottom item border to hide it
  }

  // CanopyFormFieldGroup adds a bottom margin which looks silly
  // when we lay the form out horizontally; override it for this instance
  // by targeting the wrapping div via this monstrosity
  div:has(> label[id^="canopy-form-field"]) {
    margin-bottom: 0 !important;
  }
}
