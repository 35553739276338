@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.button {
  background-color: $canopy-color-brand-secondary !important;

  &:active,
  &:hover {
    background-color: $canopy-color-primitives-teal-26 !important;
  }
}
