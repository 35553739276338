.text-editor {
  height: auto;
  min-height: 5em;
  .text-editor__input {
    border: 0;
    max-width: 100%;
    outline: none;
    padding: 0.5rem 1rem;
    resize: vertical;

    &::placeholder {
      color: #808080;
    }

    &:disabled {
      background-color: $canopy-color-primitives-gray-97 !important;
    }
  }

  .text-editor__suggestions {
    border: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba($canopy-color-primitives-gray-16, 0.175);
    width: calc(100% + 20px);
    margin-left: -15px !important;
    margin-top: 35px !important;
  }

  .text-editor__suggestions__list {
    border: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba($canopy-color-primitives-gray-16, 0.175);
    max-height: 600px;
    overflow-y: auto;
  }

  .text-editor__suggestions__item {
    background-color: $canopy-color-background-container;

    .text-editor-group {
      color: $canopy-color-primitives-gray-74;
      font-size: 14px;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
    }

    .text-editor-suggestion {
      padding: 1rem;
      border-bottom: 1px solid $canopy-color-primitives-gray-86;
    }

    &:last-child .text-editor-suggestion {
      border-bottom: none;
    }
  }

  .text-editor__suggestions__item--focused .text-editor-suggestion {
    background-color: $canopy-color-primitives-gray-97;
  }

  .text-editor-item {
    background-color: $canopy-color-interactive-background-secondary-default;
  }
}

.has-alert {
  .text-editor__input {
    padding: 1rem;
  }
}
