@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.patient-card-container {
  background: $canopy-color-background-secondary;
  border-radius: 4px;
  border: 1px solid $canopy-color-primitives-gray-86;
  box-shadow: 0 2px 4px rgba(34, 48, 54, 0.14);
}

.patient-card-inner-container {
  margin: 16px;
}
