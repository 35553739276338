@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.container {
  width: 100%;
  padding: $spacer * 2;
}

.action-bar {
  margin-top: $spacer * 0.5;
  margin-bottom: $spacer * 1.5;
}

.search-field {
  margin-right: 23px;
}

.export-button {
  height: 40px;
  line-height: 0;
}
