@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.container {
  border-radius: 12px;
  background-color: $canopy-color-background-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $canopy-space-16x;
  flex-direction: column;
  z-index: 1; /* make sure the interaction is accessible above the other DataGrid layers */
  width: 100%;
}

/* make Canopy's tertiary button look/behave like a MUI text variant button */
.textButton {
  background-color: transparent !important;
  border: 1px solid transparent;

  &:hover {
    border-color: inherit;
  }
}
