@use "@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "@parachutehealth/canopy-tokens-space/build/tokens" as *;

.separator {
  margin: $canopy-space-10x 0;
  overflow: hidden;
  position: relative;
  text-align: center;

  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: inline-block;
    padding: 0 0.75rem;
    position: relative;

    &::before,
    &::after {
      border-top: 1px solid $canopy-color-primitives-gray-74;
      content: "";
      display: block;
      min-width: 100px;
      position: absolute;
      top: 0.55em;
      width: 100%;
    }

    &::before {
      right: 100%;
    }

    &::after {
      left: 100%;
    }
  }
}
