.cards {
  padding-left: 15px;
  padding-right: 15px;
}

.card {
  @include shadow-primary;
  background: $canopy-color-background-secondary;
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: 4px;
  color: $canopy-color-text-primary;
  display: block;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  text-decoration: none;
  transition: background-color 0.2s;

  &.row {
    display: flex;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  .arrow {
    border: solid $canopy-color-primitives-gray-16;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;

    &.top {
      transform: rotate(-135deg);
    }

    &.right {
      transform: rotate(-45deg);
    }

    &.bottom {
      transform: rotate(45deg);
    }

    &.left {
      transform: rotate(135deg);
    }
  }

  .card-status {
    text-align: right;

    @include media-breakpoint-down(md) {
      border-bottom: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
      margin-left: 15px;
      margin-right: 15px;
      padding-left: 0;
      padding-right: 0;
      text-align: left;

      > :first-child {
        margin-right: 5px;
      }
    }

    @include media-breakpoint-only(md) {
      display: flex;
      flex-direction: row;

      .pill {
        margin-top: 6px;
      }

      .font-xs {
        margin-left: 5px;
        margin-top: 10px;
      }
    }

    @include media-breakpoint-down(sm) {
      > .pill {
        display: inline-block;
      }

      > div {
        display: block;
      }
    }
  }

  .card-content {
    .card-notes {
      border-right: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
      min-height: 8rem;

      @include media-breakpoint-down(md) {
        border-right: 0;
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 0;
        padding-right: 0;
      }

      @include media-breakpoint-down(md) {
        border-bottom: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
      }

      .card-note {
        margin-bottom: 0.5rem;

        .title {
          @include font-xs;
          color: $canopy-color-primitives-gray-39;
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .card-body {
      @include media-breakpoint-down(md) {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
      }
    }

    ul {
      margin-left: 0;
      padding-left: 20px;
    }
  }

  .card-action {
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
      margin-left: auto;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
  }

  .card-action-right {
    @include media-breakpoint-only(md) {
      margin-top: 4px;
    }

    @include media-breakpoint-down(sm) {
      display: block;
      position: absolute;
      right: 0;
      top: 14px;
    }
  }

  .card-dropdown {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;

    + h3 {
      padding: 0.125rem 0;
    }

    + h4,
    + h5 {
      padding: 0.25rem 0;
    }

    + h6 {
      padding: 0.375rem 0;
    }
  }

  &.card-inactive {
    background-color: $canopy-color-primitives-gray-97;
    color: $canopy-color-primitives-gray-39;

    &:hover {
      background: $canopy-color-interactive-background-disabled;
    }
  }

  &.card-default {
    border-left: 5px solid $canopy-color-primitives-gray-86;

    &.card-border-top {
      border-left: 0;
      border-top: 5px solid $canopy-color-primitives-gray-86;
    }
  }

  &.card-success {
    border-left: 5px solid $canopy-color-primitives-teal-41;

    &.card-border-top {
      border-left: 0;
      border-top: 5px solid $canopy-color-primitives-teal-41;
    }
  }

  &.card-warning {
    border-left: 5px solid $canopy-color-primitives-yellow-62;

    &.card-border-top {
      border-left: 0;
      border-top: 5px solid $canopy-color-primitives-yellow-62;
    }
  }

  &.card-danger {
    border-left: 5px solid $canopy-color-primitives-red-48;

    &.card-border-top {
      border-left: 0;
      border-top: 5px solid $canopy-color-primitives-red-48;
    }
  }

  &.card-info {
    border-left: 5px solid $canopy-color-primitives-blue-41;

    &.card-border-top {
      border-left: 0;
      border-top: 5px solid $canopy-color-primitives-blue-41;
    }
  }

  &.card-accent {
    border-left: 5px solid $canopy-color-primitives-magenta-40;

    &.card-border-top {
      border-left: 0;
      border-top: 5px solid $canopy-color-primitives-magenta-40;
    }
  }

  &.card-borderless {
    border: 1px solid transparent;
    box-shadow: none;
  }

  &.card-shadow {
    @include shadow-primary;
  }

  .card-badge {
    background-color: $canopy-color-interactive-background-primary-default;
    border-radius: 100%;
    color: $canopy-color-text-inverse;
    font-size: 0.75rem;
    font-weight: bold;
    height: 2rem;
    line-height: 2rem;
    position: absolute;
    right: -1rem;
    text-align: center;
    top: -1rem;
    width: 2rem;
  }
}

a.card {
  cursor: pointer;

  &:hover {
    @include shadow-secondary;
    background: $canopy-color-interactive-background-secondary-hover;
    color: $canopy-color-text-primary;
    text-decoration: none;
  }
}

.card:last-child {
  margin-bottom: 0;
}
