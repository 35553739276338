@import "node_modules/@parachutehealth/canopy-tokens-color/build/tokens.scss";
@import "node_modules/@parachutehealth/canopy-tokens-space/build/tokens.scss";
@import "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens.scss";

.header {
  @include canopy-typography-heading-xlarge;
  color: $canopy-color-text-primary;
  margin-bottom: $canopy-space-1x;
}
.subheader {
  @include canopy-typography-body-medium;
  color: $canopy-color-text-secondary;
  margin-bottom: 3rem;
}

.subSectionHeader {
  @include canopy-typography-heading-large;
  margin-bottom: 1rem;
  margin-top: 3rem;
}
