@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.loading-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;

  &__icon {
    font-size: 64px;
    margin: auto;
    opacity: 1;
    transition: opacity 0.4s ease;
    transition-delay: 0.25s;
  }
}
