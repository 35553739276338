@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
$icon-check: "\f00c";

.onboarding-container {
  margin: 0 auto;
  max-width: 600px;
  padding: 0 0.5rem;
  text-align: center;

  .onboarding-contents > * {
    margin-bottom: 3rem;
  }

  .onboarding-intro > * {
    margin-bottom: 1rem;
  }

  .onboarding-instructions {
    padding: $canopy-space-16x $canopy-space-20x;
    text-align: left;
  }
}
