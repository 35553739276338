@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.tabs {
  background-color: transparent;
  margin-bottom: $spacer * 1.5;
}

.order-header {
  margin-bottom: $spacer * 1.5;
}
