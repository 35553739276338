@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/mixins.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.category-filter {
  background-color: $canopy-color-interactive-background-secondary-default;
  border: solid #eff2f3 1px;
  max-height: 1434px;
  overflow-y: auto;

  .select-one .select-one-option a {
    white-space: normal;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 8px;
  }
}

.search-by-supplier {
  max-height: 1050px;
}

.combined-search {
  max-height: 1098px;
}

.consignment-and-formulary-filter {
  max-height: 1103px;
}

.combined-search.consignment-and-formulary-filter {
  max-height: 1172px;
}

.search-by-product.consignment-and-formulary-filter {
  max-height: 1494px;
}

@include media-breakpoint-up(lg) {
  .product-filter-title:first-child {
    margin-top: 10px;
  }
}

.your-organizations-suppliers-filter {
  padding: 8px;
}

.your-organizations-suppliers-label {
  margin: 0;
  font-weight: normal;
}

.your-organizations-suppliers-toggle-row {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
}

.switchLabel {
  font-size: 0.75rem;
  font-weight: bolder;
  color: $canopy-color-text-inverse;
  position: absolute;
  bottom: 3px;
}

.on {
  margin-left: 10px;
}

.off {
  margin-left: 4px;
}

.filterHeading {
  font-size: 1rem;
  font-weight: bolder;
}
