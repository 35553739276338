.gutter-20.row {
  margin-left: -10px;
  margin-right: -10px;
}

.gutter-20 > [class^="col-"],
.gutter-20 > [class^=" col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.gutter-10.row {
  margin-left: -5px;
  margin-right: -5px;
}

.gutter-10 > [class^="col-"],
.gutter-10 > [class^=" col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.gutter-0.row {
  margin-left: 0;
  margin-right: 0;
}

.gutter-0 > [class^="col-"],
.gutter-0 > [class^=" col-"] {
  padding-left: 0;
  padding-right: 0;
}

.gutter-right-0 > [class^="col-"],
.gutter-right-0 > [class^=" col-"] {
  padding-right: 0;
}

.full-width {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}
