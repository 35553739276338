$navbar-height-including-border: 57px;

html,
body {
  font-family: $font-body;
  font-size: $font-size-base;
  height: 100%;
}

body {
  background-color: $canopy-color-background-primary;
  color: $canopy-color-text-primary;
  -webkit-font-smoothing: antialiased;
  line-height: 1.45;
  margin: 0;
  -ms-overflow-style: scrollbar;
  padding-top: $navbar-height-including-border;
}

h4 {
  font-weight: bold;
}

.page {
  margin-bottom: -50px;
  min-height: 100%;
  padding-top: 1rem;
}

footer,
.push {
  height: 50px;
}

img {
  vertical-align: baseline;
}

label {
  font-weight: bold;
}

legend {
  border-bottom: 0;
}

a {
  color: $canopy-color-primitives-gray-39;
  cursor: pointer;
  transition: color 0.2s ease-in-out, opacity 0.15s ease-in-out;

  &:hover,
  &:focus {
    color: $canopy-color-primitives-gray-39;
    cursor: pointer;
    text-decoration: underline;
  }
}

svg {
  vertical-align: baseline;
}

select {
  &:focus {
    outline: none;
  }
}

// bust cache
.noop.v1 {
  display: none;
}

.mark {
  background-color: $canopy-color-primitives-yellow-92;
}

.ie-not-supported-message-wrap {
  background-color: $canopy-color-background-secondary;
  border: solid 1px $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 960px;
  min-height: 400px;
  text-align: center;
  width: 100%;

  .ie-not-supported-message-header {
    margin: 0 0 $canopy-space-8x;
    padding: 0;
    text-align: center;
  }

  .ie-not-supported-message-wrap__inner {
    max-width: 600px;
    padding-top: 80px;
    width: 100%;
  }

  .ie-not-supported-message-body {
    background-color: $canopy-color-primitives-yellow-92;
    border: solid 1px $canopy-color-primitives-yellow-72;
    border-radius: 4px;
    padding: $canopy-space-8x;
    margin: 0 $canopy-space-24x;

    a {
      color: $canopy-color-interactive-link-default;
      font-weight: $canopy-typography-font-weight-bold;
    }
  }
}
