@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.supplierIntakeFacilityData {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  inline-size: 100%;
  justify-content: space-between;
  padding-block: $canopy-space-6x;
  padding-inline: $canopy-space-10x;
}
