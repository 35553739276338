$nav-mobile-height: 60px;
$nav-mobile-line-height: 60px;

body.mobile-nav-open {
  overflow-y: hidden;
}

.nav.nav-pills > li {
  &.active {
    > a {
      background-color: $canopy-color-interactive-background-primary-active;
    }
  }

  &:not(.active) {
    > a {
      color: $canopy-color-interactive-link-default;
    }
  }
}
