@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

// We are overriding a couple of properties from the
// styling of .tabs as defined in the styleguide. Future
// work for updating .tabs in the styleguide should make this
// override unnecessary.
.tabs {
  border-bottom: 2px solid $canopy-color-primitives-gray-86;
  background: transparent;
}
