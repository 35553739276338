$shadow-color: rgba(0, 0, 0, 0.17);

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 25%);
    box-shadow: none;
  }
  &:hover {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
    box-shadow: none;
  }
  &:active,
  &.active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
      box-shadow: none;
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

@mixin button-variant-mixin($color, $background-color, $border-color) {
  border: 2px solid $border-color;
  @include button-variant($color, $background-color, $border-color);

  &[disabled],
  &[disabled]:hover,
  .disabled,
  .disabled:hover {
    background-color: $canopy-color-interactive-background-disabled;
    border: 2px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
    color: $canopy-color-primitives-gray-74;
    cursor: not-allowed;
    opacity: 1;
  }
}

@mixin button-variant-mixin-o($color, $background-color, $border-color) {
  border: 2px solid $border-color;
  @include button-variant($color, $background-color, $border-color);

  &:hover:not([disabled]) {
    background-color: darken($border-color, 5%);
    border-color: darken($border-color, 5%);
    color: $canopy-color-text-inverse;
  }

  &[disabled],
  &[disabled]:hover,
  .disabled,
  .disabled:hover {
    background-color: $canopy-color-interactive-background-disabled;
    border: 2px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
    color: $canopy-color-primitives-gray-74;
    cursor: not-allowed;
    opacity: 1;
  }
}

.btn {
  border: 0;
  border-radius: 3px;
  color: $canopy-color-text-inverse;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  height: 50px;
  line-height: 48px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  transition: all 0.2s ease-in-out;
  transition-property: background-color, border-color, color, opacity;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: $canopy-color-text-inverse;
    text-decoration: none;
  }

  &.disabled {
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.65;
  }
}

.btn-dashed {
  color: $canopy-color-text-primary;
  background-color: transparent;
  border-color: $canopy-color-primitives-gray-86;
  border-style: dashed;
  border-width: 2px;

  &:hover,
  &:focus,
  &:active {
    color: $canopy-color-text-primary;
    background-color: $canopy-color-primitives-gray-94;
    border-color: $canopy-color-primitives-gray-86;

    &:active {
      color: $canopy-color-primitives-gray-39;
    }
  }
}

a,
.btn {
  &.is-inactive {
    .fas {
      display: none;
    }
  }

  &.is-downloading {
    .fas {
      display: inline-block;
    }
  }
}

.btn-block {
  display: block;
  padding: 0;
  width: 100%;

  + .btn-block {
    margin-top: 5px;
  }
}

.btn-xs-block {
  @include media-breakpoint-down(sm) {
    display: block !important;
    padding: 0;
    width: 100%;
  }
}

.btn-action {
  margin-top: 24px;
}

.btn-primary {
  @include button-variant-mixin(
    $canopy-color-text-inverse,
    $canopy-color-brand-secondary,
    $canopy-color-brand-secondary
  );
}

.btn-secondary {
  @include button-variant-mixin(
    $canopy-color-text-primary,
    $canopy-color-primitives-gray-94,
    $canopy-color-primitives-gray-94
  );
}

.btn-danger {
  @include button-variant-mixin(
    $canopy-color-text-inverse,
    $canopy-color-primitives-red-48,
    $canopy-color-primitives-red-48
  );
}

.btn-warning {
  @include button-variant-mixin(
    $canopy-color-text-inverse,
    $canopy-color-primitives-yellow-62,
    $canopy-color-primitives-yellow-62
  );
}

.btn-white {
  @include button-variant-mixin(
    $canopy-color-text-primary,
    $canopy-color-interactive-background-secondary-default,
    $canopy-color-interactive-background-secondary-default
  );
}

.btn-default {
  @include button-variant-mixin(
    $canopy-color-text-inverse,
    $canopy-color-primitives-gray-39,
    $canopy-color-primitives-gray-39
  );
}

.btn-brand {
  @include button-variant-mixin(
    $canopy-color-text-inverse,
    $canopy-color-interactive-background-primary-default,
    $canopy-color-interactive-background-primary-default
  );
}

.btn-info {
  @include button-variant-mixin(
    $canopy-color-text-inverse,
    $canopy-color-primitives-blue-41,
    $canopy-color-primitives-blue-41
  );
}

.btn-accent {
  @include button-variant-mixin(
    $canopy-color-text-inverse,
    $canopy-color-primitives-magenta-40,
    $canopy-color-primitives-magenta-40
  );
}

.btn-gray {
  @include button-variant-mixin($canopy-color-primitives-gray-39, transparent, transparent);

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: $canopy-color-primitives-gray-94;
    border-color: $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
    box-shadow: none;
    outline: 0;
  }

  &.active,
  &.active:focus {
    background-color: $canopy-color-interactive-background-secondary-default;
    border: 1px solid $canopy-color-primitives-gray-86;
    box-shadow: none;
    outline: none;

    &:hover {
      background-color: $canopy-color-interactive-background-secondary-hover;
    }
  }
}

.btn-ghost {
  color: $canopy-color-interactive-link-default;
  background-color: transparent;
  display: inline-flex;
  font-size: 15px;
  font-weight: bolder;
  border: none;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  &[disabled],
  &[disabled]:hover,
  .disabled,
  .disabled:hover {
    background-color: $canopy-color-interactive-background-disabled;
    border: 2px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
    color: $canopy-color-primitives-gray-74;
    cursor: not-allowed;
    opacity: 1;
  }

  &:hover,
  &:focus {
    color: $canopy-color-interactive-link-default;
    background: $canopy-color-primitives-purple-94;

    &:focus,
    &:active {
      color: $canopy-color-interactive-link-active;
      background: $canopy-color-primitives-purple-87;
    }
  }
  &:active {
    color: $canopy-color-interactive-link-active;
    background: $canopy-color-primitives-purple-87;
  }
}

.btn-link {
  @include button-variant-mixin($canopy-color-text-primary, transparent, transparent);
  font-weight: normal;

  &:hover {
    color: $canopy-color-text-primary;
    text-decoration: underline;
  }

  &[disabled],
  &[disabled]:hover,
  .disabled,
  .disabled:hover {
    background-color: transparent;
    border: 2px solid transparent;
    color: $canopy-color-primitives-gray-74;
    cursor: not-allowed;
  }
}

.btn-primary-o {
  @include button-variant-mixin-o(
    $canopy-color-brand-secondary,
    $canopy-color-interactive-background-secondary-default,
    $canopy-color-brand-secondary
  );
}

.btn-secondary-o {
  @include button-variant-mixin-o(
    $canopy-color-text-primary,
    $canopy-color-interactive-background-secondary-default,
    $canopy-color-primitives-gray-16
  );
}

.btn-success-o {
  @include button-variant-mixin-o(
    #5cb85c,
    $canopy-color-interactive-background-secondary-default,
    #5cb85c
  );
}

.btn-info-o {
  @include button-variant-mixin-o(
    $canopy-color-primitives-blue-41,
    $canopy-color-interactive-background-secondary-default,
    $canopy-color-primitives-blue-41
  );
}

.btn-warning-o {
  @include button-variant-mixin-o(
    $canopy-color-primitives-yellow-62,
    $canopy-color-interactive-background-secondary-default,
    $canopy-color-primitives-yellow-62
  );
}

.btn-danger-o {
  @include button-variant-mixin-o(
    $canopy-color-text-danger,
    $canopy-color-interactive-background-secondary-default,
    $canopy-color-primitives-red-48
  );
}

.btn-default-o {
  @include button-variant-mixin-o(
    $canopy-color-primitives-gray-39,
    $canopy-color-interactive-background-secondary-default,
    $canopy-color-primitives-gray-39
  );
}

.btn-brand-o {
  @include button-variant-mixin-o(
    $canopy-color-brand-primary,
    $canopy-color-interactive-background-secondary-default,
    $canopy-color-brand-primary
  );
}

.btn-sm {
  font-size: 1em;
  height: 40px;
  line-height: 38px;
}

.btn-xs {
  font-size: 12px;
  height: 24px;
  line-height: 22px;
  padding: 0 10px;
}

.btn-short {
  font-size: 1.2em;
  width: 100px;
}

.btn-medium {
  font-size: 1.2em;
  width: 200px;
}

.btn-long {
  font-size: 1.2em;
  width: 300px;
}

.btn-badge {
  font-size: 12px;
  height: 30px;
  line-height: 30px;
}

@include media-breakpoint-down(sm) {
  .btn-full-xs {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
}

.has-error {
  .btn-primary {
    background-color: $canopy-color-primitives-purple-98;
  }
}

.point-click-care-sign-in {
  background:
    no-repeat 0 0
    url("../../assets/images/point_click_care/sign_in_button_sprite.png");
}

.epic-sign-in {
  background:
    no-repeat 0 0
    url("../../assets/images/epic/sign_in_button_sprite.png");
}

.third-party-sign-in {
  background-size: cover;
  border: 0;
  display: inline-block;
  height: 50px;
  padding: 0;
  width: 244px;

  &:hover {
    background-position: 0 -50px;
  }

  &:active {
    background-position: 0 -100px;
  }

  &.disabled {
    background-position: 0 -150px;
    pointer-events: none;
  }
}

.btn-bottom-left-fixed {
  bottom: 25px;
  left: 25px;
  position: fixed;
  z-index: $z-index-btn-bottom-left-fixed;
}

.btn-rounded {
  border-radius: 30px;
  box-shadow: 1px 2px 15px 0 $shadow-color;
}

.btn-pill {
  background-color: $canopy-color-interactive-background-secondary-default;
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: 50px;
  box-shadow: none;
  color: $canopy-color-text-primary;
  font-weight: bold;
  max-width: 250px;
  overflow: hidden;
  padding: 0 1rem;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;

  &.btn-pill-full-width {
    max-width: initial;
  }

  &.btn-sm {
    border-radius: 40px;
    font-size: 0.875rem;
  }

  &.btn-inverted {
    background-color: $canopy-color-primitives-gray-39;
    color: $canopy-color-text-inverse;

    &:hover {
      background-color: $canopy-color-primitives-gray-39;
      color: $canopy-color-text-inverse;
    }

    &:active {
      background-color: darken($canopy-color-primitives-gray-39, 10%);
    }
  }

  &.dark-outline,
  &.dark-outline:focus,
  &.dark-outline:hover {
    border-color: $canopy-color-primitives-gray-39;
    outline: 0;
  }

  &:focus,
  &.active:focus {
    border-color: $canopy-color-brand-secondary;
    box-shadow: 0 0 8px lighten($canopy-color-brand-secondary, 25%);
    color: $canopy-color-text-primary;
    outline: 0;
  }

  &:hover,
  &:active,
  &:active:focus,
  &.active {
    box-shadow: none;
    color: $canopy-color-text-primary;
    outline: 0;
  }

  &:focus,
  &:hover {
    border-color: $canopy-color-primitives-gray-74;
  }

  &.active {
    background-color: $canopy-color-primitives-teal-97;
    border-color: $canopy-color-primitives-teal-41;
  }

  &.warning {
    border-color: $canopy-color-primitives-yellow-62;
    font-style: italic;

    &::after {
      content: "*";
    }
  }

  &.active.warning {
    background-color: $canopy-color-background-warning;
  }

  &.has-icon {
    padding-right: 2.5rem;
  }

  .icon.fas.fa-times {
    border-radius: 100%;
    cursor: pointer;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    height: 2rem;
    line-height: 2rem;
    margin-left: 0.25rem;
    position: absolute;
    right: 0.25rem;
    text-align: center;
    top: 0.2rem;
    transition: background 0.2s ease-in-out;
    width: 2rem;
    z-index: 2;

    &::before {
      content: "\f00d";
    }

    &:hover {
      background: $canopy-color-primitives-gray-86;
    }
  }
}

.btn-group {
  .btn {
    border-left-width: 1px;
    border-right-width: 1px;

    + .btn {
      margin-left: 0;
    }

    &:first-child {
      border-left-width: 2px;
    }

    &:last-child {
      border-right-width: 2px;
    }

    &.btn-pill {
      border-left-width: 1px;
      border-right-width: 1px;

      + .btn {
        margin-left: -1px;
      }
    }
  }
}
