@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.content-container:not(:empty) {
  margin: 1.25em 0 0.75em;
  padding: 0 2.25em;
}

.title h3 {
  font-size: 1.5em;
}

.purple-border {
  border: 1px solid purple;
}

.horizontal-divider:not(:empty) + div:not(:empty) {
  border-top: 1px solid $canopy-color-primitives-gray-86;
  margin-top: 1rem;
  padding-top: 1rem;
}
