@import "node_modules/@parachutehealth/canopy-tokens-color/build/tokens.scss";
@import "node_modules/@parachutehealth/canopy-tokens-space/build/tokens.scss";

.supplierIntakeNavbar {
  align-items: center;
  background-color: $canopy-color-background-container;
  border-block-end: 1px solid $canopy-color-border-default;
  box-sizing: border-box;
  display: flex;
  inline-size: 100%;
  justify-content: space-between;
  padding-block: $canopy-space-6x;
  padding-inline: $canopy-space-10x;
  position: sticky;
  top: 0;
  z-index: 100;
}

.documentActions {
  display: flex;
  align-items: center;
}

// Needed in order for combobox & picker results to escape the modal
.canopyDialogModalWithComboboxOrPicker div:nth-child(2) {
  overflow: visible;
}
