.pill {
  background: $canopy-color-primitives-gray-94;
  border-radius: 1rem;
  color: $canopy-color-text-primary;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1rem;
  margin: 0 0 0.5rem;
  min-height: 1.5rem;
  padding: 0.25rem 0.5rem 0.25rem 1.5rem;
  position: relative;
  vertical-align: top;

  &.pill-basic {
    padding-left: 0.5rem;
  }

  > .far,
  > .fas,
  > img {
    left: 0.5rem;
    line-height: 1rem;
    position: absolute;
  }

  > img {
    height: 9px;
    margin-right: 0.25rem;
    max-width: 0.875rem;
    top: 7px;
  }

  &.pill-default {
    color: $canopy-color-text-primary;
  }

  &.pill-dark-default {
    background: $canopy-color-primitives-gray-86;
    color: $canopy-color-text-primary;
  }

  &.pill-solid {
    background-color: $canopy-color-primitives-gray-16;
    color: $canopy-color-text-inverse;

    > .far,
    > .fas {
      color: $canopy-color-primitives-gray-94;
    }
  }

  &.pill-shadow {
    box-shadow: 0 1px 3px rgba($canopy-color-primitives-gray-16, 0.3);
  }

  &.pill-snoozed {
    background-color: $canopy-color-primitives-gray-32;
    color: $canopy-color-text-inverse;
    padding-left: $canopy-space-4x;
  }

  &.pill-primary {
    background-color: $canopy-color-primitives-purple-98;

    > .far,
    > .fas {
      color: $canopy-color-brand-primary;
    }

    &.pill-solid {
      background-color: $canopy-color-interactive-background-primary-default;

      > .far,
      > .fas {
        color: $canopy-color-primitives-purple-98;
      }
    }
  }

  &.pill-secondary,
  &.pill-success {
    background-color: $canopy-color-background-success;

    > .far,
    > .fas {
      color: $canopy-color-brand-secondary;
    }

    &.pill-solid {
      background-color: $canopy-color-brand-secondary;

      > .far,
      > .fas {
        color: $canopy-color-primitives-teal-97;
      }
    }

    &.pill-default-bg {
      background: $canopy-color-primitives-gray-94;
    }
  }

  &.pill-info {
    background-color: $canopy-color-background-information;

    > .far,
    > .fas {
      color: $canopy-color-primitives-blue-41;
    }

    &.pill-solid {
      background-color: $canopy-color-primitives-blue-41;

      > .far,
      > .fas {
        color: $canopy-color-primitives-blue-97;
      }
    }
  }

  &.pill-warning {
    background-color: $canopy-color-background-warning;

    > .far,
    > .fas {
      color: $canopy-color-primitives-yellow-62;
    }

    &.pill-solid {
      background-color: $canopy-color-primitives-yellow-62;

      > .far,
      > .fas {
        color: $canopy-color-primitives-yellow-96;
      }
    }
  }

  &.pill-danger {
    background-color: $canopy-color-background-danger;

    > .far,
    > .fas {
      color: $canopy-color-text-danger;
    }

    &.pill-solid {
      background-color: $canopy-color-primitives-red-48;

      > .far,
      > .fas {
        color: $canopy-color-primitives-red-97;
      }
    }
  }
}

.override-pill {
  position: absolute;
  right: 8%;
  top: 25%;
}

.override-pill-center {
  vertical-align: middle;
}

.popover-pill-container {
  min-width: 140px;

  .popover-pill {
    min-width: 120px;
  }
}
