@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.radioInput {
  margin-bottom: 0;
}

.formButtonSection {
  margin-top: $canopy-space-12x;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  > button:first-child {
    margin-right: $canopy-space-4x;
  }
}

.redText {
  color: $canopy-color-text-danger;
}
