@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";
@import "app/javascript/stylesheets/mixins/font-sizes.scss";

.modalTitle {
  @include font-xl;
  @include media-breakpoint-up(md) {
    @include font-xxl;
  }
}

.modalSubtitle {
  @include font-l;
  @include media-breakpoint-up(md) {
    @include font-xl;
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: $canopy-space-16x 0;
  @include media-breakpoint-up(md) {
    padding: $canopy-space-32x 0;
  }
}

.emailForm {
  margin-top: 32px;
  width: 100%;
  max-width: 380px;
  min-width: 160px;
}
