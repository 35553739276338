@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.sorting-by {
  font-size: 0.875rem;

  @include media-breakpoint-up(md) {
    margin-top: 10px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 16px;
  }
}
