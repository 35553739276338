.circle {
  background-color: $canopy-color-brand-primary;
  border-radius: 50%;
  box-sizing: border-box;
  color: $canopy-color-text-inverse;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 600;
  height: 32px;
  line-height: 2.5;
  min-width: 34px;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  vertical-align: middle;
  width: 34px;
}

.purple.circle {
  background-color: $canopy-color-brand-primary;
}

.green.circle {
  background-color: $canopy-color-primitives-teal-41;
}

.gray.circle {
  background-color: $canopy-color-primitives-gray-39;
}

.purple-o.circle {
  background-color: $canopy-color-background-container;
  border: 1px solid $canopy-color-brand-primary;
  color: $canopy-color-brand-primary;
}

.green-o.circle {
  background-color: $canopy-color-background-container;
  border: 1px solid $canopy-color-primitives-teal-41;
  color: $canopy-color-primitives-teal-41;
}

.gray-o.circle {
  background-color: $canopy-color-background-container;
  border: 1px solid $canopy-color-primitives-gray-39;
  color: $canopy-color-primitives-gray-39;
}
