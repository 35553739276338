@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.header {
  font-size: 20px !important;
  padding-top: 0 !important;
  text-align: center !important;
  font-weight: normal;

  @include media-breakpoint-up(md) {
    font-size: 32px !important;
  }
}

.subhead {
  font-size: 16px;
  font-weight: bolder;

  @include media-breakpoint-up(md) {
    font-size: 20px;
    font-weight: normal;
  }
}

.warnings-container {
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: 4px;
  width: fit-content;
  text-align: left;
}

.warnings-header {
  font-size: 16px;
  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}

.warnings-list {
  padding-inline-start: 1.5rem;
}

.warnings-list-item {
  font-size: 14px;
  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}
