@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.duplicationInfo {
  background-color: $canopy-color-primitives-gray-94;
  border-color: $canopy-color-primitives-gray-86;
  margin-bottom: $canopy-space-8x;
  padding: $canopy-space-12x;

  div:first-of-type {
    margin-bottom: $canopy-space-6x;
  }

  .subheading {
    display: inline-block;
    width: 200px;
    font-weight: $canopy-typography-font-weight-bold;
    font-size: $canopy-typography-body-medium-font-size;
    margin-bottom: 0;
  }
}
