.chat,
.chat textarea,
.chat input,
.chat p,
.chat strong,
.chat span,
.chat .bubble.left,
.chat .bubble.right {
  font-family: $font-body !important;
}

#chat-app .bottom {
  bottom: 15px;
}

body.has-mobile-nav {
  #chat-app .bottom {
    @include media-breakpoint-down(md) {
      bottom: 80px;
    }
  }

  .zEWidget-launcher,
  iframe#launcher {
    @include media-breakpoint-down(md) {
      bottom: 65px !important;
    }
  }
}

body.right-sidebar-is-open {
  #chat-app .bottom {
    @include media-breakpoint-down(sm) {
      bottom: 15px;
    }
  }

  .zEWidget-launcher,
  iframe#launcher {
    @include media-breakpoint-down(sm) {
      bottom: 10px !important;
    }
  }
}

body.has-mobile-nav #chat-app .bar {
  @include media-breakpoint-down(md) {
    bottom: 0;
  }
}

body.mobile-nav-open {
  #chat-app,
  .zEWidget-launcher,
  iframe#launcher {
    display: none;
  }
}
