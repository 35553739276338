.truncated-text {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@supports (-webkit-line-clamp: 1) {
  .truncated-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: revert;
  }
}
