.beta-badge {
  @include font-xxs;
  background: $canopy-color-primitives-gray-94;
  border-radius: 3px;
  color: $canopy-color-primitives-gray-39;
  cursor: default;
  font-weight: bold;
  margin: 0;
  padding: 2px 4px;
  letter-spacing: 0.033em;
  text-transform: uppercase;
}
