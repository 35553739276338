@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.user-preference-navigation-content {
  margin-left: 48px;
}

.user-preference-navigation-main {
  width: 300px !important;
  border-right: 1px solid $canopy-color-primitives-gray-74;
  display: block;
  position: absolute;
  height: 100%;
}

.main-content {
  margin-left: 375px !important;
}

.add-clinicians-row {
  padding-top: 64px;
  border-top: 1px solid $canopy-color-primitives-gray-74;
  margin-right: 64px;
}

.user-preference-container {
  margin-top: 4rem;
  padding-left: 0;
}

.cant-find-clinician-link {
  font-size: $canopy-typography-body-small-font-size;
  margin-top: $canopy-space-12x;
}
