@mixin font-xxxxl {
  font-size: 4rem;
  line-height: 1.2;
  margin: 0 0 0.75rem;
}

@mixin font-xxxl {
  font-size: 3rem;
  line-height: 1.2;
  margin: 0 0 0.75rem;
}

@mixin font-xxl {
  font-size: 2rem;
  line-height: 1.2;
  margin: 0 0 0.75rem;
  padding: 0;
}

@mixin font-xl {
  font-size: 1.5rem;
  line-height: 1.2;
  margin: 0 0 0.75rem;
  padding: 0;
}

@mixin font-l {
  font-size: 1.25rem;
  line-height: 1.2;
  margin: 0 0 0.75rem;
  padding: 0;
}

@mixin font-m {
  font-size: 1rem;
  line-height: 1.2;
  margin: 0 0 0.75rem;
  padding: 0;
}

@mixin font-s {
  font-size: 1rem;
  line-height: 1.2;
  margin: 0 0 0.75rem;
  padding: 0;
}

@mixin font-xs {
  font-size: 0.875rem;
  line-height: 1.2;
  margin: 0 0 0.75rem;
  padding: 0;
}

@mixin font-xxs {
  font-size: 0.75rem;
  line-height: 1.2;
  margin: 0 0 0.75rem;
  padding: 0;
}

@mixin font-xxxs {
  font-size: 0.625rem;
  line-height: 1.2;
  margin: 0 0 0.75rem;
  padding: 0;
}

@mixin font-subparagraph {
  font-size: 0.875rem;
}
