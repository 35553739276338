@import "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens";
@import "node_modules/@parachutehealth/canopy-tokens-color/build/tokens";
@import "node_modules/@parachutehealth/canopy-tokens-space/build/tokens";
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.demographics {
  @include canopy-typography-body-medium;
  color: $canopy-color-text-primary;
  display: flex;
  flex-direction: row;
  gap: $canopy-space-16x;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.demographics-header {
  @include canopy-typography-body-small;
  color: $canopy-color-text-secondary;
}

.patient-name {
  @include canopy-typography-heading-2xlarge;
  margin-block-start: $canopy-space-4x;
  margin-block-end: $canopy-space-12x;
}

.subhead {
  @include canopy-typography-heading-large;
}
