@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.selected-doctor-item {
  border-color: $canopy-color-brand-primary;
  background-color: $canopy-color-interactive-background-secondary-selected;
  border-style: solid;
  border-width: 2px;
  list-style: none;
  display: flex;
  height: 32px;
  padding: $canopy-space-6x $canopy-space-4x $canopy-space-6x $canopy-space-6x;
  align-items: center;
  font-size: $canopy-typography-body-small-font-size;
}

.x-mark {
  margin-left: $canopy-space-4x;
  padding: $canopy-space-2x 0 0 0;
}
