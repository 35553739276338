.spinner {
  animation: rotate 0.75s infinite linear;
  border: 1px solid $canopy-color-primitives-white-100;
  border-bottom-color: $canopy-color-primitives-white-100;
  border-left-color: $canopy-color-primitives-white-100;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: $canopy-color-primitives-white-100;
  box-shadow: 0 0 1px $canopy-color-primitives-white-100;
  display: inline-block;
  height: 14px;
  margin: 0 auto;
  text-align: center;
  width: 14px;
}
