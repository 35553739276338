.suggestion {
  display: flex;
  justify-content: space-between;
}

.content {
  flex: 1;
}

.label {
  display: inline-block;
  min-width: 60px;
  padding-right: 4px;
}

.icon {
  align-self: center;
  display: flex;
  width: 16px;
}
