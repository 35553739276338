$logo-size: 36px;
$logo-size-sm: 18px;

.logo {
  color: $canopy-color-primitives-gray-39;
  display: inline-block;
  position: relative;
  text-align: center;
  width: $logo-size;

  > .image {
    background: url("../../assets/images/logo72x72.png") no-repeat;
    background-size: cover;
    height: $logo-size;
    margin-top: -0.5rem;
    width: $logo-size;
  }

  > .text {
    font-size: 0.75rem;
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  > .pill {
    bottom: -25px;
    font-size: 0.6rem;
    height: auto;
    left: -12px;
    margin: 0 auto;
    padding-left: 1rem;
    position: absolute;
    right: 0;
    width: 200px;

    > .fas {
      left: 0.25rem;
    }
  }

  &.logo-sm {
    width: $logo-size-sm;

    > .image {
      height: $logo-size-sm;
      width: $logo-size-sm;
    }
  }

  &.logo-translucent {
    filter: grayscale(1);
    opacity: 0.3;
  }
}

.logo-box {
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 30px;

  img {
    margin: 6px;
    width: 18px;
  }
}
