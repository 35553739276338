.w-md-250 {
  @include media-breakpoint-only(lg) {
    width: 250px !important;
  }
}

.w-lg-250 {
  @include media-breakpoint-up(xl) {
    width: 250px !important;
  }
}
