@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.option {
  border-radius: $canopy-space-4x;
  background-color: $canopy-color-primitives-white-100;
  display: flex;
  align-items: center;
  padding: $canopy-space-10x;
  outline: 1px solid $canopy-color-border-default;
  margin-bottom: $canopy-space-6x;

  &:hover {
    background-color: $canopy-color-primitives-gray-94;
    cursor: pointer;
  }

  &:focus,
  &:focus-visible {
    border-color: $canopy-color-brand-primary;
  }

  &.selected {
    background-color: $canopy-color-primitives-purple-98;
    outline: 2px solid $canopy-color-brand-primary;
  }

  &.disabled {
    background-color: $canopy-color-interactive-background-disabled;
    color: $canopy-color-text-disabled;
    cursor: not-allowed;
  }

  .labelContainer {
    width: auto;
  }

  .iconContainer {
    margin-inline-end: $canopy-space-6x;
    .icon {
      color: $canopy-color-primitives-gray-39;
    }
  }

  label {
    font-size: $canopy-typography-body-large-font-size;
    font-weight: $canopy-typography-font-weight-bold;
    margin-bottom: $canopy-space-4x;
  }
}
