@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.sub-option-cta {
  display: flex;
  max-width: 90%;
  min-height: 32px;
  padding: 4px 8px 4px 16px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  @include canopy-typography-body-small;
  color: $canopy-color-brand-primary;
  &:hover,
  &:focus {
    color: $canopy-color-brand-primary;
  }
}
