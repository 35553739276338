/*
 * The layout directive on an inner container of the CanopyFormFieldGroup
 * uses flex, which causes our "card" style to line up horizontally rather than stacking vertically.
 * To counteract this, we wrap our cards in an intermediary container that resets the flex-direction
 * et al the way we want it.
 */
.cardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
