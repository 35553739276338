@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.diagnoses-select {
  /*
    I am using this combination of selectors because
    currently the select component does not support classNames, and
    I don't want to use the internal classNames (ex. react-select__control)
    because the select component will be replaced by a canopy component soon
  */
  :global(#icd10-codes-select) > div {
    border: 1px solid $canopy-color-primitives-red-48;
    box-shadow: none;
  }

  // The last style is overridden on hover because of the higher specificity of this selector
  :global(#icd10-codes-select):hover > div {
    border: 1px solid $canopy-color-primitives-red-71;
    box-shadow: none;
  }

  > div:first-child {
    margin-bottom: 0.5em;
  }
}
