@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

/*
* At least with MUI, the focus/active state is moved from the firing button to the menu itself,
* and so there's no visual indication that the button (or its "descendents") are being interacted with.
* It would be nice if we could dynamically invoke the :active effect of whichever button variant we're working with,
* but until that's possible, this copies the tertiary :active state.
*/
.open {
  background-color: $canopy-color-primitives-gray-74 !important;
  color: $canopy-color-primitives-gray-16 !important;

  svg {
    fill: $canopy-color-primitives-gray-16 !important;
  }
}

.menu {
  a,
  li {
    padding: $canopy-space-6x;
    font-size: $canopy-typography-body-small-font-size;
    color: $canopy-color-text-primary !important; // !important needed due to global CMS CSS
    font-weight: 500 !important; // !important needed due to global CMS CSS
    text-decoration: none !important; // !important needed due to global CMS CSS
    &:hover {
      text-decoration: none;
      background-color: $canopy-color-interactive-background-secondary-hover;
    }
    &:focus {
      text-decoration: none;
    }
  }

  :global .MuiList-padding {
    padding: 0;
  }

  /* allow easily making a menu item appear as red */
  :global .danger {
    color: $canopy-color-text-danger !important;

    &:hover {
      background-color: $canopy-color-background-danger;
    }
  }

  /* MUI default has a weird 56px min width; revert that and give it a simple margin instead */
  :global .MuiListItemIcon-root {
    min-width: revert;
    margin-right: $canopy-space-4x;
    color: inherit; // allow changing the icon color
  }
}
