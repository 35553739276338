@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.filename {
  word-break: break-all;
}

.error {
  color: $canopy-color-text-danger;
}

.invalidField {
  border: 1px solid $canopy-color-primitives-red-48;
}
