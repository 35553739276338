@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.pending_order_alert {
  @include canopy-typography-heading-small;
  display: flex;
  align-items: center;

  button {
    margin-left: auto;
  }
}
