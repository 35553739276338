.image.with-header {
  display: inline-block;
  vertical-align: middle;
  width: 80px;

  @include media-breakpoint-down(md) {
    width: 60px;
  }
}

.image.h1-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid $canopy-color-primitives-teal-41;
  display: inline-block;
  height: 80px;
  vertical-align: middle;
  width: 80px;
}

.image.tr-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid $canopy-color-primitives-teal-41;
  display: inline-block;
  height: 20px;
  vertical-align: middle;
  width: 20px;
}

.image.thumbnail {
  background-color: $canopy-color-background-container;
  background-origin: content-box;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
  border-radius: 0;
  display: block;
  height: 100px;
  margin-bottom: 0;
  padding: 0;
  width: 100px;

  &.small {
    height: 60px;
    width: 60px;
  }

  &.centered {
    margin: 20px auto;
  }
}

.image.with-description {
  position: relative;
  display: grid;
  grid-template-columns: 65px 1fr;
  grid-template-rows: 1fr;

  > img,
  > .image-container {
    left: 0;
    max-width: 65px;
    position: absolute;
    top: 0;
    grid-column: 1;
  }

  .image-alert {
    right: 5px;
    bottom: 5px;
    position: absolute;
  }

  img {
    max-width: 65px;
  }

  > .content {
    padding-left: 15px;
    grid-column: 2;
  }

  &.image-sm {
    > img {
      max-width: 20px;
    }

    > .content {
      min-height: 20px;
      padding-left: 35px;
    }

    @include media-breakpoint-down(sm) {
      > .content {
        padding-left: 0;
      }
    }
  }

  &.image-lg {
    > img {
      max-width: 120px;
    }

    > .content {
      min-height: 120px;
      padding-left: 135px;
    }

    @include media-breakpoint-down(sm) {
      > .content {
        padding-left: 0;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    > img {
      margin-bottom: 0.5rem;
      position: relative;
    }

    > .content {
      padding-left: 0;
    }
  }
}

img.responsive {
  height: auto;
  max-width: 100%;
}
