@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.authCard {
  background: $canopy-color-primitives-white-100;
  border: 1px solid $canopy-color-border-default;
  border-radius: $canopy-space-2x;
  box-shadow: 0 2px 4px $canopy-color-border-default;
  margin-bottom: $canopy-space-8x;
}

.authCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $canopy-space-4x $canopy-space-8x;
  border-bottom: 1px solid $canopy-color-border-default;
}

.authCardBody {
  padding: $canopy-space-8x;
}
