@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.border-button-text {
  line-height: 36px;
}

.btn-block-sm {
  height: 40px;
}

@include media-breakpoint-down(xs) {
  .btn-block-sm {
    width: 97vw;
    display: block;
  }
}

@include media-breakpoint-up(sm) {
  .btn-block-sm {
    margin-left: 8px;
  }
}
