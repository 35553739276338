@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
$navbar-height: 60px;

.menu-icon {
  color: $canopy-color-text-primary;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 6px 7px;

  &:hover {
    background-color: $canopy-color-primitives-gray-94;
  }
}

.menu-icon-container {
  margin: 10px 9px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - #{$navbar-height});
}
