.dropdown-container {
  position: relative;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }

  .btn:focus {
    outline: none;
  }

  .fa-check {
    flex: 0 0 12px;
    font-size: 0.75rem;
    margin-top: 2px;
    position: relative;
  }

  .dropdown-button {
    background: none;
    border: none;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }

    .icon {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.dropdown {
  .caret {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px dashed;
    display: inline-block;
    height: 0;
    margin-left: 2px;
    width: 0;
    vertical-align: middle;
  }
}

.dropdown-toggle {
  &::after {
    display: none;
  }
}

.dropdown-toggle.embedded {
  display: flex;
  align-items: center;
}

ul.dropdown-menu {
  @include shadow-secondary;
  border-color: $canopy-color-primitives-gray-86;
  border-radius: 0;
  max-height: 600px;
  overflow: auto;
  padding: 5px;

  li a {
    div {
      display: flex;
    }
  }

  .flex-left {
    flex: 1 0 auto;
  }

  li {
    a,
    label,
    input {
      color: $canopy-color-primitives-gray-39;
      display: block;
      font-size: 0.9em;
      margin: 0;
      padding: 3px 6px;
      transition: none;
      white-space: nowrap;
    }

    input {
      background-color: transparent;
      border: 2px solid transparent;
      border-color: transparent;
      font-weight: normal;
      text-align: left;
      width: 100%;
    }

    label:hover,
    label:hover small,
    a:hover,
    a:hover small,
    input:hover {
      background-color: $canopy-color-primitives-teal-41;
      color: $canopy-color-text-inverse !important;
    }
  }

  &.dropdown-menu-lg {
    border-radius: 3px;
    padding: 0;

    li {
      border-bottom: 1px solid $canopy-color-primitives-gray-86;

      &:last-child {
        border-bottom: none;
      }
    }

    li a {
      color: $canopy-color-text-primary;
      padding: 1rem;

      &:hover {
        background: $canopy-color-primitives-gray-94;
        color: $canopy-color-text-primary !important;
      }
    }
  }
}

.dropdown-right {
  left: auto;
  right: 0;
}

.account-dropdown {
  font-size: 0.875rem;
}

@include media-breakpoint-only(md) {
  .account-dropdown button {
    max-width: 192px;
  }

  .account-dropdown button div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .account-dropdown button .caret {
    position: absolute;
    right: 0.5rem;
    top: 1rem;
  }
}

.collapse {
  display: none;

  &.in {
    display: block;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
}
