@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.patient {
  grid-area: patient;
  border-right: 1px solid $canopy-color-border-default;
}

.insurance {
  grid-area: insurance;
}

.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "patient insurance";
}

.patientDetailCard {
  background: $canopy-color-primitives-white-100;
  margin-bottom: $canopy-space-8x;
  margin-left: $canopy-space-2x;
  padding-top: $canopy-space-6x;
  padding-bottom: $canopy-space-6x;
  border-radius: 4px;
  width: fit-content;
}

.patientDetailHeader {
  padding: $canopy-space-2x $canopy-space-8x;
}

.patientDetailItem {
  padding: $canopy-space-2x $canopy-space-8x;
}

.hr.separator {
  border-top: 1px solid $canopy-color-border-default;
  color: $canopy-color-border-default;
  background: none;
}
