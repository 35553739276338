span.progress-circle {
  font-size: 24px;
  height: 22px;
  width: 22px;
}

svg.progress-circle {
  display: inline-block;
  height: 22px;
  width: 22px;
}

.progress-circle.progress-circle-s {
  font-size: inherit;
  height: 15px;
  margin: 5px 5px 0 0;
  width: 15px;
}
