@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@use "../../styles/sharedFormStyles.module.scss" as *;

.inputField {
  max-width: calc(50% - $canopy-space-6x);
}

.formSection {
  > div {
    flex: 0 1 50%;
  }

  > div:first-child {
    margin-right: $canopy-space-12x;
  }
}

.radioInput {
  margin-bottom: 0;
}

.checkboxInput {
  margin-top: -$canopy-space-12x;
  margin-bottom: $canopy-space-20x;
}

.alignRight {
  margin-inline-start: auto;
}
