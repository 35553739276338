@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.promoted-supplier-banner {
  margin-bottom: $canopy-space-16x;
  justify-content: flex-end;
}

.promoted-supplier-banner ul {
  display: flex;
  gap: $canopy-space-16x;
}

.promoted-supplier-banner-link-container {
  display: flex;
  margin-top: $canopy-space-2x;
}

.promoted-supplier-link-icon {
  color: $canopy-color-interactive-link-default;
  align-items: end;
  margin-left: $canopy-space-2x;
  margin-right: $canopy-space-2x;
}

.promoted-supplier-banner-link {
  padding-top: $canopy-space-1x;
  font-size: $canopy-typography-body-medium-font-size !important;
}
