@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
$color-border: #5b0098;
$color-hover: #eee6fa;
$color-focus: #dbcbf3;
$color-circle: #f8f4ff;

.container + .container {
  margin-top: 1rem;
}

.card {
  color: black;
  display: block;
  border: 1px solid $color-border;
  border-radius: 4px;
  padding: 1rem;
  position: relative;
}

.card:hover {
  background: $color-hover;
  text-decoration: none;
}

.card:focus {
  background: $color-focus;
  text-decoration: none;
}

.card p {
  margin-bottom: 0;
}

.circle {
  align-items: center;
  background: $color-circle;
  border-radius: 50%;
  display: flex;
  height: 72px;
  justify-content: center;
  left: 1rem;
  position: absolute;
  top: 1rem;
  width: 72px;
}

.circle img {
  width: 26px;
}

.content {
  display: inline-block;
  min-height: 72px;
  padding-left: 88px;
}
