@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.cmsDrawer {
  :global .MuiDrawer-paper {
    width: 80vh; // to be revisited
    box-shadow:
      0 8px 10px -5px rgba(0, 0, 0, 0.2),
      0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12);
  }
}

.headerContainer {
  background-color: $canopy-color-background-primary;
  border-bottom: 1px solid $canopy-color-primitives-gray-86;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $canopy-space-12x;

  h3 {
    margin-inline-end: $canopy-space-4x;
    margin-block-end: 0;
    @include canopy-typography-heading-xlarge;
  }
}

.scrim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
