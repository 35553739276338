@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.multi-actor-h1 {
  margin-bottom: 0;
}

.nav {
  padding: $canopy-space-8x !important;
}

.nav-container {
  background-color: $canopy-color-background-secondary;
  border-bottom: 1px solid $canopy-color-primitives-gray-86;
  line-height: 1;
}

.nav-flex {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.sign-out-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}
