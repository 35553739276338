@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -0.75rem;
  min-width: 43px;
  text-align: center;

  &.selected {
    color: $canopy-color-brand-secondary;
  }
}
