$popover-border-color: rgba($canopy-color-primitives-gray-16, 0.2);

.popover {
  background-clip: padding-box;
  background-color: $canopy-color-background-container;
  border: 1px solid $popover-border-color;
  border-radius: 0;
  box-shadow: 0 5px 10px $popover-border-color;
  display: none;
  font-family: $font-body;
  line-break: auto;
  max-width: 320px;
  padding: 1rem;
  position: relative;
  width: 320px;
  z-index: $z-index-popover;

  &.bottom {
    margin-top: 10px;
  }

  > .arrow {
    border-width: 11px;
  }
  > .arrow,
  > .arrow::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  > .arrow::after {
    content: "";
    border-width: 10px;
  }

  &.bottom > .arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: $popover-border-color;
  }
  &.bottom > .arrow::after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: $canopy-color-primitives-white-100;
  }

  &.popover-large {
    max-width: 360px;
    width: 360px;

    .popover-content {
      max-height: 550px;
    }

    .flatpickr-calendar.inline {
      box-shadow: none;
      margin: 0 auto;
    }
  }

  &.popover-date-selector {
    max-width: 387px;
    width: 387px;

    .popover-content {
      max-height: 550px;

      .input-date {
        width: 130px;
      }
    }

    .flatpickr-calendar.inline {
      box-shadow: none;
      margin: 0 auto;
    }
  }

  .popover-content {
    max-height: 450px;
    overflow: auto;
    padding: 0;
  }

  /*
  * Some use cases may need to overflow outside the popover area,
  * such as select menus. The Popover component allows
  * opting into this behavior, which targets this CSS rule
  */
  .popover-content.popover-visible-overflow {
    overflow: visible;
  }

  button:focus,
  button:active,
  button:active:focus {
    outline: none;
  }
}

.popover-container {
  .react-tiny-popover-container {
    z-index: $z-index-popover;

    .popover {
      position: relative;
    }
  }

  &.is-closed .react-tiny-popover-container {
    z-index: -1;
  }
}
