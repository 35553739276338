@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "app/javascript/stylesheets/variables/z-indexes.scss" as *;
@use "app/javascript/stylesheets/utilities/animation-keyframes.scss" as *;
$nav-mobile-height: 60px;

.overlay {
  background: rgba($canopy-color-primitives-gray-16, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: ease-in 0.1s;
  z-index: $z-index-bottom-navigation-overlay;
}

.space {
  height: 100%;
}

.mobile {
  animation: fadeIn 0.2s ease-in-out;
  bottom: 0;
  max-height: 100%;
  overflow: auto;
  position: fixed;
  width: 100%;
  z-index: $z-index-bottom-navigation-mobile;
}

.body {
  animation: moveUp 0.3s ease-in;
  position: relative;
  z-index: 0;
}

.bar {
  background-color: $canopy-color-background-secondary;
  border-top: 1px solid $canopy-color-border-default;
  cursor: pointer;
  height: $nav-mobile-height;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}

.button a {
  border-radius: 0;
  height: $nav-mobile-height - 1px;
  line-height: $nav-mobile-height - 1px;
  margin: 0;
  width: 120px;
}

.buttonContainer {
  background-color: $canopy-color-background-secondary;
  border-bottom: 1px solid $canopy-color-border-default;

  & > * {
    flex-basis: 50%;
  }
}
