.bulletin {
  @include font-xs;
  border-radius: 1px;
  color: $canopy-color-primitives-teal-41;
  padding: 5px;
  display: block;
  position: relative;
  top: -10px;
  margin-top: -10px;

  &.bulletin-warning {
    color: $canopy-color-primitives-yellow-62;
  }

  &.bulletin-success {
    color: $canopy-color-text-success;
  }

  &.bulletin-danger {
    color: $canopy-color-text-danger;
  }
}

.bulletin.bulletin-placeholder {
  background-color: inherit;
  border: 0;
  color: inherit;
  height: 27px;
}
