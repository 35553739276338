.stripe {
  @include clearfix;
  background: $canopy-color-primitives-purple-98;
  border: 0;
  border-radius: 2px;
  box-shadow:
    0 0 0 1px $canopy-color-primitives-purple-24,
    inset 0 0 0 1px lighten($canopy-color-primitives-purple-24, 40%);
  color: $canopy-color-text-primary;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;

  a {
    color: $canopy-color-text-primary;
  }

  &.stripe-danger {
    background: $canopy-color-background-danger;
    box-shadow: 0 0 0 1px $canopy-color-primitives-red-48, inset 0 0 0 1px $canopy-color-primitives-red-48;
    color: $canopy-color-text-primary;

    a {
      color: $canopy-color-text-primary;
    }
  }

  &.stripe-alert {
    background: $canopy-color-background-warning;
    box-shadow: 0 0 0 1px $canopy-color-primitives-yellow-62, inset 0 0 0 1px $canopy-color-primitives-yellow-62;
    color: $canopy-color-text-primary;

    a {
      color: $canopy-color-text-primary;
    }
  }

  > .icon {
    cursor: pointer;
    font-weight: bold;
    line-height: 1.45;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;
  }
}
