@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.container {
  background: $canopy-color-primitives-purple-98;
  border: 1px solid $canopy-color-primitives-purple-24;
  border-radius: 0.25rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
  padding: $canopy-space-12x;
}
