/*
 Most of the overrides here are to allow associating a tooltip with a button.
 We don't want to _visually_ look like a button, just have the semantics of one,
 so we get rid of interaction states and some of Canopy's baked-in dimensions
 */
.minimalButton {
  min-height: auto !important;
  width: auto !important;
  vertical-align: sub;

  /* current Canopy styles cap the height which makes icons used this way too small */
  svg {
    height: inherit !important;
  }
}
