@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.label {
  color: $canopy-color-text-primary;
}

.container {
  padding-bottom: $spacer * 0.5;
}
