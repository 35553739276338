$footer-height: 210px;

.sticky-footer {
  background: $canopy-color-background-container;
  border-top: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
  bottom: 0;
  margin-left: -15px;
  min-height: $footer-height;
  padding: 30px 15px;
  position: fixed;
  width: 100%;
}

.sticky-footer-push {
  height: $footer-height + 30px;
}
