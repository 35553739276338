.documentation-requirement-icon {
  max-width: 30px;
}

.documentation-requirement-content {
  padding-left: 16px;
  width: 100%;

  .requirement-wrapper {
    display: flex;
    flex-flow: wrap;
  }
}

.documentation-requirement-link {
  color: $canopy-color-interactive-link-default;
  display: flex;
  font-family: Avenir-Heavy, Avenir, serif;
  font-size: 13px;
  font-weight: bold;
  margin-top: 3px;
}

.right-sidebar-is-open {
  @include media-breakpoint-only(md) {
    .documentation-requirement-content .requirement-wrapper {
      display: block;
    }
  }
}

.ssr-app-documentation-icon-container {
  margin-right: 0.25rem;

  .documentation-requirement-icon {
    left: 0;
  }
}
