.color-primary {
  color: $canopy-color-brand-primary !important;
}

.bg-primary {
  background-color: $canopy-color-interactive-background-primary-default !important;
}

.color-primary-light {
  color: $canopy-color-primitives-purple-98 !important;
}

.bg-primary-light {
  background-color: $canopy-color-primitives-purple-98 !important;
}

.color-secondary {
  color: $canopy-color-brand-secondary !important;
}

.bg-secondary {
  background-color: $canopy-color-brand-secondary !important;
}

.color-secondary-light {
  color: $canopy-color-primitives-teal-97 !important;
}

.bg-secondary-light {
  background-color: $canopy-color-primitives-teal-97 !important;
}

.color-dark-gray {
  color: $canopy-color-primitives-gray-39 !important;
}

.bg-dark-gray {
  background-color: $canopy-color-primitives-gray-39 !important;
}

.color-mid-gray {
  color: $canopy-color-primitives-gray-74 !important;
}

.bg-mid-gray {
  background-color: $canopy-color-primitives-gray-74 !important;
}

.color-lighter-gray {
  color: $canopy-color-primitives-gray-94 !important;
}

.bg-lighter-gray {
  background-color: $canopy-color-primitives-gray-94 !important;
}

.color-lightest-gray {
  color: $canopy-color-primitives-gray-97 !important;
}

.bg-lightest-gray {
  background-color: $canopy-color-background-primary !important;
}

.color-black {
  color: $canopy-color-text-primary !important;
}

.bg-black {
  background-color: $canopy-color-primitives-gray-16 !important;
}

.color-success {
  color: $canopy-color-text-success !important;
}

.color-warning {
  color: $canopy-color-primitives-yellow-62 !important;
}

.color-danger {
  color: $canopy-color-primitives-red-48 !important;
}

.bg-danger {
  background-color: $canopy-color-primitives-red-48 !important;
}

.color-danger-light {
  color: $canopy-color-primitives-red-97 !important;
}

.bg-danger-light {
  background-color: $canopy-color-background-danger !important;
}

.color-alert {
  color: $canopy-color-primitives-yellow-62 !important;
}

.bg-alert {
  background-color: $canopy-color-primitives-yellow-62 !important;
}

.color-alert-light {
  color: $canopy-color-primitives-yellow-96 !important;
}

.bg-alert-light {
  background-color: $canopy-color-background-warning !important;
}

.color-info {
  color: $canopy-color-primitives-blue-41 !important;
}

.bg-info {
  background-color: $canopy-color-primitives-blue-41 !important;
}

.color-info-light {
  color: $canopy-color-primitives-blue-97 !important;
}

.bg-info-light {
  background-color: $canopy-color-background-information !important;
}

.color-accent {
  color: $canopy-color-primitives-magenta-40 !important;
}

.bg-accent {
  background-color: $canopy-color-primitives-magenta-40 !important;
}

.canopy-color-primitives-magenta-98 {
  color: $canopy-color-primitives-magenta-98 !important;
}

.bg-accent-light {
  background-color: $canopy-color-primitives-magenta-98 !important;
}

.color-white {
  color: $canopy-color-primitives-white-100 !important;
}

.color-white-transparent {
  color: rgba(255, 255, 255, 0.7) !important;
}

.color-brand {
  color: $canopy-color-brand-primary !important;
}

.color-check-success {
  color: $canopy-color-text-success !important;
}

.color-notification {
  color: $canopy-color-primitives-purple-24 !important;
}

.bg-white {
  background-color: $canopy-color-primitives-white-100 !important;
}

.bg-default {
  background-color: $canopy-color-background-primary !important;
}

.border-primary {
  border: 1px solid $canopy-color-primitives-purple-24;
}

.border-secondary {
  border: 1px solid $canopy-color-brand-secondary;
}

.border-danger {
  border: 1px solid $canopy-color-primitives-red-48;
}

.border-gray {
  border: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
}

.border-field {
  border: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
}

.outline-black {
  outline: 1px solid $canopy-color-primitives-gray-16;
}

.border-black {
  border: 1px solid $canopy-color-primitives-gray-16;
}

.border-mid-gray {
  border: 1px solid $canopy-color-primitives-gray-74;
}

.color-light-gray {
  color: $canopy-color-primitives-gray-86;
}

.bg-light-gray {
  background: $canopy-color-primitives-gray-86;
}

.bg-secondary-active {
  background: $canopy-color-interactive-background-secondary-active;
}
