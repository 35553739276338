.list-block {
  margin-bottom: 0;

  li {
    display: block;
    min-height: 40px;

    a {
      display: block;
      line-height: 40px;
    }
  }
}

.list-bordered {
  > li + li {
    border-top: 1px solid $canopy-color-primitives-gray-86;
  }
}

.list-aligned {
  padding-left: 17px;
}

.list-check {
  $left-padding: 1.2em;
  list-style: none;
  padding: 0;

  li {
    padding-left: $left-padding;

    &::before {
      content: "\f111";
      display: inline-block;
      font-family: "Font Awesome 5 Free";
      font-weight: 500;
      margin-left: -$left-padding;
      width: $left-padding;
    }

    &.checked::before {
      content: "\f111";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
    }
  }
}

.list-dashed {
  $left-padding: 1.2em;
  list-style: none;
  padding: 0;

  li {
    padding-left: $left-padding;

    &::before {
      content: "-";
      display: inline-block;
      margin-left: -$left-padding;
      width: $left-padding;
    }
  }
}
