@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.bulletin-margin {
  margin-top: 1rem !important;
  margin-bottom: -0.625rem !important;
  padding: 0 !important;
}

.field-separator {
  margin-bottom: 1rem !important;
}

.insurance-member-number-input {
  display: flex;
  align-items: flex-end;

  .text-field {
    width: 100%;
  }

  /* Prevents button from jumping when an error message is visible */
  .button--center {
    align-self: center;
    margin-top: 3px;
  }
}

.authorization-select {
  .authorization-suggestion {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .updated-at {
    @include canopy-typography-body-small;
    color: $canopy-color-text-secondary;
  }

  .addNewOption {
    font-weight: bold;
    color: $canopy-color-interactive-link-default;
  }
}
