.sign-up-layout {
  background-color: $canopy-color-background-primary;
  margin: 0;
  padding: 0;

  @include media-breakpoint-down(sm) {
    background-color: $canopy-color-background-secondary;
  }
}

.sign-up-container {
  .sign-up-background {
    background:
      url("../../assets/images/sign-up-background-image-small.jpeg")
      no-repeat center center;
    background-color: $canopy-color-brand-primary;
    height: 360px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    @include media-breakpoint-down(md) {
      background-size: cover;
      height: 320px;
    }

    @include media-breakpoint-down(sm) {
      background: none;
    }
  }

  main {
    padding-top: 80px;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    .sign-up-overlay {
      background: white;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba($canopy-color-primitives-gray-16, 0.06);
      margin: 0 auto;
      max-width: 960px;
      padding: 72px 0 80px;
      position: relative;

      .sign-up-content {
        margin: 0 auto;
        max-width: 100%;
        width: 640px;

        .sign-up-form,
        form {
          margin: 0 auto;
          max-width: 100%;
          width: 440px;
        }
      }

      .back-button {
        left: 0;
        padding-left: 2rem;
        position: absolute;
        top: 1.5rem;
      }

      @include media-breakpoint-only(md) {
        max-width: 736px;
      }

      @include media-breakpoint-down(sm) {
        border-radius: 0;
        box-shadow: none;
        max-width: 767px;
        padding: 0 1rem;

        .back-button {
          padding-left: 0;
          padding-top: 1rem;
          position: relative;
          top: 0;
        }
      }
    }

    h1 {
      text-align: center;

      @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
        padding-top: 2rem;
        text-align: left;
        max-width: 440px;
        margin: 0 auto;
      }
    }

    .actions {
      text-align: center;
      padding-top: 2rem;
    }

    .facility-preview-wrapper {
      min-height: 180px;
    }
  }

  .logo-container {
    text-align: center;
  }

  @include media-breakpoint-down(sm) {
    .logo-container {
      max-width: 440px;
      margin: 0 auto;
      text-align: left;
    }
  }

  .certifications-container {
    clear: both;
    display: flex;
    margin: 2rem auto;
    max-width: 960px;
    position: relative;

    .description-container {
      display: flex;
      align-items: center;
      width: 80%;
      @include media-breakpoint-down(sm) {
        flex-direction: row;
        text-align: center;
        width: auto;
      }
    }
    .certification-logos-container {
      display: flex;
      justify-content: right;
      width: 20%;
      @include media-breakpoint-down(sm) {
        flex-direction: row;
        justify-content: center;
        width: auto;
      }
    }

    @include media-breakpoint-only(md) {
      max-width: 736px;
    }

    @include media-breakpoint-down(sm) {
      border-radius: 0;
      box-shadow: none;
      max-width: 767px;
      padding: 0 1rem;
      flex-direction: column;
      margin: 2rem auto;
    }
  }
}
