.switch {
  > div {
    padding-right: 0.25rem;
  }

  > label {
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    height: 1rem;
    margin-bottom: 0;
    vertical-align: top;
  }
}
