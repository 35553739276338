@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.inputField {
  max-width: calc(50% - $canopy-space-6x);
}

.radioInput {
  margin-bottom: 0;
}

.urgentMessage {
  font-size: $canopy-typography-body-small-font-size;
  color: $canopy-color-text-secondary;
  margin-top: $canopy-space-4x;
}

.formButtonSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  > button:first-child {
    margin-right: $canopy-space-4x;
  }
}

.redText {
  color: $canopy-color-text-danger;
}
