@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.content {
  padding: $spacer * 1.5;
}
.action {
  text-transform: none;
  font-size: 16px;
  font-weight: bold;
}

.uploadTableContainer {
  padding: $spacer * 1.5;
}
