@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.navbar-without-padding {
  padding: 0;
  height: auto;
  margin-right: 6px;
}

.navbar-collapse {
  background-color: $canopy-color-background-secondary !important;
}
