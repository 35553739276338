@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.editDrawerContainer {
  padding: $canopy-space-10x $canopy-space-12x;
  padding-bottom: 120px;
}

.editDrawerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $canopy-space-8x $canopy-space-12x;
  background: $canopy-color-background-primary;
}

.drawerWidth {
  min-width: 600px;
  width: 40%;
}

.redText {
  color: $canopy-color-text-danger;
}

.lessZIndex {
  // Note: Need this class to lessen the mui drawer zIndex from 1200 so that modals appear over the drawer
  z-index: 100 !important;
}
