@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.header {
  padding-top: $canopy-space-4x;
  @include media-breakpoint-down(md) {
    grid-area: 1 / 1 / 2 / 5;
    @include canopy-typography-heading-large;
  }
  @include media-breakpoint-up(lg) {
    @include canopy-typography-heading-xlarge;
    grid-area: 1 / 1 / 2 / 2;
  }
  margin-bottom: $canopy-space-2x;
}

.helper-text {
  color: $canopy-color-text-secondary;
  @include canopy-typography-body-small;
  @include media-breakpoint-down(md) {
    grid-area: 2 / 1 / 3 / 4;
  }
  @include media-breakpoint-up(lg) {
    grid-area: 2 / 1 / 3 / 3;
  }
}

.save-text {
  grid-area: 1 / 2 / 2 / 3;
  width: 6rem;
  display: flex;
  height: 40px;
  align-items: center;
  @include media-breakpoint-down(md) {
    grid-area: 3 / 3 / 4 / 4;
    justify-content: flex-start;
    margin-left: $canopy-space-2x;
  }
  @include media-breakpoint-up(lg) {
    grid-area: 1 / 2 / 2 / 3;
    justify-content: flex-end;
    margin-right: $canopy-space-6x;
  }
}

.completed {
  width: 11.875rem;
}
.mark-complete-button {
  width: 9.375rem;
  margin-right: $canopy-space-4x;
  @include media-breakpoint-down(md) {
    grid-area: 3 / 1 / 4 / 2;
  }
  @include media-breakpoint-up(lg) {
    grid-area: 1 / 3 / 2 / 4;
  }
}

.continue-button {
  @include media-breakpoint-down(md) {
    grid-area: 3 / 2 / 4 / 3;
    margin-bottom: $canopy-space-8x;
  }
  @include media-breakpoint-up(lg) {
    grid-area: 1 / 4 / 2 / 5;
  }
  border: $canopy-color-primitives-gray-74 1px solid;
  width: 5.875rem;
}

.header-grid {
  display: grid;
  @include media-breakpoint-down(md) {
    grid-template-columns: auto auto auto 1fr 40px;
    grid-template-rows: auto auto 1fr;
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr auto auto auto 40px;
    grid-template-rows: 1fr auto auto;
  }
  grid-column-gap: 0;
  grid-row-gap: 0;
  padding-bottom: $canopy-space-6x;
}

.close-button {
  grid-area: 1 / 5 / 2 / 6;
  margin-left: $canopy-space-4x;
  background-color: transparent !important;
}
