.ba {
  border-style: solid;
  border-width: 1px;
}

.bt {
  border-top-style: solid;
  border-top-width: 1px;
}

.br {
  border-right-style: solid;
  border-right-width: 1px;
}

.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.bl {
  border-left-style: solid;
  border-left-width: 1px;
}

.bn {
  border-style: none;
  border-width: 0;
}

.bw-2 {
  border-width: 3px;
}

.bw-3 {
  border-width: 6px;
}

.bc-p1 {
  border-color: $canopy-color-primitives-teal-41 !important;
}

.bc-p3 {
  border-color: $canopy-color-primitives-purple-98;
}

.bc-p4 {
  border-color: $canopy-color-primitives-gray-86;
}

.bc-p5 {
  border-color: $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
}

.bc-t {
  border-color: transparent;
}

.bc-b {
  border-color: $canopy-color-brand-primary;
}

.bc-g {
  border-color: $canopy-color-primitives-gray-74;
}

.rounded {
  border-radius: 5px;
}
