@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.tableHead {
  border-top: 1px solid $canopy-color-primitives-gray-86;

  & th {
    font-weight: $canopy-typography-font-weight-bold;
    border-bottom: 1px solid $canopy-color-primitives-gray-86;
    height: $canopy-space-12x;
    padding: $canopy-space-8x $canopy-space-6x;
  }
}

.table {
  margin-top: $canopy-space-12x;
  margin-bottom: $canopy-space-12x;

  & tbody {
    & td,
    th {
      border-bottom: 1px solid $canopy-color-primitives-gray-86;
      height: $canopy-space-10x;
      padding: $canopy-space-6x;
    }
  }
}

.noRows {
  margin-top: $canopy-space-8x;
  padding-top: $canopy-space-8x;
  border-top: 1px solid $canopy-color-primitives-gray-86;
}
