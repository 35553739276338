@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
$width-input-group: 200px;
$width-append: 50px;
$width-border: 1px;

.inline {
  top: -9px;
  > div {
    align-items: center;
    flex-direction: column;
    margin: 9px auto;
  }

  &.container:focus-within {
    box-shadow: none;
  }
}

.container {
  > .flatpickr-calendar {
    background-color: $canopy-color-interactive-background-secondary-default;
    border: 1px solid $canopy-color-primitives-teal-41;
    border-radius: 4px;
    box-shadow: 0 0 8px lighten($canopy-color-primitives-teal-41, 25%);
    margin-top: 4px;
    position: absolute;
    z-index: 1;
  }
}

.container:focus-within {
  box-shadow: 0 0 8px lighten($canopy-color-primitives-teal-41, 25%);

  input {
    box-shadow: none;
  }
}
