@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.breadcrumbList {
  list-style-type: none;
  padding-inline-start: 0;
  display: flex;
  margin: 0;

  li {
    display: inline-flex;
    align-items: center;
    font: $canopy-typography-body-small-font;
    color: $canopy-color-text-primary;
    font-weight: $canopy-typography-font-weight-bold;
  }

  li.divider {
    margin-inline: $canopy-space-4x;
    color: $canopy-color-text-disabled;
  }

  .icon {
    margin-right: $canopy-space-2x;
  }
}

/*
* The caret placement for dropdowns is a special snowflake;
* this config makes it appear directly in the middle relative to the text.
*/
.caret {
  vertical-align: text-top;
  margin-top: $canopy-space-1x;
}

.menu {
  a {
    padding: $canopy-space-6x;
    font-size: $canopy-typography-body-small-font-size;
    color: $canopy-color-text-primary !important; // !important needed due to global CMS CSS
    font-weight: 500 !important; // !important needed due to global CMS CSS
    text-decoration: none !important; // !important needed due to global CMS CSS
    &:hover {
      text-decoration: none;
      background-color: $canopy-color-interactive-background-secondary-hover;
    }
    &:focus {
      text-decoration: none;
    }
  }

  :global .MuiList-padding {
    padding: 0;
  }
}
