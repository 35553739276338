.select-one {
  margin-bottom: 1rem;

  .select-one-option a {
    border-radius: 2px;
    color: $canopy-color-primitives-gray-39;
    display: block;
    overflow: hidden;
    padding: 0.5rem 0.75rem;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
    }

    &.selected {
      background: $canopy-color-interactive-background-secondary-selected;
      box-shadow:
        0 0 0 1px $canopy-color-primitives-purple-24,
        inset 0 0 0 1px lighten($canopy-color-primitives-purple-24, 40%);
      color: $canopy-color-interactive-link-active;
    }

    &.disabled {
      background-color: none;
      color: $canopy-color-primitives-gray-74;
    }
  }

  .select-one-suboptions {
    padding: 1.25rem 1rem;

    .select-one-option {
      border-left: 1px solid $canopy-color-primitives-gray-86;
    }
  }
}
