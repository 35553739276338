@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.header {
  display: flex;
  justify-content: space-between;
}

.subheader {
  margin-bottom: 2.5rem;
  color: $canopy-color-primitives-gray-39;
  font-size: $canopy-typography-body-small-font-size;
}

.supplier-group {
  margin-bottom: 2.5rem;
}

.your-organizations-suppliers-header {
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
  }
}

.manage-suppliers-container {
  margin-bottom: 1rem;
}
