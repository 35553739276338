.textarea-container {
  .referral-comment-form-appear & {
    transform-origin: top left;
  }

  .internal-comment-form-appear & {
    transform-origin: top right;
  }

  .referral-comment-form-appear &,
  .internal-comment-form-appear & {
    transform: scale(0);
    opacity: 0;
  }

  .internal-comment-form-appear-active &,
  .referral-comment-form-appear-active & {
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    transform: scale(1);
  }
}

.comment-form-exit {
  overflow: hidden;
  max-height: 250px;
}

.comment-form-exit-active {
  transition: max-height 100ms cubic-bezier(0.4, 0, 1, 1);
  max-height: 0;
}
