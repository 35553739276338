.table caption,
.table td,
.table th {
  text-align: left;
}

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: $canopy-color-primitives-gray-97;
  }
}

.table-sortable {
  // scss-lint:disable SelectorDepth
  > thead > tr > th {
    padding: 0;

    a,
    .non-sortable-header {
      background: inherit;
      display: block;
      padding: 8px;

      &:hover {
        background: inherit;
      }
    }

    .non-sortable-header {
      cursor: not-allowed;
    }

    a {
      // scss-lint:disable NestingDepth
      &:hover {
        text-decoration: none;
      }
      // scss-lint:disable NestingDepth
      &.asc::after {
        content: " \f160";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
      }

      &.desc::after {
        content: " \f161";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
      }
      // scss-lint:disable NestingDepth
    }
  }
  // scss-lint:disable SelectorDepth
}

.table-expanded {
  // scss-lint:disable SelectorDepth
  > tbody > tr > td {
    padding: 24px 8px;
    vertical-align: middle;
  }
  // scss-lint:disable SelectorDepth
}

.table {
  td .checkbox {
    margin: 0;
  }
}

table.js-draggable-table {
  .js-draggable-handle {
    cursor: move;
  }

  .draggable-mirror {
    background-color: $canopy-color-background-primary;
  }
}

.table-mobile {
  @include media-breakpoint-down(sm) {
    font-size: 50%;

    tbody > tr > td,
    tbody > tr > th {
      padding: 4px 1px;
    }

    .input-sm {
      height: 30px;
      margin-bottom: 0;
      min-width: 60px;
    }
  }
}

.table.table-dashboard {
  position: relative;

  thead tr th {
    background: $canopy-color-primitives-gray-94;
    border-bottom: 0;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: normal;
    padding: 0.5rem 1rem;
    transition: background 0.2s ease-in-out;
    vertical-align: middle;

    a {
      color: $canopy-color-text-primary;
    }

    &:hover {
      background: $canopy-color-primitives-gray-86;
    }

    &.non-sortable-header {
      background: $canopy-color-interactive-background-disabled;
      cursor: default;

      &:hover {
        background: $canopy-color-interactive-background-disabled;
      }
    }

    &.table-cell-sticky {
      background: $canopy-color-primitives-gray-94;
      border-right: 1px solid $canopy-color-primitives-gray-86;
      left: 0;
      position: sticky;
      z-index: 1;
    }
  }

  tbody tr td {
    font-size: 0.875rem;
    padding: 1rem;

    &.table-cell-sticky {
      border-right: 1px solid $canopy-color-primitives-gray-86;
      left: 0;
      position: sticky;
    }
  }

  thead:first-child tr:first-child th {
    border-bottom: 0;
    border-top: 1px solid $canopy-color-primitives-gray-86;

    &.col-w80 {
      flex-basis: 80px;
      flex-grow: 0;
      min-width: 80px;
    }
    &.col-w250 {
      flex-basis: 250px;
      flex-grow: 0;
      min-width: 250px;
    }
  }

  thead tr {
    border-bottom: 1px solid $canopy-color-primitives-gray-86;
  }

  tbody tr {
    border-bottom: 1px solid $canopy-color-primitives-gray-86;
    transition: background 0.2s ease-in-out;

    td {
      background: $canopy-color-interactive-background-secondary-default;
      padding-bottom: 1rem;
      padding-top: 1rem;

      &.col-w80 {
        flex-basis: 80px;
        flex-grow: 0;
        min-width: 80px;
      }
      &.col-w250 {
        flex-basis: 250px;
        flex-grow: 0;
        min-width: 250px;
      }
    }

    &:nth-child(even) td {
      background: $canopy-color-primitives-gray-97;
      transition: background 0.2s ease-in-out;
    }

    &:hover td {
      background: $canopy-color-primitives-gray-94;
      transition: background 0.2s ease-in-out;
    }

    td {
      border: 0;
    }
  }
}

.table-flex {
  overflow: auto;

  thead,
  tbody,
  tfoot {
    display: block;
    position: relative;
  }

  thead.table-sticky-header {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tr {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  tr th,
  tr td {
    align-items: center;
    display: flex;
    flex-basis: 0;
    flex-flow: row nowrap;
    flex-grow: 1;
    min-width: 120px;
    text-align: left;
  }
}

.table-wrapper {
  width: 100%;

  &::before,
  &::after {
    background: $canopy-color-interactive-background-secondary-default;
    content: "";
    display: block;
    height: 100%;
    left: -15px;
    position: absolute;
    top: 0;
    width: 15px;
    z-index: 2;
  }

  &::before {
    left: -15px;
  }

  &::after {
    right: -15px;
  }
}

.table-container {
  overflow: auto;
  width: 100%;

  /*
   * This is a workaround to fix popover overflow issues in tables caused
   * by the react 17 & react-tiny-popover upgrade. See WF-22238 for more info.
   */
  transform: translateZ(0);

  &.scroll-active {
    .table.table-dashboard thead tr th.table-cell-sticky {
      box-shadow: 3px 0 9px rgba($canopy-color-primitives-gray-74, 0.5);
    }

    .table.table-dashboard tbody tr td.table-cell-sticky {
      box-shadow: 3px 0 6px rgba($canopy-color-primitives-gray-86, 0.5);
    }
  }

  /*
   * This is a workaround to hide the popover arrow in tables due to
   * positioning issues caused by the react 17 & react-tiny-popover upgrade.
   * See WF-22238 for more info.
   */
  .react-tiny-popover-container .arrow {
    display: none;
  }
}
