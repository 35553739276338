.swatch-left {
  border-left-color: $canopy-color-primitives-gray-39;
  border-left-style: solid;
  border-left-width: 5px;

  &.swatch-left-success {
    border-left-color: $canopy-color-primitives-teal-41;
    border-left-width: 5px;

    .swatch-color {
      color: $canopy-color-text-success;
    }
  }

  &.swatch-left-warning {
    border-left-color: $canopy-color-primitives-yellow-62;
    border-left-width: 5px;

    .swatch-color {
      color: $canopy-color-primitives-yellow-62;
    }
  }

  &.swatch-left-danger {
    border-left-color: $canopy-color-primitives-red-48;
    border-left-width: 5px;

    .swatch-color {
      color: $canopy-color-text-danger;
    }
  }

  &.swatch-left-default {
    border-left-color: $canopy-color-primitives-gray-39;
    border-left-width: 5px;

    .swatch-color {
      color: $canopy-color-primitives-gray-39;
    }
  }
}
