@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.snooze-modal-container {
  &.has-errors {
    input {
      border-color: $canopy-color-primitives-red-48;
    }
  }

  input:focus {
    border-color: $canopy-color-primitives-purple-77;
    box-shadow: 0 0 8px $canopy-color-primitives-purple-77;
  }

  > div.inline {
    top: -9px;
  }
}

.inner-snooze-modal-container {
  min-height: 310px !important;
  overflow-y: hidden;
}
