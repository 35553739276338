@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.widgetLinksLayout {
  display: inline-flex;
  gap: 32px;
}

.linkStyleBtn {
  color: $canopy-color-brand-primary;
  background: none;
  border: none;
  padding: 0;
}
