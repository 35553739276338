@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.order-actions-dropdown {
  position: relative;
  z-index: 1;
}

.dropdown-container {
  background-color: $canopy-color-background-secondary;
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 $canopy-color-primitives-gray-86;
  margin: 6px 7px 0 0;
  padding-block: $canopy-space-4x;
  position: absolute;
}

.order-action {
  padding: $canopy-space-6x $canopy-space-8x;
  &:hover {
    background-color: $canopy-color-interactive-background-secondary-hover;
    cursor: pointer;
  }
}

.subtitle {
  color: $canopy-color-text-secondary;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: $canopy-space-12x;
}

.title {
  @include canopy-typography-heading-small;
}
