.container {
  margin: 0 auto;
  padding: 3rem 0 2.5rem;
  text-align: center;

  form,
  img {
    display: block;
    margin: 0 auto;
    max-width: 440px;
    width: 100%;
  }
}
