@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.sign-out-as-guest {
  color: $canopy-color-text-primary;
  background-color: $canopy-color-interactive-background-secondary-default;
}

.sign-out-as-guest:hover {
  color: $canopy-color-text-primary;
  background: $canopy-color-primitives-gray-94;
}

@include media-breakpoint-down(xs) {
  .btn-block-sm {
    width: 97vw;
    display: block;
  }
}
