@import "node_modules/@parachutehealth/canopy-tokens-color/build/tokens.scss";

.tablist {
  border-bottom: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */

  :global .MuiTabs-indicator {
    background-color: $canopy-color-interactive-background-primary-default;
  }
}

.tab {
  font-weight: bold;
  color: $canopy-color-interactive-link-default;

  &:not(:global .Mui-selected) {
    color: $canopy-color-text-secondary;
    opacity: 1;
  }
}
