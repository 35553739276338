.text-xs-left {
  @include media-breakpoint-down(sm) {
    text-align: left !important;
  }
}

.text-xs-right {
  @include media-breakpoint-down(sm) {
    text-align: right !important;
  }
}

.text-sm-left {
  @include media-breakpoint-down(md) {
    text-align: left !important;
  }
}

.font-mobile-xs {
  @include media-breakpoint-down(sm) {
    @include font-xs;
  }
}
