@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/mixins.scss";
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.doc-content {
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.doc-image {
  width: 100%;
}

.contents {
  pointer-events: none;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    width: 612px;
    height: 792px;
  }

  @include media-breakpoint-only(md) {
    width: 496px;
    height: 641px;
  }

  @include media-breakpoint-down(sm) {
    width: 384px;
    height: 548px;
  }
}
