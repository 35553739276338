.grid.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}

.tile {
  background-color: $canopy-color-background-secondary;
  border: 1px solid $canopy-color-primitives-gray-94; /* stylelint-disable-line max-line-length */ /* TODO: replace with semantic Canopy border token when available */
  position: relative;
  text-align: center;
  height: 100%;

  .overlay {
    display: none;
  }

  &.icon {
    display: table;
    height: 180px;
    padding: 1.5rem;
    width: 100%;

    &:hover {
      text-decoration: none;
    }

    i {
      display: table-cell;
      font-size: 1.5rem;
      vertical-align: middle;
    }
  }

  &.text {
    border: 0;
    height: 180px;

    h3 {
      display: table-cell;
      height: inherit;
      vertical-align: middle;
    }
  }

  &:hover .overlay {
    background: rgba($canopy-color-primitives-teal-41, 0.5);
    color: $canopy-color-text-inverse;
    cursor: pointer;
    display: block;
    font-weight: bold;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z-index-tile-overlay;
  }

  .image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100px;
    margin: 1.5rem;
  }

  h5 {
    font-weight: 800;
    min-height: 80px;
    padding: 0.75rem;
  }

  .pill {
    font-size: 1rem;
    left: 50%;
    opacity: 0.85;
    padding: 0.5rem 1rem;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .circle-overlay {
    @include font-xs;
    background-color: $canopy-color-interactive-background-secondary-default;
    border-radius: 50%;
    color: $canopy-color-primitives-purple-24;
    display: inline-block;
    fill: $canopy-color-primitives-purple-24;
    height: 75px;
    line-height: 75px;
    margin-top: 100px;
    text-align: center;
    width: 75px;
  }
}
