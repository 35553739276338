$badge-height: 1.875rem;

.badge {
  @include font-xxs;
  background: $canopy-color-primitives-gray-94;
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: 3px;
  color: $canopy-color-primitives-gray-39;
  display: inline-block;
  font-weight: bold;
  height: $badge-height;
  line-height: $badge-height;
  min-width: $badge-height;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

h1,
th {
  > .badge {
    display: inline;
    padding: 0.375rem;
  }
}
