#error_explanation {
  h2 {
    display: none;
  }

  ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
  }

  li {
    @extend .alert;
    @extend .alert-danger;

    margin-bottom: 4px;
  }
}
