.comment {
  @include clearfix;
  background-color: $canopy-color-background-primary;
  border-radius: 1rem;
  border-top-left-radius: 0;
  clear: both;
  font-size: 0.75rem;
  padding: 1rem;

  .comment-header {
    font-weight: bold;
  }

  .comment-date {
    color: $canopy-color-primitives-gray-74;
    float: right;
  }
}
