@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.orderLinkCard {
  @include canopy-typography-body-medium;
  background-color: $canopy-color-primitives-white-100;
  border-radius: $canopy-space-2x;
  height: 100%;
  max-width: 500px;
  padding: $canopy-space-8x;
}
