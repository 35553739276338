@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.option-container {
  border-radius: 4px;
  border: 1px solid transparent;
  transition: background-color 0.2s;
  margin: $canopy-space-4x;

  &:hover {
    background-color: $canopy-color-primitives-gray-94;
  }

  &.active {
    background-color: $canopy-color-primitives-purple-98;
    border: 1px solid $canopy-color-primitives-purple-24;
  }
}

.pill {
  margin: 0;
}

.single-line-text {
  height: 36px;
}

.left-sidebar-link {
  color: $canopy-color-brand-primary;
  cursor: pointer;
  padding: $canopy-space-4x;
  text-decoration: none;
  transition: background-color 0.2s;
  display: flex;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $canopy-color-brand-primary;
  }
}

.sub-option-container {
  overflow: auto;
  max-height: 280px;
}
