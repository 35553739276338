.supplier-transfer-search-result {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .out-of-network-warning-pill {
    border-radius: 17px;
    overflow: hidden;
    width: max-content;
    min-width: min-content;
    height: max-content;
    white-space: nowrap;
  }
}
