@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.container {
  alignItems: center;
  display: flex;
}

.pill-text {
  color: $canopy-color-primitives-yellow-53;
  font-weight: 700;
  margin-left: $canopy-space-2x;
  vertical-align: top;
}
