@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.backlink-text {
  font-size: $canopy-typography-body-small-font-size;
}

.grid-spacing {
  display: flex;
  flex-direction: column;
  gap: $canopy-space-12x;
  max-width: 832px;
  padding-top: $canopy-space-2x;

  @media (max-width: 832px) {
    max-width: 100%;
  }
}

.multi-actor-h2 {
  margin: 0;
}
