hr {
  background: $canopy-color-primitives-gray-86;
  border: 0;
  height: 1px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  padding: 0;
}

hr.black {
  background: $canopy-color-primitives-gray-16;
}

.horizontal-divider + div:not(:empty) {
  border-top: 1px solid $canopy-color-primitives-gray-86;
  margin-top: 1rem;
  padding-top: 1rem;
}
