@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/mixins.scss";
@import "node_modules/bootstrap/scss/variables.scss";
$image-size: 100px;

.container {
  position: relative;
}

.quantity {
  width: 88px;
}

.image {
  height: $image-size;
  margin-left: $canopy-space-8x;
  width: $image-size;
  margin-bottom: $canopy-space-8x;
}

.quantity-container {
  margin-right: $canopy-space-8x;
  margin-bottom: $canopy-space-8x;

  form > div {
    margin-left: $canopy-space-8x;
    margin-bottom: 0;
  }
}

.display-name {
  min-width: 160px;
  margin-bottom: $canopy-space-8x;
}

.cart-item:last-of-type {
  margin-bottom: 150px !important;
}

.internal-cart {
  overflow: auto;
}
