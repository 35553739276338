@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.consignmentClosetsDatagrid {
  border-top: 1px solid $canopy-color-primitives-gray-86;
  padding-top: $canopy-space-6x;

  :global .MuiDataGrid-root {
    border: none !important;
  }
}

.duplicateButton {
  a {
    padding: 0 !important;
  }
}
