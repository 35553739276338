.line-item-group-thumbnail {
  @include clearfix;
  margin-bottom: 10px;

  .line-item-group-thumbnail-name {
    line-height: 60px;
    overflow: hidden;
    padding-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.line-item-group-thumbnail-row {
  border-bottom: 1px solid $canopy-color-primitives-gray-86;
  margin-bottom: 10px;

  &:last-child {
    border-bottom: 0;
  }

  select.form-control {
    margin-top: 5px;
  }
}
