@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.selectedRowsCount {
  color: $canopy-color-interactive-background-primary-default;
  font: $canopy-typography-heading-small-font;
}

.datagridToolbar {
  button {
    font: $canopy-typography-heading-small-font;
    color: $canopy-color-interactive-link-default;
    padding: $canopy-space-2x $canopy-space-6x;

    :global .MuiSvgIcon-root {
      margin-bottom: $canopy-space-1x;
    }
  }

  :global .MuiBadge-badge {
    background-color: $canopy-color-interactive-background-primary-default;
  }
}

/* stylelint-disable selector-disallowed-list */
:global .Mui-checked {
  color: $canopy-color-interactive-background-primary-default !important;
}

:global .MuiGridPanelWrapper-root {
  button {
    font: $canopy-typography-heading-small-font;
    color: $canopy-color-interactive-link-default;

    :global .MuiSvgIcon-root {
      margin-bottom: $canopy-space-1x;
    }
  }
}
