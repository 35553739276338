.insurance-network-warning {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 75%;
  padding: 6px;

  &.insurance-network-warning-default {
    background-color: $canopy-color-primitives-gray-74;
    color: $canopy-color-text-primary;
  }

  &.insurance-network-warning-alert {
    background-color: $canopy-color-background-warning;
    color: $canopy-color-text-primary;

    .fas {
      color: $canopy-color-primitives-yellow-62;
    }
  }

  &.insurance-network-warning-alert-white {
    background-color: $canopy-color-background-container;
    color: $canopy-color-text-primary;
  }

  &.insurance-network-warning-info {
    background-color: $canopy-color-background-information;
    color: $canopy-color-text-primary;

    .fas {
      color: $canopy-color-primitives-blue-41;
    }
  }
}
