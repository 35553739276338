@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.link {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
}

.backBtn {
  margin-bottom: 3px; // 3px for fine-tuned alignment
  margin-right: $canopy-space-2x;
}

.allSeeds {
  font: $canopy-typography-body-xsmall-font;
  font-weight: $canopy-typography-font-weight-bold;
}

.archivable {
  color: $canopy-color-text-danger;
}

.menuItem {
  display: flex;
  flex-direction: row;

  .archiveIcon {
    margin-right: $canopy-space-4x;
    margin-top: 7px;
  }

  .auditIcon {
    margin-right: $canopy-space-4x;
    margin-top: $canopy-space-1x;
  }

  .menuText {
    margin-top: $canopy-space-2x;
  }
}

// stylelint-disable-next-line selector-disallowed-list
a.menuItem {
  margin-top: $canopy-space-2x;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-top: $canopy-space-2x;
  margin-bottom: $canopy-space-2x;

  .leftHeader {
    display: flex;
    flex-direction: column;
  }

  .rightHeader {
    display: flex;
    button {
      svg {
        fill: $canopy-color-text-primary !important;
      }
    }
  }
}

.mainContent {
  padding: $canopy-space-8x;
}

.internalNotesContainer {
  border: 1px solid $canopy-color-primitives-gray-86;
  border-radius: 8px;
  background-color: $canopy-color-background-container;
}
